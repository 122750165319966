import { getQueryStringFromObject } from "store/api/util";
import { Asset } from "store/models/Asset";
import Movement from "store/models/Movement";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { AppDispatch } from "store/store";
import IAccessory from "store/models/Accessory";
import { Branch } from "store/models/Branch";
import { setGlobalMessage } from "store/slices/systemSlice";
import { contractApi } from "store/services/contract";

export type MovementQuery = {
  startDate: string;
  endDate: String
  type: string[];
  transport?: string;
  projectNumber?: string;
  branch?: string;
  yard?: string;
  client?: string;
}


export type MovementCard = Omit<Movement, "asset"> & {
  asset?: AssetInfo;
  accessory?: IAccessory;
  projectNumber?: string;
  customerName?: string;
}

export type AssetInfo = Pick<Asset,"serialNumber" | "assetNumber" | "sizeCode" | "category" | "yard" | "branch" | "_id">

export type ContractInfo = {_id: string, customerName: string}

export type MovementById = Omit<Movement, "asset" | "contract" | "accessory"> & {
  asset?: AssetInfo;
  accessory?: IAccessory & {branch: Branch["_id"]};
  contract?: ContractInfo;
}



export const movementApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getMovementById: builder.query<
      MovementById,
      { contractId?: string; movementId: string }
    >({
      query: (query) => `/movement?${getQueryStringFromObject(query)}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, id) => [
        { type: "Movements", id: result?._id },
      ],
    }),
    getMovementsBy: builder.query<MovementCard[], MovementQuery>({
      query: (query) => `/movement/query?${getQueryStringFromObject(query)}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, id) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: "Movements" as const,
                id: _id,
              })),
              { type: "Movements", id: "LIST" },
            ]
          : [{ type: "Movements", id: "LIST" }],
    }),
    updateNonContractMovement: builder.mutation<Movement, Partial<Movement>>({
      query(body) {
        return {
          url: `/movement/nonContractMovement`,
          method: "PATCH",
          body,
        };
      },
      extraOptions: { maxRetries: 1 },
      transformResponse: getResponseBody,
      invalidatesTags: (result) => [
        { type: "Movements", id: "LIST" },
        { type: "Movements", id: result?._id },
      ],
    }),
    createNonContractMovement: builder.mutation<Movement, Movement>({
      query(body) {
        return {
          url: `/movement/nonContractMovement`,
          method: "POST",
          body,
        };
      },
      extraOptions: { maxRetries: 1 },
      transformResponse: getResponseBody,
      invalidatesTags: (result) => [{ type: "Movements", id: "LIST" }],
    }),
    updateSingleMovementDateOfMove: builder.mutation<
      Movement,
      { contractId?: string; movementId: string; dateOfMove: string }
    >({
      query(body) {
        return {
          url: `/movement/updateSingleDateOfMove`,
          method: "PATCH",
          body,
        };
      },
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody,
      onQueryStarted({contractId, movementId, dateOfMove}, { dispatch, queryFulfilled }) {
        if (!contractId) return
        const updateSingleResult = dispatch(
          contractApi.util.updateQueryData(
            "getContractById",
            contractId,
            (draft) => {
              const newMovements = draft.movements.map((movement) => {
                if (movement._id === movementId) {
                  return {...movement, dateOfMove: dateOfMove};
                }
                return movement;
              })
              draft.movements = newMovements;
            })
          
        );
         queryFulfilled.catch(({ error }: any) => {
          updateSingleResult.undo();
          dispatch(
            setGlobalMessage({
              messageText: `Update failed: ${error?.data?.message}`,
              severity: "error",
              show: true,
            })
          );
        });
      },
      invalidatesTags: (result, err, { contractId }) => [
        { type: "Movements", id: "LIST" },
        { type: "Movements", id: result?._id },
        { type: "ContractWorkOrders", id: contractId },
      ],
    }),
  }),
});

export const {
  useGetMovementsByQuery,
  useGetMovementByIdQuery,
  useCreateNonContractMovementMutation,
  useUpdateNonContractMovementMutation,
  useUpdateSingleMovementDateOfMoveMutation,
} = movementApi;


export const updateMovementQueryData = (movementId: MovementCard["_id"], update: Partial<MovementCard>, movementQueryArgs: MovementQuery) => (dispatch: AppDispatch) => {
  dispatch(movementApi.util.updateQueryData("getMovementsBy", movementQueryArgs, (movementCardData) => {  
    const movementIndex = movementCardData?.findIndex((movement) => movement._id === movementId)
    if(movementIndex !== -1) {
      movementCardData[movementIndex] = {...movementCardData[movementIndex], ...update}
    }
  }))
}

