import React, { FC } from "react";
import { getUserFullName, parsePhoneNumber, skipFirstElement } from "utils/util";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { selectCurrentClientId } from "store/slices/clientSlice";
import Address, { parseAddress } from "store/models/Address";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useGetClientByIdQuery } from "store/services/client";
import LoadingComponent from "components/LoadingComponent";
import { useGetUsersQuery } from "store/services/user";
import { byIds } from "store/sliceUtils";

interface ClientRowProps {
  label: string;
  value?: string | JSX.Element;
}

const ClientRow: FC<ClientRowProps> = ({ label, value }) => {

  const textIsTooLong = typeof value === "string" && value.length > 50;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={textIsTooLong ? "flex-start" : "center"}
      flexDirection={textIsTooLong ? "column" : "row"}
      gap={1}
      whiteSpace={textIsTooLong ? "pre-wrap" : "nowrap"}
    >
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {label}
      </Typography>
      <Typography variant="body2" sx={{textWrap: "wrap",wordBreak: "break-word", textAlign: "right"}}>{value}</Typography>
    </Box>
  );
};

interface Props {}

const AddressTypography: React.FC<{ address: Address }> = ({address}) => (
  <Typography
    key={`${address.address}${address.latLng.lat}${address.latLng.lat}`}
    mb={1}
  >
    {parseAddress(address)}
  </Typography>
);

const ClientView: React.FC<Props> = () => {
  const currentClientId = useSelector(selectCurrentClientId);
  const {data: currentClient, isLoading: currentClientLoading} = useGetClientByIdQuery(currentClientId || "", { skip: !currentClientId });

  const {data: users = []} = useGetUsersQuery()
  const usersHash = byIds(users)

  const addressAccordionSx: SxProps<Theme> = {
    width: "100%",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  }

  return (
    <LoadingComponent isLoading={currentClientLoading} display="flex" flexDirection="column" rowGap={4}>
      <ClientRow label="Legal Name" value={currentClient?.legalName} />

      <ClientRow label="Country" value={currentClient?.country} />

      <ClientRow
        label="Phone Number"
        value={parsePhoneNumber(currentClient?.phoneNumber || "")}
      />

      <ClientRow
        label="Fax"
        value={parsePhoneNumber(currentClient?.fax || "")}
      />

      <ClientRow label="Email" value={currentClient?.email} />

      <ClientRow label="Website" value={currentClient?.website} />

      <ClientRow
        label="Account Owner"
        value={
          currentClient?.accountOwner ? getUserFullName(usersHash[currentClient?.accountOwner]) : "None"
        }
      />

      <ClientRow label="Accounts Payable Contact" value={currentClient?.accountsPayableContact} />

      <ClientRow label="Bill Tos" />
      <Accordion
        sx={addressAccordionSx}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography sx={{ width: "100%" }}>
            {currentClient?.billTos.length
              ? parseAddress(currentClient.billTos[0])
              : "None"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {currentClient?.billTos
            .filter(skipFirstElement)
            .map((address) => <AddressTypography address={address} />)}
        </AccordionDetails>
      </Accordion>

      <ClientRow label="Ship Tos" />
      <Accordion
        sx={addressAccordionSx}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography sx={{ width: "100%" }}>
            {currentClient?.shipTos.length
              ? parseAddress(currentClient.shipTos[0])
              : "None"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {currentClient?.shipTos
            .filter(skipFirstElement)
            .map((address) => <AddressTypography address={address} key={`${address.latLng.lat}-${address.latLng.lng}`} />)}
        </AccordionDetails>
      </Accordion>

    </LoadingComponent>
  );
};

export default ClientView;
