import React from "react";
import { useSelector } from "react-redux";
import Blade from "components/Blade";
import {
  selectBladeMode,
  setBladeMode,
  simpleGlobalMessage,
} from "store/slices/systemSlice";
import UserForm from "features/users/blade/UserForm";
import { selectCurrentUserId, setCurrentUserId } from "store/slices/userSlice";
import UserData from "store/models/UserData";
import { Form, Formik, FormikHelpers } from "formik";

import { Button, IconButton } from "@mui/material";
import { initUser } from "store/api/user";
import { userValidationSchema } from "utils/formikAPI";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { createMongoId } from "utils/createMongoId";
import { useAppDispatch } from 'store/store';
import { useCreateUserMutation, useGetUserQuery } from "store/services/user";
import useLoggedInUser from "hooks/useLoggedInUser";

interface Props {}

const UserCreateBlade: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const bladeMode = useSelector(selectBladeMode);
  const currentUserId = useSelector(selectCurrentUserId);
  const {data: currentUser} = useGetUserQuery(currentUserId || '',  {skip: !currentUserId})
  const [createUser] = useCreateUserMutation()
  const isOpen = bladeMode === "userCreate" && !currentUser;
  const {loggedInUser} = useLoggedInUser()

  const handleCreateUser = (
    user: UserData,
    { setSubmitting, resetForm, setFieldValue }: FormikHelpers<UserData>
  ) => {
    createUser(user).unwrap().then(({email}) => {
      dispatch(
        simpleGlobalMessage(`User ${email} created successfully`, "success")
      );
      dispatch(setBladeMode("userCreate"));
      resetForm();
      setFieldValue("_id", createMongoId());
      
    }).catch((error) => {
      setSubmitting(false)
      dispatch(
        simpleGlobalMessage(error.data?.message || error.message || 'Failed to create user', "error")
      );
    })
  };

  const handleClose = () => {
    dispatch(setCurrentUserId(undefined));
    dispatch(setBladeMode("none"));
  };

  return (
    <Formik
      initialValues={initUser({countries: loggedInUser?.countries})}
      validationSchema={userValidationSchema}
      onSubmit={handleCreateUser}
    >
      {({ resetForm }) => {
        const resetCardForm = () => {
          resetForm();
          handleClose();
        };

        return (
          <Form>
            <Blade
              open={isOpen}
              changeOpen={resetCardForm}
              actions={[
                <IconButton
                  color="primary"
                  type="submit"
                  data-testid="submitUser"
                  key="submitUser"
                >
                  <SaveIcon />
                </IconButton>,
                <IconButton key="cancel" onClick={() => resetForm()}>
                  <CancelIcon />
                </IconButton>,
              ]}
            >
              <UserForm />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Blade>
          </Form>
        );
      }}
    </Formik>
  );
};
export default UserCreateBlade;
