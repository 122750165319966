import React, { useState } from "react";
import { setCurrentWorkOrderId } from "store/slices/maintenanceSlice";
import { setBladeMode } from "store/slices/systemSlice";
import {
  Box,
  Button,
  Chip,
  ChipProps,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppDispatch } from "store/store";
import { dateFromMMDDYYYY, toDDMMMYYYY } from "utils/util";
import { WorkOrderStatus } from "store/models/WorkOrder";
import {
  AssetWorkOrder,
  useGetWorkOrdersByAssetIdQuery,
} from "store/services/workOrder";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AssetWOSummaryPdf from "features/maintenance/pdf/AssetWOSummaryPDF";
import { useSelector } from "react-redux";
import LoadingComponent from "components/LoadingComponent";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import { useGetAssetByIdQuery } from "store/services/asset";
import { useGetBranchesQuery } from "store/services/branches";
import { byIds } from "store/sliceUtils";
import { useGetUsersQuery } from "store/services/user";


interface Props {
  assetId: string;
}
const OpenWorkOrders: React.FC<Props> = ({ assetId }) => {
  const [showInactive, setShowInactive] = useState(false);
  const {
    data: assetWorkOrders = [],
    isLoading,
    isFetching,
  } = useGetWorkOrdersByAssetIdQuery(
    { assetId, loadInactive: showInactive },
    { skip: !assetId }
  );
  const dispatch = useAppDispatch();
  const { data: branches = [] } = useGetBranchesQuery();
  const branchesHash = byIds(branches);
  const { data: users = [] } = useGetUsersQuery();
  const usersHash = byIds(users);
  const {data: asset} = useGetAssetByIdQuery(assetId);
  const assetBranch = asset ? branchesHash[asset.branch] : undefined;
  const theme = useTheme();

  if (!asset || !assetBranch || !assetId) {
    return null;
  }

  const handleWorkOrderClick = (workOrder: AssetWorkOrder) => {
    dispatch(setCurrentWorkOrderId(workOrder._id));
    dispatch(setBladeMode("workOrderViewEdit"));
  };

  function byDueDate(a: AssetWorkOrder, b: AssetWorkOrder) {
    return new Date(a.dueDate) > new Date(b.dueDate) ? 1 : -1;
  }

  function getChipTextColor(workOrder: AssetWorkOrder): ChipProps["color"] {
    switch (workOrder.status) {
      case WorkOrderStatus.CANCELLED:
        return undefined;
      case WorkOrderStatus.COMPLETE:
        return "success";
      default:
        return dateFromMMDDYYYY(workOrder.dueDate) > new Date()
          ? undefined
          : "error";
    }
  }

  const totalHours = assetWorkOrders.reduce((hours, workOrder) => {
    if (
      [WorkOrderStatus.TO_DO, WorkOrderStatus.IN_PROGRESS].includes(
        workOrder.status
      )
    ) {
      hours += workOrder.actualHoursToComplete || workOrder.estimatedHours || 0;
    }

    return hours;
  }, 0);

  return (
    <LoadingComponent
      isLoading={isLoading}
      sx={{ minHeight: "40vh" }}
    >
      <PDFDownloadLink
        document={
          <AssetWOSummaryPdf
            branch={assetBranch}
            users={usersHash}
            workOrders={assetWorkOrders}
            asset={asset}
          />
        }
        fileName={`Asset Work Order Summary - ${asset?.serialNumber}.pdf`}
        style={{
          textDecoration: "none",
          display: "flex",
          justifyContent: "flex-end",
        }}
        key="service-damage-report"
      >
        <Button
          startIcon={
            <PictureAsPdf />
          }
          fullWidth
          variant="outlined"
          sx={{m: 1, mb: 2}}
        >
          Download PDF Summary
        </Button>
      </PDFDownloadLink>
      <TableContainer component={Paper} sx={{ maxHeight: "70vh" }}>
        <Table aria-label="inspection-table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="center">
                <Typography sx={{ whiteSpace: "nowrap" }}>
                  Hrs{" "}
                  <Tooltip title="Total Remaining Est. Hours">
                    <Chip
                      sx={{
                        whiteSpace: "nowrap",
                        "& .MuiChip-label": {
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        },
                        fontSize: "0.75rem",
                        fontWeight: "bold",
                      }}
                      label={`${totalHours}`}
                      variant="outlined"
                      color="default"
                    />
                  </Tooltip>
                </Typography>
              </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() =>
                    setShowInactive((showInactive) => !showInactive)
                  }
                  variant="outlined"
                  sx={{ minWidth: "135px" }}
                  size="small"
                >
                  {showInactive ? "Due / Completed" : "Due"}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ maxHeight: "500px" }}>
            {!!assetWorkOrders.length &&
              assetWorkOrders
                .filter((workOrder) =>
                  showInactive
                    ? true
                    : [
                        WorkOrderStatus.TO_DO,
                        WorkOrderStatus.IN_PROGRESS,
                      ].includes(workOrder.status)
                )
                .sort(byDueDate)
                .map((workOrder) => {
                  return (
                    <TableRow
                      key={workOrder._id}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleWorkOrderClick(workOrder)}
                      hover
                    >
                      <TableCell
                        sx={{
                          borderTopLeftRadius: theme.spacing(1),
                          borderBottomLeftRadius: theme.spacing(1),
                          boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
                          width: "40%",
                        }}
                      >
                        {workOrder.inspection
                          ? workOrder.inspectionType
                          : workOrder.title}
                      </TableCell>
                      <TableCell align="center">
                        {workOrder.estimatedHours}
                      </TableCell>
                      <TableCell align="center">{workOrder.status}</TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          borderTopRightRadius: theme.spacing(1),
                          borderBottomRightRadius: theme.spacing(1),
                          boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
                        }}
                      >
                        <Chip
                          label={toDDMMMYYYY(
                            dateFromMMDDYYYY(
                              workOrder.completedDate || workOrder.dueDate
                            )
                          )}
                          color={getChipTextColor(workOrder)}
                          variant="outlined"
                          sx={{ width: "135px", cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
        {!assetWorkOrders.length && !isFetching && (
          <Box sx={{ pt: 3 }}>
            <Typography sx={{ textAlign: "center" }}>No Work Orders</Typography>
          </Box>
        )}
      </TableContainer>
      {isFetching && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </LoadingComponent>
  );
};

export default OpenWorkOrders;
