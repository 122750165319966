import React, { FC } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { Branch } from "store/models/Branch";
import _ from "lodash";
import { userIsSuper } from "hooks/usePermissions";
import { useGetBranchesQuery } from "store/services/branches";
import { byIds } from "store/sliceUtils";
import useLoggedInUser from "hooks/useLoggedInUser";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (branch: Branch) => branch.name,
});

type Props = Omit<
  AutocompleteProps<Branch, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  label?: string;
  "data-testId"?: string
  userBranchesOnly?: boolean
};

const BranchAutocomplete: FC<Props> = ({ value, name, error, label, userBranchesOnly, ...rest }) => {
  const {data: branches = []} = useGetBranchesQuery();
  const branchesHash = byIds(branches);
  const { loggedInUser } = useLoggedInUser()
  const isSuper = userIsSuper(loggedInUser);
  const filteredOptions = _.sortBy(isSuper ? branches : branches.filter((branch) => isSuper ||
    userBranchesOnly
      ? loggedInUser?.branches.includes(branch._id)
      : loggedInUser?.countries.includes(branch.country)
  ), "name");

  return (
    <Autocomplete
      {...rest}
      value={value || null}
      key={value ? "branch" : "no-branch"}
      getOptionLabel={(branch) =>
        typeof branch === "string"
          ? branch
          : `${branchesHash[branch._id]?.name} (${branchesHash[branch._id]?.country})`
      }
      filterOptions={filterOptions}
      options={filteredOptions}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          data-testid={rest["data-testId"]}
          error={error}
          margin="none"
          label={label}
          autoComplete="off"
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password",
            form: { autocomplete: "off" },
          }}
        />
      )}
    />
  );
}

export default BranchAutocomplete;