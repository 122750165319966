import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  Box,
  FilterOptionsState,
  ListItem,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ClientContactModal from "components/modals/ClientContactModal";
import { FormikHelpers } from "formik";
import useDebounce from "hooks/useDebounce";
import React, { useState } from "react";
import { Client } from "store/models/Client";
import { ClientContact, initClientContact } from "store/models/ClientContact";
import { useCreateClientContactMutation, useSearchClientContactsQuery } from "store/services/clientContact";
import { simpleGlobalMessage } from "store/slices/systemSlice";
import { useAppDispatch } from "store/store";
import { getFullName } from "utils/util";

interface Props
  extends Omit<
    AutocompleteProps<ClientContact, boolean, boolean, boolean>,
    "renderInput" | "options"
  > {
  clientContact?: ClientContact;
  handleChange: (value: ClientContact | null) => void;
  disableCreateNew?: boolean;
  defaultClientId?: string
  error?: boolean
  sx?: SxProps
}



const ClientContactSelect: React.FC<Props> = ({
  clientContact,
  handleChange,
  disableCreateNew = false,
  defaultClientId,
  error = false,
  sx
}) => {

  const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const debouncedSearchValue = useDebounce(searchInput, 500);
  const dispatch = useAppDispatch()
  const [createClientContact] = useCreateClientContactMutation()
  const theme = useTheme();


  const { currentData: clientContacts = [], isFetching } =
    useSearchClientContactsQuery(
      {
        search: debouncedSearchValue,
      },
      { skip: !searchInput || searchInput === "Create New Contact" }
    );

  const searchResultsClientByClientContactId = clientContacts.reduce(
    (acc, curr) => {
      acc[curr._id] = curr.populatedClient;
      return acc;
    },
    {} as Record<string, Client>
  );

  function noExactMatch() {
    const firstName = searchInput.split(" ")[0];
    const lastName = searchInput.split(" ")[1];
    return !clientContacts.some(
      (contact) => contact.firstName?.toLowerCase() === firstName?.toLowerCase() && contact.lastName?.toLowerCase() === lastName?.toLowerCase()
    );
  }

  const handleSubmit = (
    values: ClientContact,
    formikHelpers: FormikHelpers<ClientContact>
  ) => {
    createClientContact(values)
      .unwrap()
      .then((contact) => {
        setShowNewContactModal(false);
        handleChange(contact);
      })
      .catch((error) => {
        formikHelpers.setSubmitting(false);
        dispatch(
          simpleGlobalMessage(
            `Failed to create new contact for ${getFullName(values)}: ${
              error.data?.message || error.message || "An error occurred"
            }`,
            "error"
          )
        );
      });
  };

  function addNewContactOptionIfNoClientMatch(
    options: ClientContact[],
    params: FilterOptionsState<ClientContact>
  ) {
    const filteredOptions = [...clientContacts.map(({populatedClient, ...contact}) => contact)];
    if (params.inputValue && filteredOptions.length === 0 && !disableCreateNew || (noExactMatch() && params.inputValue)) {
      filteredOptions.push(
        initClientContact({firstName: "Create New Contact" })
      );
    }
    return filteredOptions;
  }

  const handleSelectChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ClientContact | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ClientContact> | undefined
  ) => {
    if (value && value.firstName.includes("Create New Contact")) {
      setShowNewContactModal(true);
    } else {
      handleChange(value);
    }
  };
  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    setSearchInput(value)
  }

  return (
    <>
      <Autocomplete
        key={clientContact ?String(clientContact) : 'no-contact'}
        options={clientContacts}
        noOptionsText={searchInput ? "No matching contacts found" : "Start typing to search"}
        autoComplete
        loading={isFetching}
        value={clientContact}
        filterOptions={addNewContactOptionIfNoClientMatch}
        includeInputInList
        filterSelectedOptions
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        clearOnBlur={false}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Contacts"
            variant="outlined"
            inputProps={{ ...params.inputProps, autocomplete: "new-password", form: { autocomplete: "off" } }}
            error={error}
          />
        )}
        fullWidth
        size="small"
        sx={{ my: 2, ...sx }}
        getOptionLabel={option => getFullName(option)}
        renderOption={(props, option) => {
          return (
            <ListItem sx={{ display: "flex", flexDirection: "column", my: 2 }} {...props}>
              <Box sx={{display: "flex", justifyContent: "space-between", gap: 2, width: "100%"}}>
                <Typography>{getFullName(option)}</Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {option.companyRole},
                </Typography>
              </Box>
              <Box sx={{display: "flex", justifyContent: "space-between", gap: 2, width: "100%"}}>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {option.currentClient && searchResultsClientByClientContactId[option._id]?.companyName}
                </Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {option.email}
                </Typography>
              </Box>
            </ListItem>
          );
        }}
      />
    {showNewContactModal && <ClientContactModal open={showNewContactModal} setOpen={setShowNewContactModal} handleSubmit={handleSubmit} clientId={defaultClientId} />}
    </>
  );
};

export default ClientContactSelect;
