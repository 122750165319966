import UserData from "store/models/UserData";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { oneMinute } from "utils/timeValues";

export const userApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getUsers: builder.query<UserData[], void>({
      query: () => `user/returnAll`,
      transformResponse: getResponseBody,
      providesTags: [{ type: "Users", id: `LIST` }],
      keepUnusedDataFor: oneMinute * 60,
    }),
    getUser: builder.query<UserData, string>({
      query: (userId) => `user/specificUser?userId=${userId}`,
      transformResponse: getResponseBody,
      providesTags: (result, err, id) => [{ type: "Users", id }],
      keepUnusedDataFor: oneMinute * 60,
    }),
    createUser: builder.mutation<UserData, UserData>({
      query: (body) => ({
        url: `user/createUser`,
        method: "POST",
        body,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: [{ type: "Users", id: `LIST` }],
      extraOptions: { maxRetries: 0 },
    }),
    updateUser: builder.mutation<UserData, UserData>({
      query: (body) => ({
        url: `user/updateUser`,
        method: "POST",
        body,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: (result, err, { _id }) => [
        { type: "Users", id: `LIST` },
        { type: "Users", id: _id },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    loginUser: builder.mutation<
      UserData,
      { idToken: string; lastLoggedIn: UserData["lastLoggedIn"] }
    >({
      query: (body) => ({
        url: `user/login`,
        method: "POST",
        body,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: [{ type: "Users", id: `LIST` }],
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useUpdateUserMutation,
  useGetUserQuery,
  useCreateUserMutation,
  useLoginUserMutation,
} = userApi;
