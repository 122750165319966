import Tasks from 'features/maintenance/Tasks';
import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { WorkOrder, WorkOrderStatus } from 'store/models/WorkOrder';
import { setBladeMode, setGlobalMessage } from 'store/slices/systemSlice';
import { dateFromMMDDYYYY, getFullName, toDDMMMYYYY } from 'utils/util';
import usePermissions, {
  FeatureType,
  ActionType,
  UIActionType,
} from "hooks/usePermissions";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from 'formik';
import { useAppDispatch } from 'store/store';
import { fCurrency } from 'utils/formatNumber';
import AttachmentList from 'components/AttachmentList';
import ImageCarousel from 'components/image/ImageCarousel';
import { Photo } from 'store/models/Photo';
import { selectCurrentWorkOrderId, setCurrentWorkOrderId  } from 'store/slices/maintenanceSlice';
import { setCurrentContractId } from 'store/slices/contractSlice';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { WebWOQueryResponse, useGetWorkOrderByIdQuery } from 'store/services/workOrder';
import { setCurrentAssetId } from 'store/slices/assetSlice';
import { useGetPhotosByWorkOrderIdQuery, useAddWorkOrderPhotoMutation, useRemoveWorkOrderPhotoMutation } from 'store/services/workOrderPhoto';
import { useGetClientContactByIdQuery } from 'store/services/clientContact';
import { useGetContractByIdQuery } from 'store/services/contract';
import { parseAddress } from 'store/models/Address';


interface WorkOrderRowProps {
  label: string;
  value?: ReactNode;
}

const WorkOrderRow: FC<WorkOrderRowProps> = ({ label, value }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      my={0.5}
    >
      <Typography sx={{ color: "text.secondary" }}>{label}</Typography>
      <Box sx={{ flex: 1, textAlign: "right" }}>{value}</Box>
    </Box>
  );
};

interface Props {
  workOrder: WebWOQueryResponse;
}

const WorkOrderView: React.FC<Props> = ({ workOrder: currentWorkOrder }) => {
  const {data: workOrderPhotos = [], isLoading} = useGetPhotosByWorkOrderIdQuery({workOrderId: currentWorkOrder?._id}, {skip: !currentWorkOrder})
  const hasParent = Boolean(currentWorkOrder.parent?.workOrder?._id)
  const {data: parentWorkOrder, isLoading: isLoadingParent} = useGetWorkOrderByIdQuery(currentWorkOrder.parent?.workOrder?._id || "", {skip: !hasParent})
  const {data: workOrderContract} = useGetContractByIdQuery(currentWorkOrder.contract?._id || "", {skip: !currentWorkOrder.contract?._id})
  const {data: siteContact} = useGetClientContactByIdQuery(workOrderContract?.siteContact || "", {skip: !workOrderContract?.siteContact})
  const {data: mainContact} = useGetClientContactByIdQuery(workOrderContract?.clientContact || "", {skip: !workOrderContract?.clientContact})
  const [addPhoto] = useAddWorkOrderPhotoMutation()
  const [deletePhoto] = useRemoveWorkOrderPhotoMutation()
  const dispatch = useAppDispatch();
  const checkAssetPermissions = usePermissions(FeatureType.ASSET);
  const currentWorkOrderId = useSelector(selectCurrentWorkOrderId)
  const checkMaintenancePermissions = usePermissions(FeatureType.MAINTENANCE);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanEditWorkOrders = checkMaintenancePermissions(ActionType.UPDATE); 
  const { setFieldValue, submitForm, values } = useFormikContext<WorkOrder>();
  const userCanViewContracts = usePermissions(FeatureType.CONTRACT)(ActionType.READ);
  const userCanViewAssets = usePermissions(FeatureType.ASSET)(ActionType.READ);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleImageUpload = (form: FormData) =>
    new Promise((resolve, reject) => {
      if (!currentWorkOrder._id) return;
      addPhoto({ workOrderId: currentWorkOrder._id, photo: form })
        .unwrap()
        .then(resolve)
        .catch(error => reject(error.data?.message));
    });

  const deleteImage = ({ _id: photoId }: Photo) => {
    if (!currentWorkOrder._id) return;
    deletePhoto({workOrderId: currentWorkOrder._id, photoId})
  };

  const updateStatus = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: WorkOrder["status"]) => {
    const userCanEditWorkOrders = checkMaintenancePermissions(ActionType.LIMITED_UPDATE, UIActionType.ONCLICK); 
    if(!userCanEditWorkOrders || !value) return;
    setFieldValue("status", value);
    submitForm()
  };

  const viewAsset = () => {
    const userCanViewAssets = checkAssetPermissions(ActionType.READ, UIActionType.ONCLICK);
    if(!userCanViewAssets) return; 
    dispatch(setBladeMode("assetViewEdit")); 
    dispatch(setCurrentAssetId(currentWorkOrder.asset._id))
  }
  const viewContract = () => {
    const userCanViewContracts = checkContractPermissions(ActionType.READ, UIActionType.ONCLICK);
    if (!userCanViewContracts || !currentWorkOrder.contract?._id) return;
    dispatch(setCurrentContractId(currentWorkOrder.contract._id))
    dispatch(setBladeMode("contractViewEdit"));
  }
  const viewParentWorkOrder = () => {
    if (parentWorkOrder && currentWorkOrderId === parentWorkOrder._id) {
      dispatch(setGlobalMessage({show: true, messageText: `Already Viewing Parent Workorder: ${parentWorkOrder.number}`, severity: "warning"}))
      return
    }
    dispatch(setCurrentWorkOrderId(parentWorkOrder?._id));
  }
  

  return (
    <>
      {currentWorkOrder && (
        <ImageCarousel
          handleImageUpload={handleImageUpload}
          isLoading={isLoading}
          deleteImage={deleteImage}
          images={workOrderPhotos}
          permission={userCanEditWorkOrders}
          allowMultiple
        />
      )}
      <Card
        sx={(theme) => ({
          p: 3,
          [theme.breakpoints.down("sm")]: {
            p: 1,
          },
        })}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {!currentWorkOrder.inspection && (
            <>
              <WorkOrderRow label="Title" value={currentWorkOrder.title} />

              <WorkOrderRow label="Description" />
              <Typography sx={{ mt: -1, mb: 2 }}>
                {currentWorkOrder.description}
              </Typography>
            </>
          )}
          <WorkOrderRow label="Priority" value={currentWorkOrder.priority} />
          <ToggleButtonGroup
            exclusive
            fullWidth
            value={values.status}
            onChange={updateStatus}
            size="small"
            color="primary"
            orientation={isSmallScreen ? "vertical" : undefined}
          >
            {Object.values(WorkOrderStatus).map((status) => (
              <ToggleButton
                data-testid="status"
                sx={{ minWidth: 100 }}
                value={status}
                key={status}
                disabled={
                  status === WorkOrderStatus.COMPLETE &&
                  values.tasks &&
                  !values?.tasks.every((task) => task.complete)
                }
              >
                {status}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <WorkOrderRow
            label="Assigned to"
            value={
              getFullName(currentWorkOrder?.assignedTo || undefined) ||
              "Unassigned"
            }
          />
          {!currentWorkOrder.inspection && (
            <>
              <WorkOrderRow label="Tasks" />
              <Tasks mode="view" />
            </>
          )}
          <WorkOrderRow
            label="Asset"
            value={
              !userCanViewAssets ? (
                currentWorkOrder.asset?.serialNumber
              ) : (
                <Button
                  variant="outlined"
                  sx={{ minWidth: "140px", justifyContent: "space-between" }}
                  startIcon={<OpenInNew />}
                  onClick={viewAsset}
                  color="inherit"
                >
                  {currentWorkOrder.asset?.serialNumber}
                </Button>
              )
            }
          />
          <WorkOrderRow
            label="Contract"
            value={
              <Button
                variant="outlined"
                sx={{ minWidth: "140px", justifyContent: "space-between" }}
                startIcon={
                  currentWorkOrder.contract?._id ? <OpenInNew /> : undefined
                }
                onClick={viewContract}
                color="inherit"
                disabled={
                  !userCanViewContracts || !currentWorkOrder.contract?._id
                }
              >
                {currentWorkOrder.contract?._id
                  ? `${currentWorkOrder.contract?.customerName} - ${currentWorkOrder.contract?.projectNumber}`
                  : "No Contract Assigned"}
              </Button>
            }
          />
          <WorkOrderRow
            label="Main Contact"
            value={getFullName(mainContact) || "N/A"}
          />
          <WorkOrderRow
            label="Main Contact Phone"
            value={mainContact?.primaryPhone || "N/A"}
          />
          <WorkOrderRow
            label="Site Contact"
            value={getFullName(siteContact) || "N/A"}
          />
          <WorkOrderRow
            label="Site Contact Phone"
            value={siteContact?.primaryPhone || "N/A"}
          />{" "}
          <WorkOrderRow
            label="Contract Ship To"
            value={parseAddress(workOrderContract?.shipTo) || "N/A"}
          />
          {hasParent ? (
            <WorkOrderRow
              label="Parent Inspection"
              value={
                <Button
                  variant="outlined"
                  sx={{ minWidth: "140px", justifyContent: "space-between" }}
                  startIcon={<OpenInNew />}
                  onClick={viewParentWorkOrder}
                  color="inherit"
                  disabled={!Boolean(parentWorkOrder?.number)}
                >
                  WO #:{" "}
                  {isLoadingParent ? (
                    <CircularProgress size={16} />
                  ) : (
                    parentWorkOrder?.number
                  )}
                </Button>
              }
            />
          ) : null}
          <WorkOrderRow
            label="Created on"
            value={toDDMMMYYYY(dateFromMMDDYYYY(currentWorkOrder.createdOn))}
          />
          <WorkOrderRow
            label="Created by"
            value={getFullName(currentWorkOrder.createdBy) || "N/A"}
          />
          <WorkOrderRow
            label="Due Date"
            value={toDDMMMYYYY(dateFromMMDDYYYY(currentWorkOrder.dueDate))}
          />
          <WorkOrderRow
            label="Completed Date"
            value={
              currentWorkOrder.completedDate
                ? toDDMMMYYYY(dateFromMMDDYYYY(currentWorkOrder.completedDate))
                : "Not Yet Completed"
            }
          />
          {currentWorkOrder.status === "COMPLETE" &&
            currentWorkOrder.completedBy?.firstName && (
              <WorkOrderRow
                label="Completed by"
                value={getFullName(currentWorkOrder.completedBy) || "N/A"}
              />
            )}
          {currentWorkOrder.tasks && (
            <>
              <WorkOrderRow
                label="Category"
                value={currentWorkOrder.category}
              />

              <WorkOrderRow
                label="SubCategory"
                value={currentWorkOrder.subcategory}
              />

              <WorkOrderRow
                label="Est. Hours"
                value={currentWorkOrder.estimatedHours}
              />

              <WorkOrderRow
                label="Est. Material Cost"
                value={`${fCurrency(
                  currentWorkOrder.estimatedMaterials?.amount
                )} ${currentWorkOrder.estimatedMaterials?.currency}`}
              />

              <WorkOrderRow
                label="Actual Hours"
                value={
                  currentWorkOrder.status === "COMPLETE"
                    ? currentWorkOrder.actualHoursToComplete
                    : "Not yet complete"
                }
              />

              <WorkOrderRow
                label="Actual Material Cost"
                value={
                  currentWorkOrder.status === "COMPLETE"
                    ? `${fCurrency(
                        currentWorkOrder.actualMaterialsToComplete?.amount
                      )} ${
                        currentWorkOrder.actualMaterialsToComplete?.currency
                      }`
                    : "Not yet complete"
                }
              />
            </>
          )}
          <WorkOrderRow
            label="PO Number"
            value={currentWorkOrder.purchaseOrder?.poNumber}
          />
          <WorkOrderRow
            label="PO Amount"
            value={
              currentWorkOrder.purchaseOrder?.amount
                ? `${fCurrency(
                    currentWorkOrder.purchaseOrder?.amount.amount
                  )} ${currentWorkOrder.purchaseOrder.amount?.currency}`
                : "No PO Assigned"
            }
          />
          {currentWorkOrder.tasks && (
            <>
              <WorkOrderRow
                label="Billable to Client"
                value={currentWorkOrder.billableToClient ? "Yes" : "No"}
              />
              <WorkOrderRow
                label="Must be Fixed Before Renting?"
                value={currentWorkOrder.isBlocking ? "Yes" : "No"}
              />
            </>
          )}
        </Box>
      </Card>
      {currentWorkOrder?._id && (
        <AttachmentList
          documentId={currentWorkOrder?._id}
          disabled={!userCanEditWorkOrders}
          documentType="workOrder"
        />
      )}
    </>
  );
};

export default WorkOrderView;

