import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setCurrentClientId, selectCurrentClientId } from 'store/slices/clientSlice';
import { selectBladeMode, simpleGlobalMessage } from 'store/slices/systemSlice';
import { Form, Formik, FormikHelpers } from 'formik';
import { initClient } from 'store/api/client';
import { clientValidationSchema } from 'utils/formikAPI';
import usePermissions, { ActionType, FeatureType, UIActionType } from 'hooks/usePermissions';
import Save from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import Edit from '@mui/icons-material/Edit';
import { Box, Card, Typography, IconButton, CircularProgress } from '@mui/material';
import BladeTabs from 'components/BladeTabs';
import { Client } from 'store/models/Client';
import _ from "lodash"
import Blade from 'components/Blade';
import { useAppDispatch } from 'store/store';
import ClientContracts from './bladeContent/ClientContracts';
import ClientForm from './bladeContent/ClientForm';
import ClientView from './bladeContent/ClientView';
import ClientPersonnel from './bladeContent/ClientPersonnel';
import { parseAddress } from 'store/models/Address';
import { useGetClientByIdQuery, useUpdateClientMutation } from 'store/services/client';
import LoadingComponent from 'components/LoadingComponent';
import useLoggedInUser from 'hooks/useLoggedInUser';

interface Props {}

const ClientViewEditBlade: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentClientId = useSelector(selectCurrentClientId);
  const {data: currentClient, isLoading: currentClientLoading} = useGetClientByIdQuery(currentClientId || "", { skip: !currentClientId });
  const [updateClient] = useUpdateClientMutation();

  const bladeMode = useSelector(selectBladeMode);
  const bladeOpen = bladeMode === "clientViewEdit" && Boolean(currentClientId);
  const { userDefaultCountry } = useLoggedInUser()
  const initialValues = currentClient || initClient({country: userDefaultCountry});
  const checkClientPermissions = usePermissions(FeatureType.CLIENTS);
  const userCanEditClients = checkClientPermissions(ActionType.UPDATE);
  const [isEditing, setEditing] = useState(false);

  const handleUpdateClient = (client: Client, { setSubmitting, resetForm }: FormikHelpers<Client>) => {
    updateClient(client).unwrap().then(() => {
      resetForm();
      setEditing(false);
    }).catch((error) => {
      setSubmitting(false);
      dispatch(simpleGlobalMessage(error.data?.message || error.message || 'An error occurred', "error"));
    }
    );
  };



  const enableEditing = () => {
    const userCanEditClients = checkClientPermissions(ActionType.UPDATE, UIActionType.ONCLICK);
    if(!userCanEditClients) return;
    setEditing(true);
  };

  const handleClose = (resetForm: FormikHelpers<Client>["resetForm"]) => {
    dispatch(setCurrentClientId(undefined));
    resetForm();
  }

  const BladeActions = useCallback(
    ({
      submitForm,
      isSubmitting,
      resetForm,
      values,
    }: {
      submitForm: FormikHelpers<Client>["submitForm"];
      isSubmitting: boolean;
      resetForm: FormikHelpers<Client>["resetForm"];
      values: Client;
    }) => {
      return isEditing
        ? [
            <IconButton
              color="primary"
              onClick={submitForm}
              disabled={_.isEqual(values, currentClient) || isSubmitting}
              data-testid="submitClientForm"
              key="submitClientForm"
            >
              {isSubmitting ? <CircularProgress size={24} /> : <Save />}
            </IconButton>,
            <IconButton
              onClick={() => {
                setEditing(false);
                resetForm();
              }}
              key="cancel"
            >
              <Cancel />
            </IconButton>,
          ]
        : [
            <IconButton
              key="edit"
              onClick={enableEditing}
              color="primary"
              disabled={!userCanEditClients}
            >
              <Edit />
            </IconButton>,
          ];
    },
    [isEditing, currentClient, userCanEditClients]
  );

  useEffect(function resetEditModeOnModuleOrClientChange() {
    setEditing(false);
  }, [currentClient])

  return (
    <LoadingComponent isLoading={currentClientLoading}>
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleUpdateClient}
      validationSchema={clientValidationSchema}
    >
      {({
        resetForm,
        submitForm,
        values,
        isSubmitting,
      }) => {

        return (
          <Form>
            <Blade
              open={bladeOpen}
              changeOpen={() => handleClose(resetForm)}
              backgroundColor="white"
              onTop={bladeMode === "clientViewEdit"}
              actions={BladeActions({submitForm, isSubmitting, resetForm, values})}
            >
              <Box>
                <Typography variant="h5">
                  {currentClient?.companyName}
                </Typography>
                <Typography variant="body2">
                  {currentClient?.billTos[0] ? parseAddress(currentClient?.billTos[0]) : "-"}
                </Typography>
              </Box>
              <BladeTabs
                tabs={[
                  {
                    tabName: "Details",
                    tabComponent: (
                      <Card sx={{ p: 3 }}>
                        {isEditing ? <ClientForm /> : <ClientView />}
                      </Card>
                    ),
                  },
                  {
                    tabName: "Contracts",
                    tabComponent: (
                      <Card sx={{ p: 3 }}>
                        <ClientContracts />
                      </Card>
                    ),
                  },  {
                    tabName: "People",
                    tabComponent: (
                      <Card sx={{ p: 3 }}>
                        <ClientPersonnel />
                      </Card>
                    ),
                  },
                ]}
                isClosing
              />
            </Blade>
          </Form>
        );
      }}
    </Formik>
    </LoadingComponent>
  );
};
export default ClientViewEditBlade;
