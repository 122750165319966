import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectBladeMode, setBladeMode, setGlobalMessage, simpleGlobalMessage } from 'store/slices/systemSlice';
import Blade from 'components/Blade';
import WorkOrderForm from 'features/maintenance/WorkOrderForm';
import { CircularProgress, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';
import Cancel from '@mui/icons-material/Cancel';
import Save from '@mui/icons-material/Save';
import { initInspectionWorkOrder, initStandardWorkOrder } from 'store/api/workOrder';
import { inspectionWorkOrderValidation, workOrderValidationSchema } from 'utils/formikAPI';
import { selectCurrentAssetId } from 'store/slices/assetSlice';
import { useAppDispatch } from 'store/store';
import { createMongoId } from 'utils/createMongoId';
import { PrimitiveSelect } from 'components/select';
import useInvalidFieldsAlert from 'hooks/useInvalidFieldsAlert';
import { getWorkOrderFromQueryResponse, useCreateWorkOrderMutation, WebWOQueryResponse } from 'store/services/workOrder';
import { selectCurrentContractId } from 'store/slices/contractSlice';
import { useGetContractByIdQuery } from 'store/services/contract';
import { useGetAssetByIdQuery } from 'store/services/asset';
import useLoggedInUser from 'hooks/useLoggedInUser';
import { useGetInspectionOptionsQuery } from 'store/services/config';

interface Props {}

const WorkOrderCreateBlade: React.FC<Props> = () => {
  const bladeMode = useSelector(selectBladeMode)
  const dispatch = useAppDispatch();
  const currentAssetId = useSelector(selectCurrentAssetId);
  const {currentData: currentAsset } = useGetAssetByIdQuery(currentAssetId || "", {skip: !currentAssetId})
  const currentContractId = useSelector(selectCurrentContractId);
  const {currentData: currentContract} = useGetContractByIdQuery(currentContractId || '', {skip: !currentContractId })
  const { loggedInUser, userDefaultCurrency } = useLoggedInUser()
  const {data: inspectionTypes} = useGetInspectionOptionsQuery()
  const inspectionTypesByName = useMemo(() => inspectionTypes?.reduce((acc, curr) => 
    ({...acc, [curr.displayName]: curr}), {} as Record<string, typeof inspectionTypes[0]>), [inspectionTypes])
  const [isInspection, setIsInspection] = useState(false)
  const [inspectionType, setInspectionType] = useState<string>("")

  const [createWorkOrder] = useCreateWorkOrderMutation()

  
  const initialValues: WebWOQueryResponse = useMemo(
    () =>
      isInspection
        ? {
            ...initInspectionWorkOrder(userDefaultCurrency, loggedInUser),
            asset: {
              _id: currentAsset?._id || '',
              assetNumber: currentAsset?.assetNumber || '',
              serialNumber: currentAsset?.serialNumber || '',
            },
            contract: currentContract ? {
              _id: currentContract?._id,
              projectNumber: currentContract?.projectNumber,
              customerName: currentContract?.customerName,
            } : undefined,
            assignedTo: undefined,
            createdBy: {
              _id: loggedInUser?._id || '',
              firstName: loggedInUser?.firstName || '',
              lastName: loggedInUser?.lastName || '',
            },
            completedBy: null,
            parent: undefined
          }
        : {
            ...initStandardWorkOrder(userDefaultCurrency, loggedInUser),
            asset: {
              _id: currentAsset?._id || '',
              assetNumber: currentAsset?.assetNumber || '',
              serialNumber: currentAsset?.serialNumber || '',
            },
            contract: currentContract ? {
              _id: currentContract?._id,
              projectNumber: currentContract?.projectNumber,
              customerName: currentContract?.customerName,
            } : undefined,
            assignedTo: undefined,
            createdBy: {
              _id: loggedInUser?._id || '',
              firstName: loggedInUser?.firstName || '',
              lastName: loggedInUser?.lastName || '',
            },
            completedBy: null,
            parent: undefined
          },
    [isInspection, bladeMode, currentAsset, currentAssetId, currentContractId, loggedInUser]
  );

  const showInvalidFieldAlert = useInvalidFieldsAlert<WebWOQueryResponse>();

  const handleSubmit = (
    workOrderQueryResponse: WebWOQueryResponse,
    { setSubmitting, resetForm }: FormikHelpers<WebWOQueryResponse>,
  ) => {
    if (!inspectionTypesByName) return
    const workOrder = getWorkOrderFromQueryResponse(workOrderQueryResponse)

    if (isInspection) {
      if(!inspectionType) {
        dispatch(simpleGlobalMessage("Must select an Inspection Type"));
        setSubmitting(false)
        return;
      }

      const inspectionWorkOrder = { workOrder, type: "inspection" as const, inspectionType: inspectionTypesByName[inspectionType].name }
      createWorkOrder(inspectionWorkOrder)
        .unwrap()
        .then(() => {
          onComplete()
        });
 
    } else {
     createWorkOrder({workOrder, type: "workOrder"}).unwrap().then(() => onComplete())
    }

    function onComplete() {
      dispatch(setBladeMode("none"));
          resetForm({values: {...initialValues, _id: createMongoId()}});
          setSubmitting(false)
          setIsInspection(false)
          setInspectionType("")
          dispatch(setGlobalMessage({show: true, messageText: "Successfully created work order", severity: "success"}))
    }
    
  };

  return(
    <Formik
      initialValues={initialValues}
      validationSchema={isInspection ? inspectionWorkOrderValidation : workOrderValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, isSubmitting, dirty, resetForm, submitForm }) => {

        const resetbladeDetails = () => {
          resetForm()
          setInspectionType("")
        }

        const handleClose = () => {
          resetForm()
          setIsInspection(false)
          setInspectionType("")
          dispatch(setBladeMode("none"));
        }

        return (
          <Blade
            open={bladeMode === "workOrderCreate"}
            changeOpen={handleClose}
            backgroundColor="white"
            onTop={bladeMode === "workOrderCreate"}
            title="New Work Order"
            actions={[
              <IconButton
                color="primary"
                key="submit"
                onClick={() => {
                  submitForm();
                  showInvalidFieldAlert(errors);
                }}
              >
                {isSubmitting ? <CircularProgress size={24} /> : <Save />}
              </IconButton>,
              <IconButton
                key="cancel"
                color="primary"
                onClick={resetbladeDetails}
              >
                <Cancel />
              </IconButton>,
            ]}
          >
            <ToggleButtonGroup
              exclusive
              value={String(isInspection)}
              onChange={(_, value) => {
                if (value) {
                  setIsInspection(value === "true");
                }
              }}
              size="small"
              color="primary"
              fullWidth
            >
              <ToggleButton data-testid="notSigned" value="true">
                Inspection
              </ToggleButton>
              <ToggleButton data-testid="isSigned" value="false">
                Standard
              </ToggleButton>
            </ToggleButtonGroup>

            {isInspection && (
              <PrimitiveSelect
                value={inspectionType}
                options={inspectionTypes?.map((type) => type.displayName) || []}
                onChange={({ target }) => setInspectionType(target.value)}
                label="Inspection Type"
                size="small"
                error={dirty && !inspectionType}
              />
            )}

            <WorkOrderForm
              mode="create"
              isInspection={isInspection}
            />
          </Blade>
        );
      }}
    </Formik>
  )
};

export default WorkOrderCreateBlade;
