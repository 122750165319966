import { AlertProps } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { signOut, getAuth } from "firebase/auth"
import { STARFLEET_STORE, AppDispatch } from "store/store";
import { ToastProps } from "components/Toast";
import { createMongoId } from "utils/createMongoId";

export interface GLOBAL_MESSAGE extends Omit<ToastProps, "messageContent" | "onClose" | "action"> {
  messageText: string;
  action?: {
    actionName: string;
    actionFunction: React.MouseEventHandler;
  };
};

export type BladeMode =
  | "none"
  | "movementViewEdit"
  | "assetCreate"
  | "assetReserve"
  | "workOrderCreate"
  | "workOrderViewEdit"
  | "assetViewEdit"
  | "clientCreate"
  | "clientViewEdit"
  | "contractViewEdit"
  | "userCreate"
  | "userViewEdit"
  | "transportCompanyCreate"
  | "transportCompanyViewEdit"
  | "transportCompanyCreate"

type ModalMode = "none" | "billOfLadingViewEdit" | "billOfLadingCreate";

export type SYSTEM_STORE = {
  globalMessage: GLOBAL_MESSAGE;
  loggedInUserId: string | undefined;
  versionBlocked: boolean;
  bladeMode: BladeMode;
  modalMode: ModalMode;
  browserTabId: string;
};

const initialState: SYSTEM_STORE = {
  globalMessage: {
    messageText: "",
    show: false,
    xPosition: "center"
  },
  loggedInUserId: undefined,
  versionBlocked: false,
  bladeMode: "none",
  modalMode: "none",
  browserTabId: createMongoId(),
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setGlobalMessage(state, action: PayloadAction<GLOBAL_MESSAGE>) {
      state.globalMessage = action.payload;
    },
    setLoggedInUserId(state, action: PayloadAction<string | undefined>) {
      state.loggedInUserId = action.payload;
    },
    setVersionBlocked(state, action: PayloadAction<boolean>) {
      state.versionBlocked = action.payload;
    },
    setBladeMode(state, action: PayloadAction<BladeMode>) {
      state.bladeMode = action.payload;
    },
    setModalMode(state, action: PayloadAction<ModalMode>) {
      state.modalMode = action.payload;
    },
  },
});


export const simpleGlobalMessage = (messageText: string, severity: AlertProps["severity"] = "warning") => {
  return (dispatch: Function) => {
    dispatch(
      setGlobalMessage({
        messageText,
        severity,
        show: true,
      })
    );
  };
};

export const globalMessageWithAction = (
  messageText: string,
  actionName: string,
  actionFunction: React.MouseEventHandler,
  severity?: AlertProps["severity"]
) => {
  return (dispatch: Function) => {
    dispatch(
      setGlobalMessage({
        messageText,
        action: {
          actionName,
          actionFunction,
        },
        show: true,
        severity,
      })
    );
  };
};

export const clearGlobalMessage = () => {
  return (dispatch: Function) => {
    dispatch(
      setGlobalMessage({
        messageText: "",
        show: false,
      })
    );
  };
};

export const selectLoggedInUserId = (state: STARFLEET_STORE) =>
  state.system.loggedInUserId;


export const selectVersionBlocked = (state: STARFLEET_STORE) =>
  state.system.versionBlocked;

export const selectBladeMode = (state: STARFLEET_STORE) =>
  state.system.bladeMode;

export const selectModalMode = (state: STARFLEET_STORE) =>
state.system.modalMode;

export const selectGlobalMessage = (state: STARFLEET_STORE) => state.system.globalMessage;

export const selectBrowserTabId =  (state: STARFLEET_STORE) => state.system.browserTabId;

export const logout = ()  => async (dispatch: AppDispatch) => {
  dispatch(setLoggedInUserId(undefined));
  const auth = getAuth();
  await signOut(auth);
};

export const {
  setGlobalMessage,
  setLoggedInUserId,
  setVersionBlocked,
  setBladeMode,
  setModalMode,
} = systemSlice.actions;

export default systemSlice.reducer;
