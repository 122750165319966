import LoginScreen from "auth/LoginScreen";
import GlobalMessage from "components/GlobalMessage";
import React from "react";
import useAuthController from "auth/useAuthController";
import Starfleet from "routes/Starfleet";
import LoadingScreen from "components/LoadingScreen";
import useLoggedInUser from "hooks/useLoggedInUser";

const Main: React.FC = () => {
  
  const {loggedInUser, loggedInUserLoading } = useLoggedInUser()
  
  const {initAuthLoading, handleLogin} = useAuthController();

  const main = () => {
    switch (true) {
      case loggedInUserLoading || initAuthLoading:        
        return <LoadingScreen />;
      case Boolean(loggedInUser):        
        return <Starfleet />;
      default:
        return <LoginScreen handleLogin={handleLogin} />;
    }
  };

  return (
    <>
      <GlobalMessage />
      {main()}
    </>
  );
};
export default Main;
