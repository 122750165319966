import useConfigs from "hooks/useConfigs";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "store/services/user";
import {
  selectLoggedInUserId
} from "store/slices/systemSlice";


const useLoggedInUser = () => {
  
  const loggedInUserId = useSelector(selectLoggedInUserId);

  const { currentData: loggedInUser, isLoading: loggedInUserLoading } =
    useGetUserQuery(loggedInUserId || "", { skip: !loggedInUserId });

  const {countries} = useConfigs(!loggedInUserId);
  const userDefaultCountry = loggedInUser?.countries[0] || "CAN";
  const userDefaultCurrency = userDefaultCountry
    ? countries?.settings[userDefaultCountry]?.currency
    : "CAD";




  return {
    loggedInUser,
    loggedInUserLoading,
    userDefaultCountry,
    userDefaultCurrency,
  };
};

export default useLoggedInUser;
