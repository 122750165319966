import React from "react";
import { Asset } from "store/models/Asset";
import { useSelector } from "react-redux";
import AssetForm from "features/assets/AssetForm";
import Blade from "components/Blade";
import {
  selectBladeMode,
  setBladeMode,
  simpleGlobalMessage,
} from "store/slices/systemSlice";
import { Form, Formik, FormikHelpers } from "formik";
import { assetValidationSchema } from "utils/formikAPI";
import _ from "lodash";
import Cancel from "@mui/icons-material/Cancel";
import Save from "@mui/icons-material/Save";
import { IconButton } from "@mui/material";
import { useAppDispatch } from 'store/store';
import { initAsset } from "store/api/asset";
import { LoadingButton } from "@mui/lab";
import { useCreateAssetMutation } from "store/services/asset";
import useLoggedInUser from "hooks/useLoggedInUser";

interface Props {}
const AssetCreateBlade: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const bladeMode = useSelector(selectBladeMode);
  const { userDefaultCurrency } = useLoggedInUser()
  const initialValues = initAsset({currency: userDefaultCurrency})
  const [createAsset, {isLoading: isCreating}] = useCreateAssetMutation()

  const handleSubmit = (
    asset: Asset,
    { setSubmitting, resetForm }: FormikHelpers<Asset>
  ) => {
    createAsset(asset).unwrap().then(() => {
      resetForm({ values: initAsset({ currency: userDefaultCurrency }) });
      dispatch(setBladeMode("none"));
      dispatch(simpleGlobalMessage("Successfully Created Asset", "success"));
    }
    ).catch((error) => {
      setSubmitting(false);
      dispatch(simpleGlobalMessage(error.data.message || "Error Creating Asset", "error"));
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={assetValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, resetForm }) => {

        
        const bladeActions = [
              <IconButton
                color="primary"
                type="submit"
                disabled={_.isEqual(initialValues, values)}
                data-testid="submitUser"
                key="submitUser"
              >
                <Save />
              </IconButton>,
              <IconButton
                onClick={() => resetForm()}
                key="close"
              >
                <Cancel />
              </IconButton>,
            ];
        return (
          <Form>
            <Blade
              open={bladeMode === "assetCreate"}
              changeOpen={() => dispatch(setBladeMode("none"))}
              backgroundColor="white"
              onTop={bladeMode === "assetCreate"}
              title="New Asset"
              actions={bladeActions}
            >
              <AssetForm isCreating />
              <LoadingButton
                color="primary"
                variant="contained"
                fullWidth
                disabled={isSubmitting || isCreating}
                loading={isSubmitting}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Blade>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssetCreateBlade;
