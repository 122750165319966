import Save from "@mui/icons-material/Save";
import { Box, Button, CardMedia, Grid, TextField, useTheme } from "@mui/material";
import { BranchAutoComplete } from "components/autocomplete";
import AddressInput from "components/google-map/AddressInput";
import { mapIds } from "components/google-map/AddressSelectDialog";
import PinWithHole from "components/google-map/PinWithHole";
import { Formik, Form, FormikHelpers } from "formik";
import useLoggedInUser from "hooks/useLoggedInUser";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Address, { defaultAddress } from "store/models/Address";
import { Branch } from "store/models/Branch";
import { Yard } from "store/models/Yard";
import { useGetBranchesQuery } from "store/services/branches";
import { useUpdateYardMutation } from "store/services/yard";
import { setGlobalMessage } from "store/slices/systemSlice";
import { byIds } from "store/sliceUtils";
import { useAppDispatch } from "store/store";
import { isOfDocumentType } from "utils/util";

interface Props {
  yard: Yard;
  branchId: string;
}

const YardDetails: React.FC<Props> = ({ yard, branchId }) => {
   const {data: branches = []} = useGetBranchesQuery();
   const branchesHash = byIds(branches);
   const yardBranch = branchesHash[yard.branch];
  const [updateYard] = useUpdateYardMutation();
  const [address, setAddress] = useState<Address>(
    yard.address || defaultAddress
  );
  const {loggedInUser} = useLoggedInUser()
  const checkBranchPermissions = usePermissions(FeatureType.BRANCH);
  const useCanUpdateYard =
    checkBranchPermissions(ActionType.UPDATE) &&
    loggedInUser?.branches.includes(branchId);

  const dispatch = useAppDispatch();
  const handleSubmit = (
    values: Yard,
    { setSubmitting }: FormikHelpers<Yard>
  ) => {
    updateYard(values).unwrap().then((data) => {
      dispatch(setGlobalMessage({
        show: true,
        severity: "success",
        messageText: `Successfully updated ${yard.name} yard`
      }));
      setSubmitting(false);
    }).catch((error) => {
      dispatch(
        setGlobalMessage({
          show: true,
          severity: "error",
          messageText: error.data?.message || error.message || "An error occurred",
        })
      );
      setSubmitting(false);
    })
  };

  const mapRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    loadMap();
  }, [yard.address.latLng.lat, address.latLng.lat]);

  useEffect(() => {
    setAddress(yard.address);
  }, [yard.address]);

  const handleBranchChange = (
    value: NonNullable<string | Branch> | (string | Branch)[] | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (isOfDocumentType<Branch>(value)) {
      setFieldValue("branch", value);
    }
  };

  async function loadMap() {
    const mapsAddress = Boolean(yard.address.latLng.lat)
      ? {
          center: {
            lat: parseInt(address.latLng.lat),
            lng: parseInt(address.latLng.lng),
          },
          zoom: 7,
        }
      : defaultAddress;
    const map = new window.google.maps.Map(mapRef.current!, {
      ...mapsAddress,
      mapId: theme.palette.mode === "light" ? mapIds.light : mapIds.dark,
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
      zoomControl: false,
      streetViewControl: false,
      gestureHandling: "cooperative", // Set to "none" to disable ability to move map around
    });
    const marker = new window.google.maps.Marker({
      position: {
        lat: parseInt(address.latLng.lat),
        lng: parseInt(address.latLng.lng),
      },
      map,
      draggable: true,
      icon: {
        path: PinWithHole,
        fillColor: theme.palette.primary.main,
        fillOpacity: 1,
        strokeColor: theme.palette.primary.dark,
        strokeWeight: 1,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(12, 22),
      },
    });
  }

  return (
    <Grid container sx={{ p: 3, pt: 2 }}>
      <Grid item xs={12}>
        <Formik
          initialValues={{ ...yard }}
          onSubmit={handleSubmit}
          enableReinitialize
          disabled={!useCanUpdateYard}
        >
          {({ errors, handleBlur, setFieldValue, touched, values }) => (
            <Form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      minWidth: "200px",
                      width: { md: "inherit", xs: "100%" },
                    }}
                    disabled={_.isEqual(yardBranch, values)}
                    startIcon={<Save />}
                  >
                    Save
                  </Button>
                </Box>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="name"
                      label="Yard Name"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={({ target }) =>
                        setFieldValue("name", target.value.trim())
                      }
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: "off",
                        form: { autoComplete: "off" },
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="country"
                      label="Country"
                      value={values.country}
                      error={Boolean(touched.country && errors.country)}
                      fullWidth
                      disabled
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: "off",
                        form: { autoComplete: "off" },
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <BranchAutoComplete
                      value={branchesHash[yard.branch]}
                      label="Yard Branch"
                      onChange={(event, value) =>
                        handleBranchChange(value, setFieldValue)
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AddressInput
                      onAddressSelect={(address) => {
                        setFieldValue("address", address);
                        setAddress(address);
                      }}
                      address={values.address}
                      showAddress
                      restrictToCountry={yardBranch?.country === "CAN" ? "ca" : "us"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CardMedia
                      sx={{ borderRadius: 1, height: 440, width: "100%" }}
                      ref={mapRef}
                      component="div"
                      id="googleMap"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default YardDetails;
