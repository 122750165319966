import { Typography, Grid, Card, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CurrencyTextField from "components/CurrencyTextField";
import DateSelector from "components/DateSelector";
import ImageCarousel from "components/image/ImageCarousel";
import NumberTextField from "components/NumberTextField";
import { PrimitiveSelect } from "components/select";
import { FastField, FastFieldProps, Field, useFormikContext } from "formik";
import usePermissions, { FeatureType, ActionType } from "hooks/usePermissions";
import { FC, ReactNode, useEffect } from "react";
import { IChecklistItem } from "store/models/Inspection";
import { Photo } from "store/models/Photo";
import { Priority } from "store/models/WorkOrder";
import { WorkOrderTask } from "store/models/WorkOrderTask";
import { dateFromMMDDYYYY, toMMDDYYYY } from "utils/util";
import CheckListTasks from "./modal/CheckListTasks";
import useConfigs from "hooks/useConfigs";

interface ListItemRowProps {
  label: string;
  input?: ReactNode;
}

const ListItemRow: FC<ListItemRowProps> = ({ label, input }) => {

  return (
    <Grid container alignItems="center" mb={1.5} minHeight="40px">
      <Grid item xs={4} sx={{ color: "text.secondary" }}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={8} sx={{ textAlign: typeof input === "string" ? "right" : "" }}>
        {input}
      </Grid>
    </Grid>
  );
};

interface Props {
  images: Photo[];
  isFetchingImages: boolean;
  handleImageUpload: (form: FormData) => Promise<any>;
  deleteImage: (photo: Photo) => void;
}

const CheckListItemForm: FC<Props> = ({
  images,
  isFetchingImages,
  handleImageUpload,
  deleteImage,
}) => {
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } = useFormikContext<IChecklistItem>();
  const checkMaintenancePermissions = usePermissions(FeatureType.MAINTENANCE);
  const userCanEditInspections = checkMaintenancePermissions(ActionType.UPDATE);
  const {maintenanceCategories} = useConfigs();
  const isFailedItem = values.result === "Fail";

  useEffect(() => {
    setFieldValue("photos", images.map(({ _id }) => _id));
  },[images])

  return (
    <>
      <ImageCarousel
        handleImageUpload={handleImageUpload}
        deleteImage={deleteImage}
        images={images}
        isLoading={isFetchingImages}
        permission={userCanEditInspections}
        allowMultiple
        boxSx={{
          width: "100%",
        }}
      />
      {!isFailedItem && (
        <>
          <ListItemRow
            label="Item Quantity"
            input={
              <FastField name="quantity">
                {({ field }: FastFieldProps) => (
                  <NumberTextField
                    {...field}
                    size="small"
                    fullWidth
                    error={Boolean(touched.quantity && errors.quantity)}
                  />
                )}
              </FastField>
            }
          />

          <ListItemRow label="Notes:" />
          <FastField name="description">
            {({ field }: FastFieldProps) => (
              <TextField
                {...field}
                multiline
                minRows={3}
                size="small"
                autoComplete="off"
                fullWidth
                error={Boolean(touched.description && errors.description)}
                inputProps={{
                  "data-testid": "description",
                }}
              />
            )}
          </FastField>
        </>
      )}

      {isFailedItem && (
        <Card sx={{ p: 3 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <ListItemRow
              label="Title"
              input={
                <FastField name="title">
                  {({ field }: FastFieldProps) => (
                    <TextField
                      {...field}
                      size="small"
                      autoComplete="off"
                      fullWidth
                      error={Boolean(touched.title && errors.title)}
                      inputProps={{
                        "data-testid": "title",
                      }}
                    />
                  )}
                </FastField>
              }
            />

            <ListItemRow label="Description" />
            <FastField name="description">
              {({ field }: FastFieldProps) => (
                <TextField
                  {...field}
                  multiline
                  minRows={3}
                  size="small"
                  autoComplete="off"
                  fullWidth
                  error={Boolean(touched.description && errors.description)}
                  inputProps={{
                    "data-testid": "description",
                  }}
                  sx={{
                    mb: 3,
                    mt: -1,
                  }}
                />
              )}
            </FastField>

            <ListItemRow
              label="Priority"
              input={
                <PrimitiveSelect
                  name="priority"
                  size="small"
                  value={values.priority}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    "data-testid": "priority",
                  }}
                  options={Object.values(Priority)}
                  error={Boolean(touched.priority && errors.priority)}
                />
              }
            />

            <ListItemRow label="Tasks" />
            <CheckListTasks
              tasks={values.tasks}
              addTask={function (task: WorkOrderTask): void {
                setFieldValue("tasks", [...values.tasks, task]);
              }}
              completeTask={function (index: number): void {
                setFieldValue(
                  "tasks",
                  values.tasks.map((listItem, currentIndex) =>
                    currentIndex === index
                      ? { ...listItem, complete: true }
                      : listItem
                  )
                );
              }}
              deleteTask={function (index: number): void {
                setFieldValue(
                  "tasks",
                  values.tasks.filter(
                    (_, currentIndex) => currentIndex !== index
                  )
                );
              }}
              error={Boolean(touched.tasks && errors.tasks)}
            />

            <ListItemRow
              label="Item Quantity"
              input={
                <FastField name="quantity">
                  {({ field }: FastFieldProps) => (
                    <NumberTextField
                      {...field}
                      size="small"
                      fullWidth
                      error={Boolean(touched.quantity && errors.quantity)}
                    />
                  )}
                </FastField>
              }
            />
            <ListItemRow
              label="Due Date"
              input={
                <DateSelector
                  onChange={(date) =>
                    date && setFieldValue("dueDate", toMMDDYYYY(new Date(date)))
                  }
                  value={dateFromMMDDYYYY(values.dueDate)}
                  textFieldProps={{
                    onBlur: handleBlur,
                    fullWidth: true,
                    size: "small",
                    error: Boolean(touched.dueDate && errors.dueDate),
                  }}
                />
              }
            />

            <ListItemRow
              label="Category"
              input={
                <PrimitiveSelect
                  name="workOrderCategory"
                  size="small"
                  value={values.workOrderCategory}
                  onChange={({ target }) => {
                    if (target) {
                      setFieldValue("workOrderCategory", target.value);
                      setFieldValue("subcategory", "");
                    }
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    "data-testid": "workOrderCategory",
                  }}
                  error={Boolean(
                    touched.workOrderCategory && errors.workOrderCategory
                  )}
                  options={Object.keys(maintenanceCategories.settings)}
                />
              }
            />

            <ListItemRow
              label="Subcategory"
              input={
                <PrimitiveSelect
                  name="workOrderSubcategory"
                  size="small"
                  value={values.workOrderSubcategory}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    "data-testid": "workOrderSubcategory",
                  }}
                  error={Boolean(
                    touched.workOrderSubcategory && errors.workOrderSubcategory
                  )}
                  options={maintenanceCategories.settings[values.workOrderCategory]}
                />
              }
            />

            <ListItemRow
              label="Est. Hours"
              input={
                <FastField name="estimatedHours">
                  {({ field }: FastFieldProps) => (
                    <NumberTextField
                      {...field}
                      size="small"
                      fullWidth
                      error={Boolean(
                        touched.estimatedHours && errors.estimatedHours
                      )}
                      inputProps={{
                        "data-testid": "estimatedHours",
                      }}
                    />
                  )}
                </FastField>
              }
            />

            <ListItemRow
              label="Est. Materials Cost"
              input={
                <Field name="estimatedMaterials">
                  {({ field, form }: FastFieldProps) => (
                    <CurrencyTextField
                      {...field}
                      fullWidth
                      value={field.value}
                      onChange={(event) => {
                        form.setFieldValue(
                          "estimatedMaterials.amount",
                          Number(event.target.value) || undefined
                        );
                      }}
                      onCurrencyChange={(event) => {
                        event.target.value &&
                          form.setFieldValue(
                            "estimatedMaterials.currency",
                            event.target.value
                          );
                      }}
                      error={Boolean(
                        touched.estimatedMaterials && errors.estimatedMaterials
                      )}
                      inputProps={{
                        "data-testid": "estimatedMaterials",
                      }}
                      size="small"
                    />
                  )}
                </Field>
              }
            />
            <ListItemRow
              label="Billable to Client"
              input={
                <Field name="billableToClient">
                  {({ field, form }: FastFieldProps) => (
                    <ToggleButtonGroup
                      exclusive
                      fullWidth
                      value={String(values.billableToClient)}
                      onChange={(_, value) =>
                        value && setFieldValue("billableToClient", value)
                      }
                      size="small"
                      color="primary"
                    >
                      <ToggleButton
                        data-testid="not-billable"
                        sx={{ minWidth: 100 }}
                        value="false"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        data-testid="is-billable"
                        sx={{ minWidth: 100 }}
                        value="true"
                      >
                        Yes
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Field>
              }
            />
          </Grid>
        </Card>
      )}
    </>
  );
}

export default CheckListItemForm;