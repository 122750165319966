import UserData from "store/models/UserData";
import { createMongoId } from "utils/createMongoId";

export const initUser = (user?: Partial<UserData>): UserData => {
  return { 
    _id: createMongoId(),
    email: "",
    phone: "",
    role: "VIEWER",
    branches: [],
    isDisabled: false,
    countries: [],
    lastName: "",
    firstName: "",
    ...user
  }
}

