import React, { useEffect, useState } from "react";
import TransportCompanyView from "./TransportCompanyView";
import { useSelector } from 'react-redux';
import { selectBladeMode, setBladeMode, setGlobalMessage } from 'store/slices/systemSlice';
import usePermissions, { ActionType, FeatureType, UIActionType } from "hooks/usePermissions";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Blade from "components/Blade";
import _ from "lodash";
import TransportCompanyForm from "./TransportCompanyForm";
import { Form, Formik, FormikHelpers } from "formik";
import { useAppDispatch } from 'store/store';
import { TransportCompany, clearCurrentTransportCompanyId, selectCurrentTransportCompanyId } from "store/slices/transportCompanySlice";
import { useGetTransportCompaniesQuery, useUpdateTransportCompanyMutation } from "store/services/transportCompany";
import { initTransportCompany, transportCompanyValidationSchema } from "store/api/transportCompany";

interface Props { }

const TransportCompanyViewEditBlade: React.FC<Props> = () => {
  const bladeMode = useSelector(selectBladeMode);
  const checkMovementPermissions = usePermissions(FeatureType.MOVEMENT);
  const userCanEditTrasportCompanies = checkMovementPermissions(ActionType.UPDATE);
  const [isEditing, setEditing] = useState(false);
  const currentTransportCompanyId = useSelector(selectCurrentTransportCompanyId);
  const { data: currentTransportCompany } = useGetTransportCompaniesQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      data: data?.filter((transportCompany) => transportCompany._id === currentTransportCompanyId)[0],
      ...rest
    }),
    skip: !currentTransportCompanyId
  })
  const [updateTransportCompany] = useUpdateTransportCompanyMutation();
  const initialValues = initTransportCompany({ ...currentTransportCompany });

  const dispatch = useAppDispatch();

  const handleSubmit = async (transportCompany: TransportCompany, { setSubmitting }: FormikHelpers<TransportCompany>) => {
    try {
      setSubmitting(true);
      await updateTransportCompany(transportCompany).unwrap()
      setSubmitting(false);
      setEditing(false);
      dispatch(setGlobalMessage({ messageText: "Successfully Updated Transport Company", show: true }))
    } catch (error) {
      dispatch(setGlobalMessage({ messageText: `Error: ${error}` }))
      setSubmitting(false);
    }
  };

  const preventEditHopping = () => setEditing(false);
  const closeBlade = () => {
    dispatch(setBladeMode("none"));
    dispatch(clearCurrentTransportCompanyId())
  };

  useEffect(preventEditHopping, [currentTransportCompanyId]);

  const editMode = () => {
    const userCanEditTrasportCompanies = checkMovementPermissions(ActionType.UPDATE, UIActionType.ONCLICK);
    if (!userCanEditTrasportCompanies) return;
    setEditing(true);
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={transportCompanyValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, resetForm, submitForm, errors }) => {

        const viewMode = () => {
          setEditing(false);
          resetForm()
        }

        const bladeActions = () =>
          isEditing
            ? [
              <Tooltip title="Save" key="save">
                <IconButton
                  disabled={_.isEqual(values, currentTransportCompany)}
                  color="primary"
                  onClick={submitForm}
                  key="submitUpdate"
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SaveIcon />
                  )}
                </IconButton>
              </Tooltip>,
              <IconButton color="primary" onClick={viewMode} key="cancel">
                <CancelIcon />
              </IconButton>,
            ]
            : [
              <Tooltip title="Edit Transport Company" key="edit transport company">
                <IconButton
                  key="edit"
                  color="primary"
                  onClick={editMode}
                  disabled={!userCanEditTrasportCompanies}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ];

        return (
          <Form>
            <Blade
              title="Transport Company"
              actions={bladeActions()}
              open={Boolean(currentTransportCompanyId)}
              changeOpen={closeBlade}
              onTop={bladeMode === "transportCompanyViewEdit"}
            >
              {isEditing ? <TransportCompanyForm /> : <TransportCompanyView currentTransportCompany={currentTransportCompany} />}
            </Blade>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TransportCompanyViewEditBlade;
