import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STARFLEET_STORE } from "store/store";

export interface USERS_STORE {
  currentUserId?: string;
}

const initialState: USERS_STORE = {
  currentUserId: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUserId(state, action: PayloadAction<string | undefined>) {
      state.currentUserId = action.payload;
    },
  },
});




export const { setCurrentUserId } = userSlice.actions;

export const selectCurrentUserId = (state: STARFLEET_STORE) => state.users.currentUserId

export default userSlice.reducer;
