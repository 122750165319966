import { differenceInMonths } from "date-fns";
import { ICountry } from "store/slices/configSlice";
import { ICurrency, initCurrency } from "types/Currency";
import { dateFromMMDDYYYY, MMDDYYYY, toMMDDYYYY } from "utils/util";
import { AssetStatus } from "./AssetStatus";

export interface Asset {
  // Summary
  _id: string;
  assetNumber: string ;
  serialNumber: string;
  branch: string;
  yard: string;
  status: AssetStatus;
  yardPosition: string;
  category: string;
  subCategory: string;
  condition: number;
  branding: string;
  manufacturer: string;
  hrs: string;
  notes: string;
  // Details
  yearOfManufacture: string;
  buildingCode: string;
  acType: string;
  heatingType: string;
  cladding: string;
  electrical: string;
  fuelType: string;
  floorStyle: string;
  sizeCode: string;
  height: number;
  structuralLimit: string;
  frame: string;
  colour: string;
  wash: number;
  partitions: number;
  complex: string;
  axleCount: string;
  windRating: string;
  roofType: string;
  inServiceDate: MMDDYYYY;
  orientation: string;
  layout: string;
  keyNumber: string;
  licensePlate: string;
  stepsAndRailsDetails: string;
  // Finance
  capitalCost: ICurrency;
  accumulatedImpairment: ICurrency;
  usefulLife: number;
  residualValue: number;
  depreciationRate: number;
  // Files
  photos: string[];
  version: number;
  attachments?: string[];
  inProduction: boolean;
}

export const blankAsset = ({
  currency,
}: {
  currency: ICountry["currency"];
}): Asset => ({
  _id: "",
  assetNumber: "",
  serialNumber: "",
  branch: "",
  yard: "",
  status: "AVAILABLE",
  yardPosition: "",
  category: "",
  subCategory: "",
  condition: 10,
  branding: "",
  manufacturer: "",
  hrs: "",
  notes: "",
  yearOfManufacture: "",
  buildingCode: "",
  acType: "",
  heatingType: "",
  cladding: "",
  electrical: "",
  fuelType: "",
  floorStyle: "",
  sizeCode: "",
  height: 0,
  structuralLimit: "",
  frame: "",
  colour: "",
  wash: 0,
  partitions: 0,
  complex: "",
  axleCount: "",
  windRating: "",
  roofType: "",
  inServiceDate: toMMDDYYYY(new Date()),
  orientation: "",
  layout: "",
  keyNumber: "",
  licensePlate: "",
  stepsAndRailsDetails: "",
  capitalCost: initCurrency({ amount: 0, currency }),
  accumulatedImpairment: initCurrency({ amount: 0, currency }),
  usefulLife: 1,
  residualValue: 1,
  photos: [],
  version: 0,
  attachments: [],
  inProduction: true,
  depreciationRate: 0,
});

export function calcMonthlyDepreciation(asset: Asset) {
  const {capitalCost, residualValue, usefulLife } = asset;
  return ((capitalCost.amount * (1 - (residualValue / 100)))) / (usefulLife * 12);
}

export function calcAccumulatedAmortization(asset: Asset) {
  const usefulLifeInMonths = asset.usefulLife * 12;
  const { inServiceDate } = asset;
  if (!inServiceDate) return "No In Service Date";
  return Math.min(differenceInMonths(new Date(), dateFromMMDDYYYY(inServiceDate)), usefulLifeInMonths) * calcMonthlyDepreciation(asset)
}

export function calcNetBookValue(asset: Asset) {
  const { capitalCost, accumulatedImpairment } = asset;
  const accumulatedAmortization = calcAccumulatedAmortization(asset);
  return typeof accumulatedAmortization === "number"
    ? Math.max(capitalCost.amount - accumulatedAmortization - accumulatedImpairment.amount,0)
    : accumulatedAmortization;
}


export function assetIsPastUsefulLife (asset: Asset) {
  const {inServiceDate, usefulLife} = asset
  if (!inServiceDate || !usefulLife) return false
  return differenceInMonths(new Date(), dateFromMMDDYYYY(inServiceDate)) > usefulLife * 12
}

export function calcResidualValue (asset: Asset) {
  const {capitalCost, residualValue} = asset
  if (!capitalCost.amount || !residualValue) return 0 
  return capitalCost.amount * residualValue / 100
}


export interface CsvAsset
  extends Omit<
    Asset,
    "capitalCost" | "accumulatedImpairment"
  > {
  capitalCost: number;
  accumulatedAmortization: number;
  accumulatedImpairment: number;
}
