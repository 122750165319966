import { getQueryStringFromObject, handleFileResponse } from "store/api/util";
import { Asset } from "store/models/Asset";
import { AssetActionRecord } from "store/models/AssetActionRecord";
import { AssetStatus } from "store/models/AssetStatus";
import { Contract } from "store/models/Contract";
import { Photo } from "store/models/Photo";
import { apiUrl, starfleetApi } from "store/services/api";
import { getRequestHeaders, getResponseBody, isFileResponse } from "store/services/utils";
import { AssetQueryArgs, AssetWithFinancialData } from "store/slices/assetSlice";
import { setGlobalMessage, simpleGlobalMessage } from "store/slices/systemSlice";
import { oneMinute } from "utils/timeValues";

export interface AssetCardData {
  _id: string;
  sizeCode: string;
  serialNumber: string;
  assetNumber: string;
  status: AssetStatus
  category: string;
}


export const assetApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAllAssets: builder.query<AssetWithFinancialData[], AssetQueryArgs | void>({
      query: (query) => `asset/?${getQueryStringFromObject(query || {})}`,
      transformResponse: getResponseBody<AssetWithFinancialData[]>,
      providesTags: [{ type: "Assets", id: "LIST" }],
      keepUnusedDataFor: oneMinute * 5
    }),
    getAssetById: builder.query<Asset, string>({
      query: (id) => `/asset/specificAsset?assetId=${id}`,
      transformResponse: getResponseBody<Asset>,
      providesTags: (result, error, id) => [
        { type: "Assets", id: result?._id },
      ],
    }),
    getAssetAutoCompleteOptions: builder.query<AssetCardData[],{ limit: number; searchValue: string, limitResultsToContractId?: string }>({
      query: (params) =>
        `/asset/getAssetAutocompleteOptions?${getQueryStringFromObject(params)}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, id) => [{ type: "AssetAutoComplete", id: "AUTOCOMPLETE" }],
    }),
    getAverageRentalRateByAssetId: builder.query<number, string>({
      query: (assetId) => `asset/averageRentalRate?assetId=${assetId}`,
      transformResponse: getResponseBody<number>,
      providesTags: (result, error, assetId) => [
        { type: "Assets", id: `AVG_RENTAL_RATE - ${assetId}` },
      ],
    }),
    getAssetContractHistory: builder.query<
      { contracts: Contract[]; totalRecords: number },
      { assetId: string; limit: number }
    >({
      query: (query) =>
        `asset/assetContractHistory?${getQueryStringFromObject(query)}`,
      transformResponse: getResponseBody<{
        contracts: Contract[];
        totalRecords: number;
      }>,
      providesTags: (result, error, { assetId }) => [
        { type: "Assets", id: assetId },
      ],
    }),
    getAssetsByContractId: builder.query<Asset[], string>({
      query: (contractId) => `asset/contract?contractId=${contractId}`,
      transformResponse: getResponseBody<Asset[]>,
      providesTags: (result, error, contractId) => [
        { type: "Assets", id: contractId },
      ],
    }),
    createAsset: builder.mutation<Asset, Omit<Asset, "_id">>({
      query: (asset) => ({
        url: "asset/",
        method: "POST",
        body: asset,
      }),
      transformResponse: getResponseBody<Asset>,
      invalidatesTags: [{ type: "Assets", id: "LIST" }],
      extraOptions: { maxRetries: 0 },
    }),
    createManyAssets: builder.mutation<void, FormData>({
      queryFn: async (file, {getState, dispatch}) => {
        try {
          const headers = await getRequestHeaders(getState);
          headers.delete("Content-Type");
          const response = await fetch(`${apiUrl}/api/asset/createMany`, {
            headers,
            body: file,
            method: "POST",
          });
  

          if (!response.ok) {
            const result = await response.json();
            dispatch(setGlobalMessage({messageText: result.message, severity: "error", show: true}));
            return { error: undefined };
          }

          if (isFileResponse(response)) {
            handleFileResponse(response, "Errors");
            dispatch(simpleGlobalMessage("Error creating assets. See Errors.csv", "error"));
          } else {
            dispatch(simpleGlobalMessage("Successfully created assets", "success"));
          }
          return { data: undefined };
        } catch (error) {
          return { error: undefined };
        }
      },
      invalidatesTags: [{ type: "Assets", id: "LIST" }],
      extraOptions: { maxRetries: 0 },
    }),
    updateAsset: builder.mutation<Asset, Asset & { reason: string }>({
      query: ({ reason, ...asset }) => ({
        url: "asset/",
        method: "PUT",
        body: { asset, clientChangeDate: new Date(), reason },
      }),
      transformResponse: getResponseBody<Asset>,
      invalidatesTags: (result, error, asset) => [
        { type: "Assets", id: asset._id },
        { type: "Assets", id: "LIST" },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    updateAssetStatus: builder.mutation<
      Asset,
      { asset: { _id: Asset["_id"] } & Partial<Asset>; reason: string }
    >({
      query: ({ asset, reason }) => ({
        url: "asset/status",
        method: "PATCH",
        body: { asset, reason, clientChangeDate: new Date() },
      }),
      transformResponse: getResponseBody<Asset>,
      invalidatesTags: (result, error, { asset }) => [
        { type: "Assets", id: asset._id },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    getPhotosByAssetId: builder.query<Photo[], string>({
      query: (assetId) => `assetPhoto/byAssetId?assetId=${assetId}`,
      transformResponse: getResponseBody<Photo[]>,
      providesTags: (result, error, assetId) => [
        { type: "AssetPhotos", id: assetId },
      ],
    }),
    addAssetPhoto: builder.mutation<Photo, { assetId: string; file: FormData }>(
      {
        query: ({ assetId, file }) => ({
          url: `assetPhoto/?assetId=${assetId}`,
          method: "POST",
          body: file,
        }),
        transformResponse: getResponseBody<Photo>,
        invalidatesTags: (result, error, { assetId }) => [
          { type: "AssetPhotos", id: assetId },
        ],
        extraOptions: { maxRetries: 0 },
      }
    ),
    deleteAssetPhoto: builder.mutation<
      undefined,
      { assetId: string; photoId: string }
    >({
      query: ({ assetId, photoId }) => ({
        url: `assetPhoto`,
        method: "DELETE",
        body: { assetId, photoId },
      }),
      transformResponse: getResponseBody<undefined>,
      invalidatesTags: (result, error, { assetId }) => [
        { type: "AssetPhotos", id: assetId },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    getIdleAssets: builder.query<Asset[], void>({
      query: () => "asset/idleAssets",
      transformResponse: getResponseBody<Asset[]>,
      providesTags: [{ type: "Assets", id: "IDLE" }],
    }),
    getAssetActionRecords: builder.query<
      AssetActionRecord[],
      {
        createdDate?: { $gte: Date; $lte: Date };
        actionPerformedBy?: string;
        asset?: string;
        fieldChanged?: keyof Asset;
        limit?: number;
      }
    >({
      query: (query) =>
        `asset/actionRecords?${getQueryStringFromObject(query)}`,
      transformResponse: getResponseBody<AssetActionRecord[]>,
      providesTags: (result, error, assetId) => [
        { type: "Assets", id: `ACTION_RECORDS_${assetId}` },
      ],
    }),
  }),
});

export const {
  useGetAssetByIdQuery,
  useGetAssetContractHistoryQuery,
  useGetAssetsByContractIdQuery,
  useGetPhotosByAssetIdQuery,
  useAddAssetPhotoMutation,
  useDeleteAssetPhotoMutation,
  useGetAverageRentalRateByAssetIdQuery,
  useGetAllAssetsQuery,
  useCreateAssetMutation,
  useUpdateAssetMutation,
  useCreateManyAssetsMutation,
  useUpdateAssetStatusMutation,
  useGetAssetActionRecordsQuery,
  useLazyGetAssetActionRecordsQuery,
  useGetIdleAssetsQuery,
  useGetAssetAutoCompleteOptionsQuery
} = assetApi;
