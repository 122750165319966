import { Dispatch, FC, MutableRefObject, SetStateAction, useMemo, useState } from "react";
import { toDDMMMYYYY, getFullName, dateFromMMDDYYYY, DDMMMYYYY } from "utils/util";
import { setCurrentWorkOrderId } from "store/slices/maintenanceSlice";
import { setBladeMode } from "store/slices/systemSlice";
import { useAppDispatch } from "store/store";
import { richTextToString } from "utils/formatUtils";
import { WebWOQueryResponse } from "store/services/workOrder";
import { Card, Tooltip } from "@mui/material";
import StyledDataGrid from "components/data-grid/styled-data-grid";
import { GRID_ACTIONS_COLUMN_TYPE, GRID_CHECKBOX_SELECTION_FIELD, GridActionsCellItem, GridApiPro, GridColDef, GridRenderCellParams, GridRowId, useGridApiRef } from "@mui/x-data-grid-pro";
import PushPin from "@mui/icons-material/PushPin";
import { inOperator, notInOperator } from "components/data-grid/column-filters";
import { WorkOrder, WorkOrderStatus } from "store/models/WorkOrder";
import Label, { LabelColor } from "components/label";
import usePinnedRows from "hooks/usePinnedRows";

function statusToColor(status: WorkOrder["status"]) {
  switch (status) {
    case WorkOrderStatus.TO_DO:
      return "warning";
    case WorkOrderStatus.IN_PROGRESS:
      return "success";
    case WorkOrderStatus.CANCELLED:
      return "default";
    case WorkOrderStatus.COMPLETE:
      return "info";
    default:
      return "default";
  }
}

function priorityToColor(type: WorkOrder["priority"]): LabelColor {
  switch (type) {
    case "LOW":
      return "default";
    case "MEDIUM":
      return "default";
    case "HIGH":
      return "warning";
    case "URGENT":
      return "error";
    default:
      return "default";
  }
}

type ColumnProps = {
  pinnedRowsIds: {
    top: GridRowId[];
    bottom: GridRowId[];
  };
  setPinnedRowsIds: Dispatch<SetStateAction<{
    top: GridRowId[];
    bottom: GridRowId[];
  }>>
};

const useMaintenanceColumns = ({
  pinnedRowsIds,
  setPinnedRowsIds,
}: ColumnProps) => {
  const styledColumns: GridColDef<WebWOQueryResponse>[] = useMemo(
    () => [
      {
        headerName: "WO #",
        type: "number",
        align: "center",
        headerAlign: "center",
        field: "number",
        valueFormatter: (value) => String(value),
      },
      {
        headerName: "Title",
        field: "title",
        valueGetter: (_, row) =>
          row.inspection
            ? row.inspectionType
            : row.title || "",
        align: "left",
        headerAlign: "left",
        width: 200,
      },
      {
        headerName: "Description",
        field: "description",
        valueFormatter: (value) =>
          value ? richTextToString(value) : "",
        flex: 1,
        minWidth: 200,
      },
      {
        headerName: "PO #",
        field: "purchaseOrder",
        width: 100,
        valueGetter: (value: WorkOrder["purchaseOrder"]) => (value ? value.poNumber : ""),
      },
      {
        headerName: "Serial #",
        field: "serialNumber",
        width: 120,
        align: "center",
        headerAlign: "center",
        valueGetter: (_, row) =>
          row.asset ? row.asset.serialNumber : "",
        filterOperators: [inOperator('in-serial'), notInOperator('not-in-serial')],
      },
      {
        headerName: "Asset #",
        field: "assetNumber",
        width: 120,
        align: "center",
        headerAlign: "center",
        valueGetter: (_, row) =>
          row.asset ? row.asset.assetNumber : "",
        filterOperators: [inOperator('in-asset'), notInOperator('not-in-asset')],
      },
      {
        headerName: "Status",
        field: "status",
        align: "center",
        headerAlign: "center",
        width: 120,
        filterOperators: [
          inOperator("in-status"),
          notInOperator("not-in-status"),
        ],
        renderCell: (params: GridRenderCellParams<WebWOQueryResponse>) => (
          <Label variant="soft" color={statusToColor(params.value)}>
            {params.value}
          </Label>
        ),
      },
      {
        headerName: "Assigned To",
        field: "assignedTo",
        width: 150,
        align: "center",
        headerAlign: "center",
        valueGetter: (_, row) =>
          row.assignedTo
            ? getFullName(row.assignedTo)
            : "Unassigned",
      },
      {
        headerName: "Due Date",
        field: "dueDate",
        type: "date",
        align: "center",
        headerAlign: "center",
        valueGetter: (value: DDMMMYYYY) =>
          value ? dateFromMMDDYYYY(value) : null,
        valueFormatter: (value: Date) =>
          value ? toDDMMMYYYY(value) : null,
        width: 120,
      },
      {
        headerName: "Created By",
        field: "createdBy",
        type: "string",
        align: "center",
        headerAlign: "center",
        valueGetter: (value: WebWOQueryResponse["createdBy"]) =>
          value ? getFullName(value) : null,
        width: 120,
      },
      {
        headerName: "Completed By",
        field: "completedBy",
        type: "string",
        align: "center",
        headerAlign: "center",
        valueGetter: (value: WebWOQueryResponse["completedBy"]) =>
          value ? getFullName(value) : null,
        width: 120,
      },
      {
        headerName: "Completed Date",
        field: "completedDate",
        type: "date",
        align: "center",
        headerAlign: "center",
        valueGetter: (value: DDMMMYYYY) =>
          value ? dateFromMMDDYYYY(value) : null,
        valueFormatter: (value: Date) =>
          value ? toDDMMMYYYY(value) : null,
        width: 120,
      },
      {
        headerName: "Estimated Hours",
        field: "estimatedHours",
        width: 120,
        align: "center",
        headerAlign: "center",
      },
      {
        headerName: "Estimated Materials",
        field: "estimatedMaterials",
        width: 150,
        align: "center",
        headerAlign: "center",
        valueGetter: (value: WebWOQueryResponse["estimatedMaterials"]) =>
          value?.amount ? value.amount : null,
        renderCell: (params) =>
          params.value ? `$${params.row.estimatedMaterials?.amount} ${params.row.estimatedMaterials?.currency}` : null,
      },
      {
        headerName: "Actual Hours",
        field: "actualHoursToComplete",
        width: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        headerName: "Actual Materials",
        field: "actualMaterialsToComplete",
        width: 150,
        align: "center",
        headerAlign: "center",
        valueGetter: (value: WebWOQueryResponse["actualMaterialsToComplete"]) =>
          value?.amount ? value.amount : null,
        renderCell: (params) =>
          params.value ? `$${params.row.actualMaterialsToComplete?.amount} ${params.row.actualMaterialsToComplete?.currency}` : null,
      },
      {
        headerName: "Priority",
        field: "priority",
        width: 120,
        align: "center",
        headerAlign: "center",
        filterOperators: [
          inOperator("in-priority"),
          notInOperator("not-in-priority"),
        ],
        renderCell: (params: GridRenderCellParams<WebWOQueryResponse>) => (
          <Label variant="soft" color={priorityToColor(params.value)}>
            {params.value}
          </Label>
        ),
      },
      {
        headerName: "Category",
        field: "category",
        width: 150,
        align: "center",
        headerAlign: "center",
        filterOperators: [
          inOperator("in-category"),
          notInOperator("not-in-category"),
        ],
      },
      {
        headerName: "Subcategory",
        field: "subcategory",
        width: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        headerName: "Client",
        field: "contract.customerName",
        width: 150,
        align: "center",
        headerAlign: "center",
        valueGetter: (_, row) =>
          row.contract ? row.contract.customerName : "-",
      },
      {
        headerName: "Project Number",
        field: "contract.projectNumber",
        width: 150,
        align: "center",
        headerAlign: "center",
        valueGetter: (_, row) =>
          row.contract ? row.contract.projectNumber : "-",
      },
      {
        headerName: "Billable",
        field: "billableToClient",
        type: "singleSelect",
        valueOptions: ["Yes", "No"],
        width: 120,
        align: "center",
        headerAlign: "center",
        valueGetter: (value: boolean, row) =>
          row.category !== "INSPECTION"
            ? value
              ? "Yes"
              : "No"
            : "-",
      },
      {
        field: "actions",
        type: "actions",
        width: 40,
        getActions: (params) => {
          const isPinnedTop = pinnedRowsIds.top.includes(params.row._id);
          const handlePinClick = () => {
            if (isPinnedTop) {
              setPinnedRowsIds((prevPinnedRowsIds) => ({
                ...prevPinnedRowsIds,
                top: prevPinnedRowsIds.top.filter(
                  (rowId) => rowId !== params.row._id
                ),
              }));
            } else {
              setPinnedRowsIds((prevPinnedRowsIds) => ({
                ...prevPinnedRowsIds,
                top: [...prevPinnedRowsIds.top, params.row._id],
              }));
            }
          };
          if (isPinnedTop) {
            return [
              <GridActionsCellItem
                label="Unpin"
                icon={
                  <Tooltip title="Unpin">
                    <PushPin color="primary" />
                  </Tooltip>
                }
                onClick={handlePinClick}
              />,
            ];
          }
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Pin to top">
                  <PushPin />
                </Tooltip>
              }
              label="Pin to top"
              onClick={handlePinClick}
            />,
          ];
        },
      },
    ],
    [pinnedRowsIds]
  );
  return styledColumns;
};


interface Props {
  workOrders: WebWOQueryResponse[];
  tableActions?: (apiRef: MutableRefObject<GridApiPro>) => JSX.Element[];
  onRowClick?: (workOrder: WebWOQueryResponse) => void;
  tableId?: string;
  isLoading?: boolean;
}

const MaintenanceTable: FC<Props> = ({
  workOrders,
  tableActions,
  onRowClick,
  tableId = "maintenance-table",
  isLoading = false,
}) => {
  const dispatch = useAppDispatch();
  const tableData = workOrders.map((workOrder) => {
    return {
      ...workOrder,
      category: workOrder.inspection ? "INSPECTION" : workOrder.category,
    };
  });
  const { pinnedRowsIds, setPinnedRowsIds, getPinnedRowData } = usePinnedRows({
    tableId
  });
  const { rows, pinnedRows } = getPinnedRowData(tableData);
  
  const tableColumns = useMaintenanceColumns({ pinnedRowsIds, setPinnedRowsIds });

  const onWorkOrderRowClick = (value: WebWOQueryResponse) => {
    if (onRowClick) {
      onRowClick(value);
      return;
    }
    dispatch(setCurrentWorkOrderId(value._id));
    dispatch(setBladeMode("workOrderViewEdit"));
  };

  const apiRef = useGridApiRef();

  return (
    <Card sx={{ px: 2 }}>
      <StyledDataGrid
        rows={rows}
        apiRef={apiRef}
        disableRowSelectionOnClick
        pinnedRows={pinnedRows}
        checkboxSelection
        tableId={tableId}
        getRowId={(row: WebWOQueryResponse) => row._id}
        onRowClick={(params) => onWorkOrderRowClick(params.row)}
        columns={tableColumns}
        additionalActions={tableActions ? tableActions(apiRef) : undefined}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_FIELD, GRID_ACTIONS_COLUMN_TYPE],
          },
        }}
        disableFontSizeSelector
        persistColumnOrder
        persistPinnedColumns
        persistFilter
        persistSort
        pageSizeOptions={[10, 15, 25, 50, 100, 250, 500]}
        sx={{ maxHeight: "60vh" }}
        loading={isLoading}
        csvOptions={{fileName: `Starfleet Work Orders`}}
      />
    </Card>
  );
};

export default MaintenanceTable;
