import React, { FC } from "react";
import { TextField, MenuItem, TextFieldProps } from "@mui/material";
import { FormMenuStyles } from ".";
import _ from "lodash";

export type Primitive = string | number;

const PrimitiveSelect: FC<TextFieldProps & { options?: Primitive[], "data-testid"?: string, allowEmpty?: boolean, enableClear?: boolean, clearText?: string; disableAlphabetize?: boolean}> = ({
  value = "",
  options = [],
  enableClear = false,
  clearText = "None",
  disableAlphabetize = false,
  ...rest
}) => {

  return (
    <TextField
      value={value}
      {...rest}
      select
      SelectProps={{
        MenuProps: FormMenuStyles,
        disableUnderline: true,
        
      }}
    >
    {enableClear && (
      <MenuItem key="none" data-testid={`${rest.name && `${rest.name}-`}none`} value="">
        <em>{clearText}</em>
      </MenuItem>
    )}
    {(!disableAlphabetize ? _.clone(options).sort() : _.clone(options)).map((option) => (
      <MenuItem key={option} data-testid={`${rest.name && `${rest.name}-`}${option}`} value={option}>
        {option}
      </MenuItem>
    ))}
    </TextField>
  )
}

export default PrimitiveSelect;