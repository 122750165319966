import React, { FC } from "react";
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material";
import { useSelector } from "react-redux";
import UserData from "store/models/UserData";
import { getUserFullName } from "utils/util";
import { useGetUsersQuery } from "store/services/user";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (user: UserData) => getUserFullName(user) || "",
});

type Props = Omit<
  AutocompleteProps<UserData, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  label?: string;
  enableClearOnBlur?: boolean
};

const UserAutocomplete: FC<Props> = ({ value, name, error, label, enableClearOnBlur = false, ...rest }) => {
  const {data: options = [], isLoading} = useGetUsersQuery();

  return (
    <Autocomplete
      {...rest}
      key={String(value) || "user-autocomplete"}
      value={value || null}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      getOptionLabel={(user) => typeof user === "string" ? user : getUserFullName(user)}
      filterOptions={filterOptions}
      clearOnBlur={enableClearOnBlur}
      loading={isLoading}
      options={options}
      renderInput={(params) => (
        <TextField
          name={name}
          label={label}
          {...params}
          error={error}
          margin="none"
          autoComplete="off"
          inputProps={{ ...params.inputProps, autocomplete: "new-password", form: { autocomplete: "off" } }}
        />
      )}
    />
  );
};

export default UserAutocomplete;
