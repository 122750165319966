import React, { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { contractLength, dateFromMMDDYYYY, displayAddress, getFullName, toDDMMMYYYY } from "utils/util";
import AttachmentList from "components/AttachmentList";
import { useSelector } from "react-redux";
import usePermissions, {
  FeatureType,
  ActionType,
} from "hooks/usePermissions";
import { Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { fCurrency } from "utils/formatNumber";
import CopyClipboard from "components/CopyClipboard";
import { awaitingContractStatuses, canceleedContractStatuses } from "store/models/Contract";
import { useGetClientContactByIdQuery } from "store/services/clientContact";
import { useGetContractByIdQuery } from "store/services/contract";
import { selectCurrentContractId } from "store/slices/contractSlice";
import { useGetClientByIdQuery } from "store/services/client";
import useLoggedInUser from "hooks/useLoggedInUser";


const ProminentBox: FC<{children?: ReactNode}> = ({ children }) => {
  return (
    <Box sx={{ py: 1.5 }}>
      {children}
    </Box>
  );
};

const ContractRow: FC<{children?: ReactNode}> = ({ children }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ py: 0.5 }}
    >
      {children}
    </Box>
  );
};

const ContractField = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "invalid",
})<{ invalid?: boolean }>(({ theme, invalid = false }) => ({
  ...(theme.typography.subtitle2 as any),
  color: invalid ? theme.palette.error.main : theme.palette.text.secondary,
  whiteSpace: "nowrap",
  marginRight: 24,
}));

interface Props {
  invalidContractProperties: string[];
}
const ContractView: React.FC<Props> = ({ invalidContractProperties }) => {
  
  const currentContractId = useSelector(selectCurrentContractId);
  const {data: currentContract, isLoading: contractLoading} = useGetContractByIdQuery(currentContractId ||'', {skip: !currentContractId});
  const {data: currentContractClient} = useGetClientByIdQuery(currentContract?.client || "", {skip: !currentContract?.client});
  const {data: clientContact} = useGetClientContactByIdQuery(currentContract?.clientContact || "", {skip: !currentContract?.clientContact});
  const {data: siteContact} = useGetClientContactByIdQuery(currentContract?.siteContact || "", {skip: !currentContract?.siteContact});
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const { loggedInUser } = useLoggedInUser()
  const contractIsCancelled = currentContract ? canceleedContractStatuses.includes(currentContract?.status) : true;

  function userCanEditContract(){
    const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
    const userCanEditOwnContract = userTryingToEditTheirOwnContract() &&
      checkContractPermissions(ActionType.LIMITED_UPDATE);

    return userCanEditOwnContract || userCanEditContracts
  }
  function userTryingToEditTheirOwnContract() {
    return loggedInUser?._id === currentContract?.createdBy;
  }

  function displaySecurityDeposit() {
    return currentContract?.securityDeposit.received
      ? `${fCurrency(currentContract?.securityDeposit.amount.amount)} ${
          currentContract?.securityDeposit.amount.currency
        }`
      : "Not Received";
  }

  return (
    <>
      <Card
        sx={(theme) => ({
          p: 2.5,
          mb: 2,
          fontSize: theme.typography.subtitle2,
        })}
      >
        <Box display="flex" flexDirection="column" gap="3">
          <ContractRow>
            <ContractField
              gutterBottom
              invalid={invalidContractProperties.includes("projectNumber")}
            >
              Project #
            </ContractField>
            <Box>
              {currentContract?.projectNumber && (
                <CopyClipboard
                  value={currentContract?.projectNumber}
                  tooltipText="Copy Project #"
                  successText={`Copied Project #: ${currentContract?.projectNumber}`}
                />
              )}
              {currentContract?.projectNumber}{" "}
            </Box>
          </ContractRow>
          <ContractRow>
            <ContractField gutterBottom>Contract Type</ContractField>
            {currentContract?.contractType}
          </ContractRow>
          {currentContract?.status &&
            awaitingContractStatuses.includes(currentContract.status) && (
              <ContractRow>
                <ContractField gutterBottom>Reservation Expiry</ContractField>
                {toDDMMMYYYY(dateFromMMDDYYYY(currentContract?.reservationExpiry))}
              </ContractRow>
            )}
          <ContractRow>
            <ContractField gutterBottom>
              {currentContract?.contractType !== "Sale"
                ? "Start Date"
                : "Sale Date"}
            </ContractField>
            {currentContract?.startDate
              ? toDDMMMYYYY(dateFromMMDDYYYY(currentContract?.startDate))
              : "..."}
          </ContractRow>
          <ContractRow>
            <ContractField gutterBottom>Out Date</ContractField>
            {currentContract?.outDate
              ? toDDMMMYYYY(dateFromMMDDYYYY(currentContract?.outDate))
              : "..."}
          </ContractRow>
          {currentContract?.contractType !== "Sale" && (
            <ContractRow>
              <ContractField gutterBottom>End Date</ContractField>
              {currentContract?.endDate
                ? toDDMMMYYYY(dateFromMMDDYYYY(currentContract?.endDate))
                : currentContract?.evergreen
                ? "Evergreen"
                : "..."}
            </ContractRow>
          )}
          {currentContract?.contractType !== "Sale" && (
            <ContractRow>
              <ContractField gutterBottom>Length</ContractField>
              {contractLength(
                currentContract?.startDate,
                currentContract?.endDate
              )}
            </ContractRow>
          )}
          <ContractRow>
            <ContractField
              gutterBottom
              invalid={invalidContractProperties.includes("contractSigned")}
            >
              Contract Signed{" "}
            </ContractField>
            {currentContract?.contractSigned ? "Yes" : "No"}
          </ContractRow>
          <ContractRow>
            <ContractField gutterBottom>Client</ContractField>
            {currentContractClient?.companyName}
          </ContractRow>
          <Box>
            <ContractField gutterBottom>Main Contact</ContractField>
            {clientContact && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                <Box> {getFullName(clientContact)}</Box>

                <Box> {clientContact.primaryPhone}</Box>
                <Box> {clientContact.email}</Box>
              </Box>
            )}
          </Box>
          <Box sx={{my: 2}}>
            <ContractField gutterBottom>Site Contact</ContractField>
            {siteContact && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                <Box> {getFullName(siteContact)}</Box>

                <Box> {siteContact.primaryPhone}</Box>
                <Box> {siteContact.email}</Box>
              </Box>
            )}
          </Box>
          <ContractRow>
            <ContractField gutterBottom>Ship to</ContractField>
            {displayAddress(currentContract?.shipTo)}
          </ContractRow>
          <ContractRow>
            <ContractField gutterBottom>Bill to</ContractField>
            {displayAddress(currentContract?.billTo)}
          </ContractRow>
          {currentContract?.contractType !== "Sale" && (
            <>
              <ContractRow>
                <ContractField gutterBottom>Security Deposit</ContractField>
                {displaySecurityDeposit()}
              </ContractRow>
              <ContractRow>
                <ContractField gutterBottom>PPSA #</ContractField>
                {currentContract?.ppsaRegistration}
              </ContractRow>
            </>
          )}
          <ContractRow>
            <ContractField gutterBottom>Client PO Number</ContractField>
            {currentContract?.clientPoNumber}
          </ContractRow>
          <ContractRow>
            <ContractField gutterBottom>Quote Number</ContractField>
            {currentContract?.quoteNumber}
          </ContractRow>
          <ProminentBox>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Delivery Instructions
            </Typography>
            {currentContract?.deliveryInstructions || ""}
          </ProminentBox>
          <ProminentBox>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Additional Comments
            </Typography>
            {currentContract?.additionalComments || ""}
          </ProminentBox>
        </Box>
      </Card>
      {currentContract?._id && (
        <AttachmentList
          documentId={currentContract?._id}
          disabled={!userCanEditContract() || contractIsCancelled}
          documentType="contract"
        />
      )}
    </>
  );
};
export default ContractView;
