import { Client } from 'store/models/Client';
import { createMongoId } from 'utils/createMongoId';



export const initClient = (client: Partial<Client> & {country: Client["country"]}): Client => {
  return ({
    _id: createMongoId(),
    legalName:"",
    companyName:"",
    phoneNumber:"",
    fax:"",
    email:"",
    accountOwner: null,
    accountId: "",
    contacts: [],
    website:"",
    billTos: [],
    shipTos: [],
    accountsPayableContact: "",
    ...client
  })
}
