import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { Config } from "store/slices/configSlice";
import { InspectionConfig } from "store/slices/maintenanceSlice";
import { oneMinute } from "utils/timeValues";

export const configApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getConfigs: builder.query<Config[], void>({
      query: (query) => `config`,
      transformResponse: getResponseBody,
      providesTags: [{ type: "Config", id: `LIST` }],
      keepUnusedDataFor: oneMinute * 60 * 8,
    }),
    updateConfig: builder.mutation<Config, Config>({
      query: (body) => ({
        url: `config`,
        method: "PATCH",
        body,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: [{ type: "Config", id: `LIST` }],
      extraOptions: {maxRetries: 0},
    }),
    getInspectionOptions: builder.query<InspectionConfig[], void>({
        query: (query) => `config/inspectionOptions`,
        transformResponse: getResponseBody,
        providesTags: [{ type: "InspectionOptions", id: `LIST` }],
        keepUnusedDataFor: oneMinute * 60 * 8,
        }),

  }),
});

export const { useGetConfigsQuery, useGetInspectionOptionsQuery, useUpdateConfigMutation } = configApi;
