
import { useGetConfigsQuery } from 'store/services/config';
import { Config, ConfigStore, DefaultNotificationSetting } from 'store/slices/configSlice';

const useConfigs = (skip?: boolean): ConfigStore => {

    const {data: configs = [], isLoading} = useGetConfigsQuery(undefined, {skip: skip || false})

    const configsHash = configs.reduce((acc, config) => {
        acc[config.name] = config
        return acc
    }, {} as  Record<string, Config<any>>)

    const permissions = configsHash.permissions
    const maintenanceCategories = configsHash.maintenanceCategories
    const additionalCheckListItems = configsHash.additionalCheckListItems
    const categories = configsHash.categories
    const sizeCodes = configsHash.sizeCodes
    const airConditionTypes = configsHash.airConditionTypes
    const conditions = configsHash.conditions
    const branding = configsHash.branding
    const buildingCodes = configsHash.buildingCodes
    const cladding = configsHash.cladding
    const electrical = configsHash.electrical
    const floorStyles = configsHash.floorStyles
    const fuelTypes = configsHash.fuelTypes
    const statuses = configsHash.statuses
    const structuralLimits = configsHash.structuralLimits
    const subCategories = configsHash.subCategories
    const washRoomCounts = configsHash.washRoomCounts
    const partitions = configsHash.partitions
    const countries = configsHash.countries
    const unitsOfMeasure = configsHash.unitsOfMeasure
    const accessories = configsHash.accessories
    const currencies = Object.values(countries?.settings || {}).map((country: any) => country.currency)
    const defaultNotificationSettings = configsHash.defaultNotificationSettings as Config<Record<string, DefaultNotificationSetting>>

    const userRoles = Object.keys(permissions?.settings || {})

        return ({
            permissions,
            maintenanceCategories,
            additionalCheckListItems,
            categories,
            sizeCodes,
            airConditionTypes,
            conditions,
            branding,
            buildingCodes,
            cladding,
            electrical,
            floorStyles,
            fuelTypes,
            statuses,
            structuralLimits,
            subCategories,
            washRoomCounts,
            partitions,
            countries,
            unitsOfMeasure,
            accessories,
            currencies,
            userRoles,
            defaultNotificationSettings,
            configsLoading: isLoading
        });
}

export default useConfigs
