import { combineReducers } from 'redux'
import clientReducer from 'store/slices/clientSlice';
import assetReducer from 'store/slices/assetSlice';
import contractReducer from 'store/slices/contractSlice';
import systemReducer from 'store/slices/systemSlice';
import userReducer from 'store/slices/userSlice';
import maintenanceReducer from 'store/slices/maintenanceSlice'
import movementReducer from "store/slices/movementSlice"
import transportCompanyReducer from "store/slices/transportCompanySlice"
import { starfleetApi } from 'store/services/api';


const rootReducer = combineReducers({
    assets: assetReducer,
    contracts: contractReducer,
    clients: clientReducer,
    system: systemReducer,
    users: userReducer,
    maintenance: maintenanceReducer,
    movements: movementReducer,
    transportCompanies: transportCompanyReducer,
    [starfleetApi.reducerPath]: starfleetApi.reducer
});

export default rootReducer