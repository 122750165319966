import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { getAuth } from "firebase/auth";
import store, { STARFLEET_STORE } from "store/store";
import appVersion from "utils/version";

export const apiUrl = process.env.REACT_APP_API_URL

function isSendFileEndpoint(endpoint: string) {
  const fileEndpoints = ["addWorkOrderPhoto","addInspectionCheckListItemPhoto", "addAssetPhoto", "createManyAssets", "createAttachment"];
  return fileEndpoints.some(e => endpoint.toLowerCase().includes(e.toLowerCase()));
}

const baseQuery = fetchBaseQuery({
    baseUrl: `${apiUrl}/api/`,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      const userId = store.getState().system.loggedInUserId
      const token = await getAuth().currentUser?.getIdToken();
      const browserTabId = (getState() as STARFLEET_STORE).system.browserTabId;
      isSendFileEndpoint(endpoint) ? headers.delete("Content-Type") :  headers.set("Content-Type", "application/json");
      headers.set("app-version", String(appVersion));
      headers.set("browserTabId", browserTabId);
      userId && headers.set("userid", userId);
      token && headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  })

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

export const starfleetApi = createApi({
  baseQuery: baseQueryWithRetry,
  tagTypes: [
    "ActionsRequired",
    "AssetAutoComplete",
    "AssetPhotos",
    "Assets",
    "AssetWorkOrders",
    "Attachments",
    "BillOfLading",
    "Branches",
    "Chat",
    "CheckListItemPhotos",
    "ClientContact",
    "ClientContracts",
    "Clients",
    "Config",
    "ContractHistory",
    "Contracts",
    "ContractWorkOrders",
    "DataGridFilterFavorites",
    "Inspection",
    "InspectionOptions",
    "InspectionPhotos",
    "InspectionWorkOrders",
    "MaintenanceTV",
    "MasterUnitList",
    "Movements",
    "MovementsTV",
    "OpenHoursByAssetId",
    "TransportCompanies",
    "UserPreference",
    "Users",
    "WorkOrder",
    "WorkOrderPhoto",
    "WorkOrderPhotos",
    "WorkOrders",
    "Yards",
  ],
  reducerPath: "starfleetApi",
  endpoints: () => ({}),
});