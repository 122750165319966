import React, { SyntheticEvent, useRef } from "react";
import { Attachment } from "store/models/Attachment";
import { getDateFromObjectId, proxyUrl, toLocalDate } from "utils/util";
import { simpleGlobalMessage } from "store/slices/systemSlice";
import { useAppDispatch } from "store/store";
import { format } from "date-fns";
import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Download from "@mui/icons-material/Download";
import AttachFile from "@mui/icons-material/AttachFile";
import {
  useCreateAttachmentMutation,
  useDeleteAttachmentMutation,
  useGetAttachmentsQuery,
  useLazyDownloadAttachmentQuery,
} from "store/services/attachments";

interface Props {
  documentId: string;
  documentType: "contract" | "asset" | "workOrder";
  disabled: boolean;
}

const AttachmentList: React.FC<Props> = ({
  documentId,
  disabled,
  documentType,
}) => {
  const dispatch = useAppDispatch();

  const [createAttachment, { isLoading: isAddingAttachment }] =
    useCreateAttachmentMutation();

  const [deleteAttachment, { isLoading: isDeletingAttachment }] =
    useDeleteAttachmentMutation();

  const [downloadAttachment] = useLazyDownloadAttachmentQuery();

  const { data: contractAttachments = [], isLoading } = useGetAttachmentsQuery({
    documentId,
    documentType,
  });

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileInputClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleAttachmentDownload = async (
    e: SyntheticEvent,
    attachment: Attachment
  ) => {
    e.preventDefault();

    const downloadFileByUrl = async (targetUrl: string, fileName: string) => {
      const res = await fetch(proxyUrl + targetUrl);
      const blob = await res.blob();

      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.setAttribute("download", fileName);
      a.click();
    };

    downloadAttachment({ documentId, documentType, attachmentId: attachment._id })
      .unwrap()
      .then((url) => {
        downloadFileByUrl(url, attachment.fileName);
      });
  };

  const handleAttachmentUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      dispatch(
        simpleGlobalMessage("You do not have permission to perform this action")
      );
      return;
    }

    const file = e.target.files!.length > 0 ? e.target.files![0] : null;

    if (!file) return;

    const maxFileSize = 10 * 1024 * 1024; // 10 MB
    if (file.size > maxFileSize) {
      dispatch(
        simpleGlobalMessage(
          `The file was larger than ${maxFileSize / (1024 * 1024)} MB maximum!`
        )
      );
      return;
    }

    const form = new FormData();
    form.append("file", file);

    createAttachment({
      documentId,
      documentType,
      form,
    })
      .unwrap()
      .then(() => {
        dispatch(
          simpleGlobalMessage("Attachment uploaded successfully", "success")
        );
      })
      .catch(() => {
        dispatch(simpleGlobalMessage("Attachment upload failed", "error"));
      });
  };

  const handleAttachmentDelete = (
    e: SyntheticEvent,
    attachment: Attachment
  ) => {
    e.preventDefault();

    if (disabled) {
      dispatch(
        simpleGlobalMessage("You do not have permission to perform this action")
      );
      return;
    }

    const { _id: attachmentId, fileName } = attachment;

    window.confirm(`Are you sure you wish to delete '${fileName}' file?`) &&
      deleteAttachment({
        documentId,
        documentType,
        attachmentId,
      })
        .unwrap()
        .then(() => {
          dispatch(
            simpleGlobalMessage("Attachment deleted successfully", "success")
          );
        })
        .catch(() => {
          dispatch(simpleGlobalMessage("Attachment delete failed", "error"));
        });
  };

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
  }

  const showLoadingSpinner = isAddingAttachment || isLoading;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Button
        fullWidth
        variant="outlined"
        startIcon={<AttachFile />}
        onClick={handleFileInputClick}
        sx={{ mb: 3, mt: 1 }}
        disabled={disabled || isLoading || isDeletingAttachment}
      >
        Attach file
        <input
          style={{ display: "none" }}
          id="attachment-file-input"
          type="file"
          ref={hiddenFileInput}
          name="attachmentUpload"
          onChange={handleAttachmentUpload}
        />
      </Button>
      {contractAttachments.map((attachment) => (
        <Card
          key={attachment._id}
          sx={{
            display: "grid",
            rowGap: 2,
            columnGap: 2,
            gridTemplateColumns: "auto 1fr 1fr",
            alignItems: "center",
            p: 2.5,
            mb: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              maxWidth: "100%",
              textOverFlow: "ellipsis",
              overflowX: "hidden",
            }}
          >
            {attachment.fileName}
          </Typography>
          <Box onClick={(e) => handleAttachmentDownload(e, attachment)}>
            <Button startIcon={<Download />}>Download</Button>
          </Box>
          <Box onClick={(e) => handleAttachmentDelete(e, attachment)}>
            <Button startIcon={<DeleteForever />}>Remove</Button>
          </Box>
          <Typography variant="subtitle2">
            Uploaded:{" "}
            {format(
              toLocalDate(new Date(getDateFromObjectId(attachment._id))),
              "MM-dd-yyyy"
            )}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              pl: 1,
            }}
          >
            Size: {formatBytes(attachment.fileSize)}
          </Typography>
        </Card>
      ))}
      {showLoadingSpinner && <CircularProgress size={24} />}
    </Box>
  );
};

export default AttachmentList;
