const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ASSETS = '/assets';
const ROOTS_MUL = '/mul';
const ROOTS_CONTRACTS = '/contracts';
const ROOTS_MOVEMENTS = '/movements';
const ROOTS_CLIENTS = '/clients';
const ROOTS_MAINTENANCE = '/maintenance';
const ROOTS_USERS = '/listUsers';
const ROOTS_SYSTEM = '/logout';
const ROOTS_SETTINGS = '/settings';

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/app'),
  fleet: path(ROOTS_DASHBOARD, '/fleet'),
  finance: path(ROOTS_DASHBOARD, '/finance'),
  geo: path(ROOTS_DASHBOARD, '/geo'),
  movements: path(ROOTS_DASHBOARD, '/movements'),
  analytics: path(ROOTS_DASHBOARD, '/analytics'),
  banking: path(ROOTS_DASHBOARD, '/banking'),
  booking: path(ROOTS_DASHBOARD, '/booking'),
};

export const PATH_ASSETS = {
  root: ROOTS_ASSETS
};

export const PATH_MUL = {
  root: ROOTS_MUL
};


export const PATH_CONTRACTS = {
  root: ROOTS_CONTRACTS
};

export const PATH_MOVEMENTS = {
  root: ROOTS_MOVEMENTS,
  movements:  path(ROOTS_MOVEMENTS, '/list'),
  transportCompanies:  path(ROOTS_MOVEMENTS, '/transportCompanies'),
};

export const PATH_CLIENTS = {
  root: ROOTS_CLIENTS,
  clients:  path(ROOTS_CLIENTS, '/clients'),
  contacts:  path(ROOTS_CLIENTS, '/contacts'),
};

export const PATH_MAINTENANCE = {
  root: ROOTS_MAINTENANCE
};

export const PATH_USERS = {
  root: ROOTS_USERS
};

export const PATH_SYSTEM = {
  root: ROOTS_SYSTEM
}

export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
  profile: path(ROOTS_SETTINGS, "/profile"),
  permisssions: path(ROOTS_SETTINGS,  "/permissions"),
  branch: path(ROOTS_SETTINGS, "/branch"),
  sizeCodes: path(ROOTS_SETTINGS, "/sizeCodes")
}

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}