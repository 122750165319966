import React, { FC } from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { Yard } from "store/models/Yard";
import _ from "lodash";
import { useGetYardsQuery } from "store/services/yard";
import { byIds } from "store/sliceUtils";
import useLoggedInUser from "hooks/useLoggedInUser";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (yard: Yard) => yard.name,
});

type Props = Omit<
  AutocompleteProps<Yard, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  label?: string;
};

const YardAutocomplete: FC<Props> = ({ value, name, error, label, ...rest }) => {
  const { data: yards = [] } = useGetYardsQuery();
  const yardsHash = byIds(yards);
  const { loggedInUser } = useLoggedInUser()
  const userYardsOnly = _.sortBy(yards.filter((yard) =>
    loggedInUser?.countries.includes(yard.country)
  ), "name");

  return (
    <Autocomplete
      {...rest}
      value={value || null}
      key={value ? "yard" : "no-yard"}
      getOptionLabel={(yard) => typeof yard === "string" ? yard : yardsHash[yard._id]?.name}
      filterOptions={filterOptions}
      options={userYardsOnly}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          error={error}
          margin="none"
          label={label}
          inputProps={{ ...params.inputProps, autocomplete: "new-password", form: { autocomplete: "off" } }}
        />
      )}
    />
  );
}

export default YardAutocomplete;