import React from "react";
import { Box, Typography } from "@mui/material";
import { format, isBefore } from "date-fns";
import { IPopulatedMessage } from "store/models/Chat";
import useLoggedInUser from "hooks/useLoggedInUser";


interface Props {
  message: IPopulatedMessage
}

const Message = ({ message }: Props) => {
  const date = new Date(message.date);
  const { loggedInUser } = useLoggedInUser()
  const messagePosition = fromLoggedInUser() ? "right" : "left";
  const messageLocationStyle = messagePosition === "right"
      ? { marginLeft: "20%" }
      : { marginRight: "20%" };

  function formattedDate() {
    if (messageIsOld()) {
      return format(date, "MMM do, yyyy h:mmaaaaa'm'")
    } else {
      return format(date, "h:mmaaaaa'm'")
    }
  }

  function messageIsOld() {
    const today = new Date().toDateString()
    return isBefore(date, new Date(today))
  }

  function fromLoggedInUser() {
    return loggedInUser && message.user === loggedInUser._id
  }

  return (
    <Box
      key={message._id}
      sx={{
        ...messageLocationStyle,
        borderRadius: 10,
      }}
    >
      <Box
        sx={(theme) => ({
          marginRight: "auto",
          background:
            messagePosition === "right"
              ? theme.palette.action.hover
              : `${theme.palette.primary.main}40`,
          color: theme.palette.text.primary,
          borderRadius: "5px",
          padding: theme.spacing(2),
          margin: theme.spacing(2),
        })}
      >
        <Typography variant="body1" fontWeight="bold">
          {message.userName} - {formattedDate()}
        </Typography>
        <Typography
          variant="body2"
          sx={{ overflowWrap: "break-word", whiteSpace: "pre-line" }}
        >
          {message.message}
        </Typography>
      </Box>
    </Box>
  );
}

export default Message;