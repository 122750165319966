import React, { useEffect, useState } from "react";
import AssetView from "./view/AssetView";
import { Asset } from "store/models/Asset";
import { useSelector } from 'react-redux';
import { selectBladeMode, setBladeMode, simpleGlobalMessage } from 'store/slices/systemSlice';
import { clearCurrentAssetId, selectCurrentAssetId, setSelectedAssetIds } from 'store/slices/assetSlice';
import usePermissions, { ActionType, FeatureType, UIActionType } from "hooks/usePermissions";
import { CircularProgress, IconButton, SvgIcon, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import { ReactComponent as PdfIcon } from "svg/pdf.svg";
import MaintenanceIcon from '@mui/icons-material/Handyman';
import CancelIcon from '@mui/icons-material/Cancel';
import Blade from "components/Blade";
import { useLocation } from "react-router";
import _ from "lodash";
import AssetForm from "features/assets/AssetForm";
import { Form, Formik, FormikHelpers } from "formik";
import { assetValidationSchema } from "utils/formikAPI";
import { ReactComponent as RentIcon } from "svg/real-estate-sign-house-rent.svg";
import { QRCodeCanvas } from 'qrcode.react';
import { setNewReservation } from "store/slices/contractSlice";
import UserData from "store/models/UserData";
import { initAsset } from "store/api/asset";
import { useAppDispatch } from 'store/store';
import AsyncButton from "components/upload/AsyncButton";
import { setCreatedClientId } from "store/slices/clientSlice";
import { initContract } from "store/services/contract";
import useCompressImages from "hooks/useCompressImages";
import { useGetAssetByIdQuery, useGetPhotosByAssetIdQuery, useUpdateAssetMutation } from "store/services/asset";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AssetSummaryPDF from "features/assets/AssetSummaryPDF";
import { useGetWorkOrdersByAssetIdQuery } from "store/services/workOrder";
import { useGetBranchesQuery } from "store/services/branches";
import { byIds } from "store/sliceUtils";
import { useGetYardsQuery } from "store/services/yard";
import useLoggedInUser from "hooks/useLoggedInUser";

interface Props {}

const AssetViewEditBlade: React.FC<Props> = () => {
  const currentAssetId = useSelector(selectCurrentAssetId);

  const [isEditing, setEditing] = useState(false);
  const bladeMode = useSelector(selectBladeMode);
  const { loggedInUser, userDefaultCurrency } = useLoggedInUser()
  const { data: branches = [] } = useGetBranchesQuery();
  const branchesHash = byIds(branches);
  const { data: yards = [] } = useGetYardsQuery();
  const yardsHash = byIds(yards);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const checkAssetPermissions = usePermissions(FeatureType.ASSET);
  const userCanEditAssets = checkAssetPermissions(ActionType.UPDATE);
  const checkMaintenancePermissions = usePermissions(FeatureType.MAINTENANCE);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanCreateContract = checkContractPermissions(ActionType.CREATE);

  const {data: currentAsset} = useGetAssetByIdQuery(currentAssetId || "", { skip: !currentAssetId });
  const {data: workOrders = []} = useGetWorkOrdersByAssetIdQuery({assetId: currentAssetId || '' }, {
    skip: !currentAssetId,
  });

  const {data: assetPhotos = [], isLoading: assetPhotosLoading} = useGetPhotosByAssetIdQuery(currentAssetId || "", {
    skip: !currentAssetId,
  });

  const [updateAsset, {isLoading: isUpdatingAsset}] = useUpdateAssetMutation();

  const initialValues = initAsset({...currentAsset, currency: userDefaultCurrency});

  const isInAssetModule = location.pathname.includes("assets")
  const disableReservationCreate = !userCanCreateContract || !isInAssetModule;

  const { compressedImages, isCompressing } = useCompressImages({
    images: assetPhotos,
    imagesLoading: assetPhotosLoading,
    dataId: currentAssetId || "",
  });

 
  const handleSubmit = (asset: Asset, { setSubmitting, resetForm}: FormikHelpers<Asset>) => {
    updateAsset({...asset, reason: `Asset Manually Updated by ${loggedInUser?.email}`}).unwrap().then((data) => {
      setEditing(false);
      resetForm();
    }).catch((error) => {
      setSubmitting(false);
      dispatch(simpleGlobalMessage(error.data?.message || error.message || "An unknown error occurred"));
    }
    );
  };

  /**
   * Users can otherwise be in edit mode on one asset and jump directly to edit mode on another
   */
  const preventEditHopping = () => setEditing(false);
  const closeBlade = () => dispatch(clearCurrentAssetId());

  useEffect(preventEditHopping, [currentAssetId]);

  const editMode = () => {
    const userCanEditAssets = checkAssetPermissions(ActionType.UPDATE, UIActionType.ONCLICK);
    if(!userCanEditAssets) return;
    setEditing(true);
  }

  const createWorkOrder = () => {
    const userCanCreateWorkOrders = checkMaintenancePermissions(ActionType.CREATE, UIActionType.ONCLICK);
    if (!userCanCreateWorkOrders) return;
    dispatch(setBladeMode("workOrderCreate"));
  }


  async function handleProductionComplete() {
    if (!currentAsset) return;
    updateAsset({
      ...currentAsset,
      inProduction: false,
      reason: `Asset set IN PRODUCTION to false by ${loggedInUser?.email}`,
    })
      .unwrap()
      .then(() => {
        dispatch(simpleGlobalMessage("Asset marked as production complete"));
      })
      .catch((error) => {
        dispatch(
          simpleGlobalMessage(
            error.data?.message || error.message || "An unknown error occurred"
          )
        );
      });
  }

  const createReservation = () => {
    const userCanEditAssets = checkAssetPermissions(ActionType.UPDATE, UIActionType.ONCLICK);
    if (!currentAsset || !userCanEditAssets) return;

    if (currentAsset.status === 'AVAILABLE') {
      dispatch(setCreatedClientId(undefined))
      dispatch(setSelectedAssetIds([currentAsset._id]));
      dispatch(setNewReservation(initContract({ createdBy: loggedInUser?._id }, userDefaultCurrency)))
      dispatch(setBladeMode("assetReserve"));
    } else {
      dispatch(simpleGlobalMessage('Asset is not available for reservation'));
    }
  }

  const downloadQRCode = () => {
    const qrCodeCanvas = document.querySelector("canvas");
    if(!qrCodeCanvas) return;
    const a = document.createElement("a");
    a.href = qrCodeCanvas.toDataURL();
    a.setAttribute("download", "download.png");
    a.click();
  }
  if (!currentAsset) return null;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={assetValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, resetForm, submitForm }) => {
        
        const viewMode = () => {
          setEditing(false);
          resetForm()
        }
        
        const bladeActions = () =>
          isEditing
            ? [
                <Tooltip title="Save" key="save">
                  <IconButton
                    disabled={_.isEqual(values, currentAsset)}
                    color="primary"
                    onClick={submitForm}
                    key="submitUpdate"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      <SaveIcon />
                    )}
                  </IconButton>
                </Tooltip>,
                <IconButton color="primary" onClick={viewMode} key="cancel">
                  <CancelIcon />
                </IconButton>,
              ]
            : [
                <Tooltip
                  title={
                    disableReservationCreate
                      ? "Reservations must be created in Assets module"
                      : "Create New Reservation"
                  }
                  key="new reservation"
                >
                  <span>
                    <IconButton
                      color="primary"
                      disabled={disableReservationCreate}
                      onClick={createReservation}
                      key="rent"
                    >
                      <SvgIcon fontSize="small">
                        <RentIcon />
                      </SvgIcon>
                    </IconButton>
                  </span>
                </Tooltip>,
                <Tooltip title="Download QR Code" key="QR">
                  <IconButton
                    color="primary"
                    onClick={downloadQRCode}
                    data-testid="download-qr-code"
                    key="download-qr-code"
                  >
                    <QrCodeRoundedIcon />
                  </IconButton>
                </Tooltip>,
                <PDFDownloadLink
                  document={
                    <AssetSummaryPDF
                      branch={branchesHash[currentAsset?.branch]}
                      workOrders={workOrders}
                      asset={currentAsset}
                      images={compressedImages}
                      yard={yardsHash[currentAsset?.yard]}
                    />
                  }
                  fileName={`Asset Summary - ${currentAsset?.serialNumber}.pdf`}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  key="service-damage-report"
                >
                  <Tooltip title="Asset PDF">
                    {isCompressing ? (
                      <CircularProgress size={24} />
                    ) : (
                      <IconButton color="primary">
                        <SvgIcon fontSize="small">
                          <PdfIcon />
                        </SvgIcon>
                      </IconButton>
                    )}
                  </Tooltip>
                </PDFDownloadLink>,
                <Tooltip title="Create Work Order" key="new WO">
                  <IconButton
                    color="primary"
                    onClick={createWorkOrder}
                    key="newWorkOrder"
                  >
                    <MaintenanceIcon />
                  </IconButton>
                </Tooltip>,
                <Tooltip title="Edit Asset" key="edit asset">
                  <IconButton
                    key="edit"
                    color="primary"
                    onClick={editMode}
                    disabled={!userCanEditAssets}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>,
              ];

        return (
          <Form>
            <Blade
              title={currentAsset?.inProduction ? (
                <AsyncButton
                  sx={{ marginRight: 1, minWidth: 200 }}
                  size="small"
                  variant="contained"
                  disabled={!userCanEditAssets}
                  onClick={handleProductionComplete}
                  isLoading={isUpdatingAsset}
                >
                  Production Complete
                </AsyncButton>
              ) : undefined}
              actions={bladeActions()}
              open={Boolean(currentAssetId)}
              changeOpen={closeBlade}
              onTop={bladeMode === "assetViewEdit"}
            >
              {isEditing ? <AssetForm /> : <AssetView photos={assetPhotos} asset={currentAsset} />}
            </Blade>
            <QRCodeCanvas
              value={`${process.env.REACT_APP_URL}/assets/${currentAsset?.serialNumber}`}
              style={{
                width: "100%",
                height: "100%",
                display: "none"
              }}
              size={240}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssetViewEditBlade;
