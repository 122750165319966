import { Yard } from "store/models/Yard";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { oneMinute } from "utils/timeValues";

export const yardApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getYards: builder.query<Yard[], void>({
      query: (query) => `yard`,
      transformResponse: getResponseBody,
      providesTags: [{ type: "Yards", id: `LIST` }],
      keepUnusedDataFor: oneMinute * 60,
    }),
    updateYard: builder.mutation<Yard, Yard>({
      query: (body) => ({
        url: `yard`,
        method: "PATCH",
        body,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: [{ type: "Yards", id: `LIST` }],
      extraOptions: {maxRetries: 0},
    }),
  }),
});

export const { useGetYardsQuery, useUpdateYardMutation } = yardApi;
