import React, { FC } from "react";
import { TextField, MenuItem, TextFieldProps } from "@mui/material";
import { FormMenuStyles } from ".";
import _ from "lodash";
import useConfigs from "hooks/useConfigs";

const ConditionSelect: FC<TextFieldProps> = ({
  value = "",
  ...rest
}) => {
  const {conditions} = useConfigs();
  return (
    <TextField
      value={value}
      {...rest}
      select
      SelectProps={{
        MenuProps: FormMenuStyles,
      }}
    >
    <MenuItem value="" />,
    {_.cloneDeep(conditions.settings).sort().map((condition) => (
      <MenuItem key={condition.number} value={condition.number}>
        {condition.conditionString}
      </MenuItem>
    ))}
    </TextField>
    )
}

export default ConditionSelect;