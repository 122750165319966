import React, { FC, ReactNode, useEffect } from "react";
import { Contract, awaitingContractStatuses } from "store/models/Contract";
import { MMDDYYYY, toMMDDYYYY, dateFromMMDDYYYY } from "utils/util";
import DateIncrementor from "components/DateIncrementor";
import { Box, Card, Checkbox, Grid2, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ClientAutoComplete } from "components/autocomplete";
import { FastField, FastFieldProps, useFormikContext } from "formik";
import DateSelector from "components/DateSelector";
import { simpleGlobalMessage } from "store/slices/systemSlice";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import AddressAutoComplete from "components/autocomplete/AddressAutoComplete";
import Address from "store/models/Address";
import { useAppDispatch } from 'store/store';
import CurrencyTextField from "components/CurrencyTextField";
import ClientContactSelect from "components/autocomplete/ClientContactSelect";
import { useGetClientContactByIdQuery } from "store/services/clientContact";
import { ClientAutoCompleteResult, useGetClientByIdQuery, useUpdateClientMutation } from "store/services/client";
import { useSelector } from "react-redux";
import { selectCreatedClientId } from "store/slices/clientSlice";

interface ContractRowProps {
  label: ReactNode;
  input?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

const ContractRow: FC<ContractRowProps> = ({ label, input, onClick }) => {
  return (
    <Grid2 container alignItems="center" my={1} onClick={onClick}>
      <Grid2
        size={4}
        sx={{ color: "text.secondary" }}
      >
        {typeof label === "string" ? <Typography>{label}</Typography> : label}
      </Grid2>
      <Grid2 size={8} paddingRight={2} textAlign="right">
        {input}
      </Grid2>
    </Grid2>
  );
};

interface Props {
}

const ContractForm: React.FC<Props> = () => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
  } = useFormikContext<Contract>();
 
  const dispatch = useAppDispatch()
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
  const {data: clientContact  } = useGetClientContactByIdQuery(values?.clientContact || "", {skip: !values?.clientContact});
  const {data: siteContact} = useGetClientContactByIdQuery(values?.siteContact || "", {skip: !values?.siteContact});
  const {currentData: client} = useGetClientByIdQuery(values?.client || "", {skip: !values?.client});
  const createdClientId = useSelector(selectCreatedClientId)
  const [updateClient] = useUpdateClientMutation();

  const handleAddressSelect = (type: 'billTos' | 'shipTos', address: Address) => {
    if (!client) return
    const updatedClient = {
      ...client,
      [type]: [...client[type], address]
    }
    updateClient(updatedClient).unwrap().then(() => {
      dispatch(simpleGlobalMessage("New Address Added", "success"))
      setFieldValue(type === 'billTos' ? 'billTo' : 'shipTo', address)
    })
  }
  useEffect(() => {
    if (createdClientId) {
      setFieldValue("client", createdClientId)
    }
  }, [createdClientId])

  return (
    <Card sx={{ p: 2.5, mb: 2 }}>
      <Grid2
        sx={{ pl: 3 }}
      >
        <ContractRow
          label="Project #"
          input={
            <TextField
              name="projectNumber"
              value={values.projectNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              error={Boolean(touched.projectNumber && errors.projectNumber)}
              inputProps={{
                "data-testid": "projectNumber",
              }}
              size="small"
            />
          }
        />

        <ContractRow
          label="Contract Type"
          input={
            <ToggleButtonGroup
              exclusive
              fullWidth
              value={values.contractType}
              onChange={(_, value) => {
                value && setFieldValue("contractType", value);
              }}
              size="small"
              color="primary"
            >
              <ToggleButton
                data-testid="Rental"
                sx={{ minWidth: 100 }}
                value="Rental"
              >
                Rent
              </ToggleButton>
              <ToggleButton
                data-testid="Sale"
                sx={{ minWidth: 100 }}
                value="Sale"
              >
                Sale
              </ToggleButton>
            </ToggleButtonGroup>
          }
        />

        {awaitingContractStatuses.includes(values.status) && (
          <ContractRow
            label="Reservation Expiry"
            input={
              <DateSelector
                value={dateFromMMDDYYYY(values.reservationExpiry)}
                onChange={(date) =>
                  date &&
                  setFieldValue("reservationExpiry", toMMDDYYYY(new Date(date)))
                }
                textFieldProps={{
                  size: "small",
                  onBlur: handleBlur,
                  fullWidth: true,
                  error: Boolean(touched.outDate && errors.outDate),
                }}
              />
            }
          />
        )}

        <ContractRow
          label={values.contractType === "Sale" ? "Sale Date" : "Start Date"}
          input={
            <DateSelector
              value={dateFromMMDDYYYY(values.startDate)}
              onChange={(date) =>
                date && setFieldValue("startDate", toMMDDYYYY(new Date(date)))
              }
              textFieldProps={{
                size: "small",
                onBlur: handleBlur,
                fullWidth: true,
                error: Boolean(touched.startDate && errors.startDate),
              }}
            />
          }
        />

        <ContractRow
          label="Out Date"
          input={
            <DateSelector
              value={dateFromMMDDYYYY(values.outDate)}
              onChange={(date) =>
                date && setFieldValue("outDate", toMMDDYYYY(new Date(date)))
              }
              textFieldProps={{
                size: "small",
                onBlur: handleBlur,
                fullWidth: true,
                error: Boolean(touched.outDate && errors.outDate),
              }}
            />
          }
        />
        {values.evergreen ? (
          <ContractRow label="End Date" input="N/A (Evergreen)" />
        ) : (
          values.contractType !== "Sale" && (
            <>
              <ContractRow
                label="End Date"
                input={
                  <DateSelector
                    value={values.endDate ? dateFromMMDDYYYY(values.endDate) : null}
                    onChange={(date) =>
                      setFieldValue(
                        "endDate",
                        date ? toMMDDYYYY(new Date(date)) : null
                      )
                    }
                    clearable
                    textFieldProps={{
                      size: "small",
                      onBlur: handleBlur,
                      fullWidth: true,
                      error: Boolean(touched.endDate && errors.endDate),
                    }}
                  />
                }
              />

              <DateIncrementor
                date={new Date(values.endDate as MMDDYYYY)}
                changeDate={(newDate: Date) =>
                  setFieldValue("endDate", toMMDDYYYY(newDate))
                }
                disabled={values.evergreen}
              />
            </>
          )
        )}

        {values.contractType !== "Sale" && (
          <ContractRow
            label="Evergreen"
            input={
              <Checkbox
                name="evergreen"
                checked={values.evergreen}
                onChange={() => {
                  setFieldValue("evergreen", !values.evergreen);
                  setFieldValue(
                    "endDate",
                    !values.evergreen ? null : toMMDDYYYY(new Date())
                  );
                }}
                onBlur={handleBlur}
                data-testid="evergreen"
              />
            }
          />
        )}

        <ContractRow
          label="Signed"
          input={
            <ToggleButtonGroup
              exclusive
              fullWidth
              value={String(values.contractSigned)}
              onChange={(_, value) => {
                value && setFieldValue("contractSigned", value);
              }}
              size="small"
              color="primary"
            >
              <ToggleButton
                data-testid="notSigned"
                sx={{ minWidth: 100 }}
                value="false"
              >
                No
              </ToggleButton>
              <ToggleButton
                data-testid="isSigned"
                sx={{ minWidth: 100 }}
                value="true"
              >
                Yes
              </ToggleButton>
            </ToggleButtonGroup>
          }
        />
        <ContractRow
          label="Client"
          key={`client-autocomplete-${client?._id}`}
          input={
            <FastField name="client">
              {({ field, form }: FastFieldProps) => (
                <ClientAutoComplete
                  {...field}
                  value={values.client && client ? {_id: client._id, companyName: client.companyName, country: client.country} : undefined}
                  size="small"
                  onChange={(_, value) => {
                    form.setFieldValue(
                      "client",
                      (value as ClientAutoCompleteResult)?._id || null
                    );
                    form.setFieldValue("billTo", null);
                    form.setFieldValue("shipTo", null);
                    form.setFieldValue("clientContact", null);
                    form.setFieldValue("siteContact", null);
                       form.setFieldValue(
                      "customerName",
                      (value as ClientAutoCompleteResult)?.companyName || null
                    );
                  }}
                  fullWidth
                  error={Boolean(touched.client && errors.client)}
                  data-testid="client"
                />
              )}
            </FastField>
          }
        />

        <ContractRow
          label="Main Contact"
          input={
            <ClientContactSelect
              clientContact={values.clientContact ? clientContact : undefined}
              size="small"
              handleChange={(value) => {
                setFieldValue("clientContact", value ? value._id : null);
              }}
              fullWidth
              defaultClientId={values.client}
            />
          }
        />
        <ContractRow
          label="Site Contact"
          input={
            <ClientContactSelect
              clientContact={values.siteContact ? siteContact : undefined}
              size="small"
              handleChange={(value) => {
                setFieldValue("siteContact", value ? value._id : null);
              }}
              fullWidth
            />
          }
        />
        <ContractRow
          label="Ship To"
          onClick={() =>
            !userCanEditContracts &&
            dispatch(
              simpleGlobalMessage(
                "You do not have the permissions to change this value"
              )
            )
          }
          input={
            <AddressAutoComplete
              disabled={!userCanEditContracts}
              value={values.shipTo}
              error={Boolean(touched.shipTo && errors.shipTo)}
              options={client?.shipTos || []}
              size="small"
              onChange={(_, value) => setFieldValue("shipTo", value as Address)}
              allowClientUpdates
              onAddressSelect={(address) => handleAddressSelect('shipTos', address)}
            />
          }
        />

        <ContractRow
          label="Bill To"
          onClick={() =>
            !userCanEditContracts &&
            dispatch(
              simpleGlobalMessage(
                "You do not have the permissions to change this value"
              )
            )
          }
          input={
            <AddressAutoComplete
              disabled={!userCanEditContracts}
              value={values.billTo}
              error={Boolean(touched.billTo && errors.billTo)}
              options={client?.billTos || []}
              size="small"
              onChange={(_, value) => setFieldValue("billTo", value as Address)}
              allowClientUpdates
              onAddressSelect={(address) => handleAddressSelect('billTos', address)}
            />
          }
        />

        {values.contractType !== "Sale" && (
          <>
            <ContractRow
              label={
                <Box>
                  Deposit
                  <Checkbox
                    name="securityDeposit.received"
                    checked={values.securityDeposit.received}
                    onChange={() => {
                      setFieldValue(
                        "securityDeposit.received",
                        !values.securityDeposit.received
                      );
                    }}
                    sx={{ ml: 5 }}
                    onBlur={handleBlur}
                    data-testid="evergreen"
                  />
                </Box>
              }
              input={
                <CurrencyTextField
                  fullWidth
                  value={values.securityDeposit.amount}
                  onChange={(event) => {
                    setFieldValue(
                      "securityDeposit.amount.amount",
                      Number(event.target.value) || undefined
                    );
                  }}
                  onCurrencyChange={(event) => {
                    event.target.value &&
                      setFieldValue(
                        "securityDeposit.amount.currency",
                        event.target.value
                      );
                  }}
                  disabled={!values.securityDeposit.received}
                  error={Boolean(
                    touched?.securityDeposit && errors?.securityDeposit
                  )}
                  size="small"
                />
              }
            />

            <ContractRow
              label="PPSA #"
              input={
                <TextField
                  name="ppsaRegistration"
                  value={values.ppsaRegistration || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={Boolean(
                    touched.ppsaRegistration && errors.ppsaRegistration
                  )}
                  size="small"
                />
              }
            />
          </>
        )}

        <ContractRow
          label="Client PO Number"
          input={
            <TextField
              name="clientPoNumber"
              value={values.clientPoNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              error={Boolean(touched.clientPoNumber && errors.clientPoNumber)}
              size="small"
            />
          }
        />

        <ContractRow
          label="Quote Number"
          input={
            <TextField
              name="quoteNumber"
              value={values.quoteNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              error={Boolean(touched.quoteNumber && errors.quoteNumber)}
              size="small"
            />
          }
        />

        <Typography
          sx={{ color: "text.secondary", justifySelf: "left", py: 1 }}
        >
          Delivery Instructions
        </Typography>
        <TextField
          name="deliveryInstructions"
          value={values.deliveryInstructions}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          multiline
          minRows={3}
          error={Boolean(
            touched.deliveryInstructions && errors.deliveryInstructions
          )}
          size="small"
        />

        <Typography
          sx={{ color: "text.secondary", justifySelf: "left", py: 1 }}
        >
          Additional Comments
        </Typography>
        <TextField
          name="additionalComments"
          value={values.additionalComments}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          multiline
          minRows={3}
          error={Boolean(
            touched.additionalComments && errors.additionalComments
          )}
          size="small"
        />
      </Grid2>
    </Card>
  );

};
export default ContractForm;
