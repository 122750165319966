import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as TrekIcon } from 'svg/trekIcon.svg';
import { ReactComponent as StarFleetTextSvg } from 'svg/starfleetText.svg';
import BarChart from "@mui/icons-material/BarChart";
import Apartment from "@mui/icons-material/Apartment";
import AccountBox from "@mui/icons-material/AccountBox";
import AssistantDirection from "@mui/icons-material/AssistantDirection";
import Group from "@mui/icons-material/Group";
import Description from "@mui/icons-material/Description";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import ConfigSettings from "@mui/icons-material/PermDataSetting";
import Construction from "@mui/icons-material/Construction";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { setBladeMode } from 'store/slices/systemSlice';
import { clearCurrentAssetId } from "store/slices/assetSlice";
import {
  setCurrentContractId,
  setCurrentMovementId,
  setNewReservation,
} from "store/slices/contractSlice";
import { setCurrentClientId } from 'store/slices/clientSlice';
import { setCurrentWorkOrderId } from 'store/slices/maintenanceSlice';
import Scrollbar from 'components/Scrollbar';
import { Box, CircularProgress, Drawer, Stack, SvgIcon } from '@mui/material';
import { styled } from "@mui/material/styles";
import { DASHBOARD_NAVBAR_COLLAPSE_WIDTH, DASHBOARD_NAVBAR_WIDTH } from 'config';
import cssStyles from 'utils/cssStyles';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import CollapseButton from './CollapseButton';
import NavbarAccount from './NavbarAccount';
import NavSection from 'components/nav-section';
import {
  PATH_ASSETS,
  PATH_CLIENTS,
  PATH_CONTRACTS,
  PATH_DASHBOARD,
  PATH_MOVEMENTS,
  PATH_SYSTEM,
  PATH_USERS,
  PATH_MAINTENANCE,
  PATH_MUL,
  PATH_SETTINGS,
} from "routes/paths";
import useResponsive from 'hooks/useResponsive';
import Label from 'components/label';
import { NavSectionProps } from 'components/nav-section/type';
import usePermissions, { FeatureType, ActionType } from 'hooks/usePermissions';
import { useAppDispatch } from 'store/store';
import { setCurrentTransportCompanyId } from 'store/slices/transportCompanySlice';
import { useGetTotalNumberOfActionsRequiredQuery } from 'store/services/contract';
import { oneMinute } from 'utils/timeValues';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
  fontSize: "100%"
}));

const StarFleetText = styled(StarFleetTextSvg)(({ theme }) => ({
  fill: theme.palette.text.secondary,
  height: 26,
}));

interface Props {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
}

const SideNav: React.FC<Props> = ({ isOpenSidebar, onCloseSidebar }) => {
  const {data: numberOfActionsRequired = 0, isLoading: isLoadingActionsRequiredCount} = useGetTotalNumberOfActionsRequiredQuery(undefined, {pollingInterval: oneMinute * 5})
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const isDesktop = useResponsive('up', 'lg');
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const closeAllAppLevelBlades = () => {
    dispatch(setBladeMode("none"));
    dispatch(clearCurrentAssetId());
    dispatch(setCurrentContractId(undefined));
    dispatch(setCurrentClientId(undefined));
    dispatch(setCurrentMovementId(undefined))
    dispatch(setCurrentWorkOrderId(undefined))
    dispatch(setCurrentTransportCompanyId(undefined))
  }

  const checkUserPermissions = usePermissions(FeatureType.USER);
  const checkUPermissionPrivileges = usePermissions(FeatureType.PERMISSION);
  const userCanViewDashboards = usePermissions(FeatureType.DASHBOARD)(ActionType.READ);
  const userCanViewUsers = checkUserPermissions(ActionType.READ); 
  const userCanViewPermissions = checkUPermissionPrivileges(ActionType.READ); 
  const userCanEditSettings = checkUPermissionPrivileges(ActionType.UPDATE); 
  const userCanViewAssets = usePermissions(FeatureType.ASSET)(ActionType.READ);
  const userCanViewContracts = usePermissions(FeatureType.CONTRACT)(ActionType.READ);
  const userCanViewClients = usePermissions(FeatureType.CLIENTS)(ActionType.READ);
  const userCanViewMovements = usePermissions(FeatureType.MOVEMENT)(ActionType.READ);
  const userHasLimitedMaintenanceRead = usePermissions(FeatureType.MAINTENANCE)(ActionType.LIMITED_READ);
  const userHasFullMaintenanceRead = usePermissions(FeatureType.MAINTENANCE)(ActionType.READ);

  useEffect(function closeOnNav() {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    if (isDesktop) {
      onCloseSidebar()
    }
  }, [isDesktop])

  useEffect(function closeReservationBladeOnNavigate() {
    dispatch(setNewReservation(undefined));
  }, [pathname]);

  const navConfig: NavSectionProps["navConfig"] = [
    {
      subheader: "modules",
      items: [
        {
          title: "dashboard",
          path: PATH_DASHBOARD.root,
          icon: <BarChart />,
          children: [
            { title: "app", path: PATH_DASHBOARD.app },
            { title: "fleet", path: PATH_DASHBOARD.fleet },
            { title: "finance", path: PATH_DASHBOARD.finance },
            { title: "geo", path: PATH_DASHBOARD.geo },
            { title: "movements", path: PATH_DASHBOARD.movements },
          ],
          hidden: !userCanViewDashboards,
        },
        {
          title: "assets",
          path: PATH_ASSETS.root,
          icon: <Apartment />,
          hidden: !userCanViewAssets,
        },
        {
          title: "MUL",
          path: PATH_MUL.root,
          icon: <ReceiptLongIcon />,
          hidden: !userCanViewAssets,
        },
        {
          title: "contracts",
          path: PATH_CONTRACTS.root,
          icon: <Description />,
          info: (
            <Label variant="soft" color="error">
              {isLoadingActionsRequiredCount ? (
                <Box
                  sx={{ width: 30, display: "flex", justifyContent: "center" }}
                >
                  <CircularProgress size={14} />
                </Box>
              ) : (
                `+${numberOfActionsRequired}`
              )}
            </Label>
          ),
          hidden: !userCanViewContracts,
        },
        {
          title: "clients",
          path: PATH_CLIENTS.root,
          hidden: !userCanViewClients,
          icon: <AccountBox />,
          children: [
            { title: "clients", path: PATH_CLIENTS.clients },
            { title: "contacts", path: PATH_CLIENTS.contacts },
          ],
        },
        {
          title: "movements",
          path: PATH_MOVEMENTS.root,
          icon: <AssistantDirection />,
          hidden: !userCanViewMovements,
          children: [
            { title: "movements", path: PATH_MOVEMENTS.movements },
            {
              title: "transport companies",
              path: PATH_MOVEMENTS.transportCompanies,
            },
          ],
        },
        {
          title: "maintenance",
          path: PATH_MAINTENANCE.root,
          icon: <Construction />,
          hidden: !userHasFullMaintenanceRead && !userHasLimitedMaintenanceRead,
        },
      ],
    },
    {
      subheader: "system",
      items: [
        {
          title: "users",
          path: PATH_USERS.root,
          icon: <Group />,
          hidden: !userCanViewUsers,
        },
        {
          title: "settings",
          path: PATH_SETTINGS.root,
          icon: <ConfigSettings />,
          children: [
            { title: "profile", path: PATH_SETTINGS.profile },
            {
              title: "branch",
              path: PATH_SETTINGS.branch,
              hidden: !userCanEditSettings,
            },
            {
              title: "size Codes",
              path: PATH_SETTINGS.sizeCodes,
              hidden: !userCanEditSettings,
            },
            {
              title: "permissions",
              path: PATH_SETTINGS.permisssions,
              hidden: !userCanViewPermissions,
            },
          ],
        },
        { title: "logout", path: PATH_SYSTEM.root, icon: <PowerSettingsNew /> },
      ],
    },
  ];

  process.env.NODE_ENV !== "production" && navConfig[1].items.push({ title: 'PDF playground', path: './pdfPlayground', icon: <PictureAsPdf /> })

  const drawerContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SvgIcon fontSize="large" sx={{ color: "#fda92d", my: 1 }}>
            <TrekIcon />
          </SvgIcon>
          {!isCollapse && (
            <>
              <StarFleetText />
              <CollapseButton
                data-testid="CollapseButton"
                onToggleCollapse={() => {
                  isDesktop ? onToggleCollapse() : onCloseSidebar();
                }}
                collapseClick={collapseClick}
              />
            </>
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>
      <NavSection navConfig={navConfig} isCollapse={isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        
        width: {
          lg: isCollapse ? DASHBOARD_NAVBAR_COLLAPSE_WIDTH : DASHBOARD_NAVBAR_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute',
        })
      }}
      data-testid="SideNav"
      onClick={closeAllAppLevelBlades}
    >

      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: DASHBOARD_NAVBAR_WIDTH } }}
        >
          {drawerContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          variant="persistent"
          anchor="left"
          open
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: (theme) => ({
              zIndex: 10,
              width: DASHBOARD_NAVBAR_WIDTH,
              bgcolor: process.env.REACT_APP_STAGING_COLOURATION ? "warning.dark" : theme.palette.background.default,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: DASHBOARD_NAVBAR_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                backgroundColor: process.env.REACT_APP_STAGING_COLOURATION ? "warning.dark" : theme.palette.background.default,
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            }),
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </RootStyle>
  )
};

export default SideNav;
