import { Box, List, ListItem, useTheme, alpha, Divider, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import YardDetails from './YardDetails';
import { Yard } from 'store/models/Yard';
import { userIsSuper } from 'hooks/usePermissions';
import { useGetYardsQuery } from 'store/services/yard';
import useLoggedInUser from 'hooks/useLoggedInUser';

interface Props {
    branchId: string
}

const BranchYards: React.FC<Props> = ({ branchId }) => {
  const {data: yards = []} = useGetYardsQuery();
  const branchYard = yards.find((yard) => yard.branch === branchId);
  const [yard, setYard] = useState<Yard | undefined>(branchYard);
  const [showAllYards, setShowAllYards] = useState(false);
  const handleYardClick = (yard: Yard) => {
    setYard(yard);
  };
  const {loggedInUser} = useLoggedInUser()

  function yardIsSelected(listYard: Yard) {
    return listYard._id === yard?._id;
  }

  const theme = useTheme();
  const isSuper = userIsSuper(loggedInUser)

  useEffect(() => {
      setYard(branchYard);

  },[branchId])

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{ ml: 2, maxHeight: "55vh", overflowY: "scroll", minWidth: 200 }}
      >
        {isSuper && (
          <Box sx={{ display: "flex", pt: 4 }}>
            <Button
              color="primary"
              variant={showAllYards ? "contained" : "outlined"}
              size="small"
              onClick={() => setShowAllYards(!showAllYards)}
              disabled={!isSuper}
              fullWidth
            >
              Show All
            </Button>
          </Box>
        )}

        <List>
          {yards
            .filter((yard) => (showAllYards ? yard : yard.branch === branchId))
            .map((yard, index) => (
              <ListItem
                sx={{
                  cursor: "pointer",
                  bgcolor: yardIsSelected(yard)
                    ? alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity
                      )
                    : undefined,
                }}
                key={index}
                onClick={() => handleYardClick(yard)}
              >
                {yard.name}
              </ListItem>
            ))}
        </List>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ pt: 4, width: "100%"}}>
        {yard ? (
          <YardDetails yard={yard} branchId={branchId} />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: 685,
              textAlign: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>No Yards Found For Branch</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BranchYards;