import React, { FC } from "react";
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material";
import { USER_ROLE } from "hooks/usePermissions";
import useLoggedInUser from "hooks/useLoggedInUser";
import useConfigs from "hooks/useConfigs";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (country: string) => country,
});

type Props = Omit<
  AutocompleteProps<string, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name: string;
  error?: boolean;
};

const CountryAutocomplete: FC<Props> = ({ value, name, error, ...rest }) => {
  const {countries} = useConfigs();
  const { loggedInUser } = useLoggedInUser()
  const userIsSuper = loggedInUser?.role === USER_ROLE.SUPER_USER

  function onlyLoggedInUserCountries(country: string) {
    return loggedInUser?.countries.includes(country)
  }

  const getOptions = () => {
    if (userIsSuper) return Object.keys(countries.settings)
    else return Object.keys(countries.settings).filter(onlyLoggedInUserCountries)
  }

  return (
    <Autocomplete
      {...rest}
      value={value || null}
      getOptionLabel={(country) => country}
      filterOptions={filterOptions}
      options={getOptions()}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          error={error}
          margin="none"
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password",
            form: { autocomplete: "off" },
          }}
        />
      )}
    />
  );
};

export default CountryAutocomplete;
