import React from "react";
import { ReactComponent as StarFleetTextSvg } from "svg/starfleetText.svg";
import { Box, Button, styled } from "@mui/material";
import Logo from "components/Logo";

const StarFleetText = styled(StarFleetTextSvg)(({ theme }) => ({
  fill: theme.palette.text.primary,
  height: 60,
}));

interface Props {
  handleLogin: (e?: React.FormEvent) => Promise<void>
}

const LoginScreen: React.FC<Props> = ({handleLogin}) => {

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          columnGap: 2,
          rowGap: 2,
          alignItems: "center",
        }}
      >
        <Logo sx={{ height: 48 }} />
        <StarFleetText />
        <Button
          fullWidth
          variant="contained"
          onClick={handleLogin}
          sx={{ gridColumn: "1 / 3" }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};
export default LoginScreen;
