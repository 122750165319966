import {
  Container,
  Box,
  Card,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import BladeTabs from "components/BladeTabs";
import PageHeader from "components/header/PageHeader";
import useSettings from "hooks/useSettings";
import BranchDetails from "./BranchDetails";
import { Branch } from "store/models/Branch";
import { BranchAutoComplete } from "components/autocomplete";
import { isOfDocumentType } from "utils/util";
import BranchYards from "./yards/BranchYards";
import { useGetBranchesQuery } from "store/services/branches";
import { byIds } from "store/sliceUtils";
import LoadingComponent from "components/LoadingComponent";
import useLoggedInUser from "hooks/useLoggedInUser";

function BranchSettings() {
  const { themeStretch } = useSettings();
  const { data: branches = [], isLoading } = useGetBranchesQuery();
  const branchesHash = byIds(branches);
  const {loggedInUser} = useLoggedInUser()
  const [branch, setBranch] = useState<Branch>(
    branchesHash[loggedInUser?.branches[0] || ""]
  );

  useEffect(() => {
    if (loggedInUser && loggedInUser.branches.length > 0) {
      setBranch(branchesHash[loggedInUser.branches[0]]);
    }
  }, [isLoading])

  const handleBranchChange = (
    event: SyntheticEvent<Element, Event>,
    value: NonNullable<string | Branch> | (string | Branch)[] | null
  ) => {
    if (isOfDocumentType<Branch>(value)) {
      setBranch(value);
    }
  };

  if (!loggedInUser) return <Box>No User found</Box>;

  return (
    <LoadingComponent isLoading={isLoading}>
    <Container maxWidth={themeStretch ? false : "lg"}>
      <PageHeader
        heading="Branch Settings"
        links={[
          { name: "System" },
          { name: "Settings" },
          { name: "Branch Settings" },
        ]}
      />
      <Card>
        <BranchAutoComplete
          value={branch}
          onChange={handleBranchChange}
          sx={{ m: 5, width: 200 }}
          userBranchesOnly
        />
        <BladeTabs
          isClosing={false}
          tabsBottomMargin={0}
          tabs={[
            {
              tabComponent: (
                <BranchDetails branchId={branch?._id} />
              ),
              tabName: "Details",
            },
            {
              tabComponent: <BranchYards branchId={branch?._id} />,
              tabName: "Yards",
            },
          ]}
        />
      </Card>
    </Container>
    </LoadingComponent>
  );
}

export default BranchSettings;
