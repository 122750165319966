import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STARFLEET_STORE } from "store/store";
import { ICountry } from "./configSlice";
import { WorkOrderQuery } from "store/services/workOrder";

type InspectionType = `offRent${ICountry["name"]}` | `onRent${ICountry["name"]}`;

export type InspectionConfig =  { displayName: string, name: InspectionType };

const initialQuery: WorkOrderQuery = {
  workOrderNumber: undefined,
  assetNumber: undefined,
  serialNumber: undefined,
  statuses: [],
  branch: undefined,
  priority: undefined,
  category: undefined,
  subCategory: undefined,
  pendingMovement: undefined,
  workOrderType: undefined,
  startDate: undefined,
  endDate: undefined,
  assignedTo: undefined,
  createdBy: undefined,
  client: undefined,
  projectNumber: undefined,
};

export type MAINTENANCE_STORE = {
  currentWorkOrderId: string | undefined;
  workOrderQueryArgs: WorkOrderQuery
};

const initialState: MAINTENANCE_STORE = {
  currentWorkOrderId: undefined,
  workOrderQueryArgs: initialQuery,
};

const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setCurrentWorkOrderId: (state, action: PayloadAction<string | undefined>) => {
      state.currentWorkOrderId = action.payload;
    },
    setWorkOrderQueryArgs(state, action: PayloadAction<WorkOrderQuery>) {
      state.workOrderQueryArgs = action.payload;
    }
  },
});

export const selectWorkOrderQueryArgs = (state: STARFLEET_STORE) => state.maintenance.workOrderQueryArgs;


export const selectCurrentWorkOrderId = (state: STARFLEET_STORE) => state.maintenance.currentWorkOrderId;

export const {
  setCurrentWorkOrderId,
  setWorkOrderQueryArgs
} = maintenanceSlice.actions;


export default maintenanceSlice.reducer;
