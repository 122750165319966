import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ContractAsset from "./ContractAsset";
import { useSelector } from "react-redux";
import {
  selectCurrentContractId,
} from "store/slices/contractSlice";
import Movement  from "store/models/Movement";
import { toMMDDYYYY } from "utils/util";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import { Box, Button, Card, Typography, Checkbox, FormControlLabel, ToggleButtonGroup, ToggleButton, darken } from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { AddAssetModalMode } from "components/modals/AddAssetModal";
import BulkMovementCreate from "features/contracts/BulkMovementCreate";
import useContractBladeUtils from "features/contracts/blades/useContractBladeUtils";
import { inactiveContractStatuses } from "store/models/Contract";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { useGetContractByIdQuery, useUpdateContractMutation } from "store/services/contract";
import { useGetAssetsByContractIdQuery } from "store/services/asset";
import { byIds } from "store/sliceUtils";
import LoadingComponent from "components/LoadingComponent";
import useLoggedInUser from "hooks/useLoggedInUser";

interface Props {
  setAddAssetMode: (mode: AddAssetModalMode, filters?: GridFilterModel) => void;
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>
}

const ContractAssets: React.FC<Props> = ({ setAddAssetMode, changeIsSubmitting }) => {
  const currentContractId = useSelector(selectCurrentContractId);
  const {data: currentContract} = useGetContractByIdQuery(currentContractId || "", {skip: !currentContractId});
  const {data: contractAssets = [], isLoading: contractAssetsLoading, isFetching, currentData} = useGetAssetsByContractIdQuery(currentContractId || "", {skip: !currentContractId});
  const assetMap = byIds(contractAssets);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const {loggedInUser} = useLoggedInUser()
  const [sameBillingStart, changeSameBillingStart] = useState(() => allCustomBillingStartSameDate());
  const [sameBillingEnd, changeSameBillingEnd] = useState(() => allCustomBillingEndSameDate());
  const visibleDeliverables = currentContract?.assetDeliverables.filter((deliverable) => deliverable.isActiveOnContract);
  const hiddenDeliverables = currentContract?.assetDeliverables.filter((deliverable) => !deliverable.isActiveOnContract);
  const [showInactive, setShowInactive] = useState(false);
  const [newMovements, setNewMovements] = useState<Movement[]>([]);
  const [updateContract] = useUpdateContractMutation();

  const contractIsInactive = currentContract? inactiveContractStatuses.includes(currentContract.status) : true;

  const {handleScheduleAllMovements} = useContractBladeUtils()

  useEffect(() => {
    sameBillingStart && !allCustomBillingStartSameDate() && setAllCustomBillingStarts();
  }, [sameBillingStart]);

  useEffect(() => {
    sameBillingEnd && !allCustomBillingEndSameDate() && setAllCustomBillingEnds();
  }, [sameBillingEnd]);


  if (!currentContract) return null;

  function userCanEditContract(){
    const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
    const userCanEditOwnContract = userTryingToCancelTheirOwnContract() && checkContractPermissions(ActionType.LIMITED_UPDATE);
    if (currentContract?.status === "ACTIVE") {
      return userCanEditContracts;
    } else {
      return (userCanEditOwnContract || userCanEditContracts);
    }
  }

  function userTryingToCancelTheirOwnContract() {
    return loggedInUser?._id === currentContract?.createdBy;
  }

  const handleAddAsset = () => {
    if (!currentContract) return;
    setAddAssetMode("add")
  }

  function allCustomBillingStartSameDate() {
    return currentContract ? currentContract.assetDeliverables.every(
      (deliverable) =>
        deliverable.customBillingStart === currentContract.assetDeliverables[0].customBillingStart
    ) : false;
  }
  
  function allCustomBillingEndSameDate() {
    return currentContract ? currentContract.assetDeliverables.every(
      (deliverable) =>
        deliverable.customBillingEnd === currentContract.assetDeliverables[0].customBillingEnd
    ) : false;
  }

  function setAllCustomBillingStarts() {
    if (!currentContract) return;
    const customBillingStart = currentContract.assetDeliverables[0].customBillingStart || currentContract.startDate;
    const contractUpdate = {
      ...currentContract,
      assetDeliverables: currentContract.assetDeliverables.map(
        (deliverable) => {
          return { ...deliverable, customBillingStart};
        }
        ),
      };
    updateContract({ ...contractUpdate }).unwrap().then(({ contract }) => {
      ;
      changeSameBillingStart(true);
    }
    );
  }

  function setAllCustomBillingEnds() {
    if (!currentContract) return;
    const customBillingEnd = currentContract.assetDeliverables[0].customBillingEnd || currentContract.endDate || toMMDDYYYY(new Date());
    const contractUpdate = {
      ...currentContract,
      assetDeliverables: currentContract.assetDeliverables.map(
        (deliverable) => {
          return { ...deliverable, customBillingEnd};
        }
      ),
    };
    updateContract({ ...contractUpdate }).unwrap().then(({ contract }) => {
      ;
      changeSameBillingEnd(true);
    }
    );
  }

  return (
    <Box sx={{ display: "grid", rowGap: 2 }}>
      <Box
        sx={{ mx: 2 }}
        display="grid"
        columnGap={2}
        gridTemplateColumns="6fr 6fr"
      >
        <Button
          onClick={() => handleScheduleAllMovements(setNewMovements)}
          fullWidth
          variant="outlined"
        >
          <Typography>Schedule All Movements</Typography>
        </Button>
        <ToggleButtonGroup
          color="primary"
          fullWidth
          value={String(showInactive)}
        >
          <ToggleButton
            color="primary"
            value="true"
            onClick={() => setShowInactive((showInactive) => !showInactive)}
            sx={(theme) => ({
              color: theme.palette.primary.main,
              borderColor: darken(theme.palette.primary.main, 0.4),
              "&:hover": {
                borderColor: theme.palette.primary.main
              },
            })}
          >
            <Typography noWrap>
              {showInactive ? "Hide" : "Show"} Inactive{" "}
              {`( ${hiddenDeliverables?.length} )`}
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {userCanEditContract() && currentContract.contractType !== "Sale" && (
        <Box sx={{ mx: 2 }} display="flex" justifyContent="space-between">
          <FormControlLabel
            control={
              <Checkbox
                checked={sameBillingStart}
                onClick={() => changeSameBillingStart(!sameBillingStart)}
              />
            }
            label="Same Billing Start"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sameBillingEnd}
                onClick={() => changeSameBillingEnd(!sameBillingEnd)}
              />
            }
            label="Same Billing End"
          />
        </Box>
      )}
        <LoadingComponent isLoading={contractAssetsLoading || (isFetching && !currentData)} sx={{display: "flex", flexDirection: "column", gap: 1}}>
          {visibleDeliverables?.map((asset, index) => (
            <ContractAsset
              key={asset.asset}
              assetDeliverable={asset}
              sameBillingStart={sameBillingStart}
              sameBillingEnd={sameBillingEnd}
              index={index}
              setAddAssetMode={setAddAssetMode}
              setNewMovements={setNewMovements}
              changeIsSubmitting={changeIsSubmitting}
              asset={assetMap[asset.asset]}
            />
          ))}
          {showInactive &&
            hiddenDeliverables?.map((asset, index) => (
              <ContractAsset
                key={asset.asset}
                assetDeliverable={asset}
                sameBillingStart={sameBillingStart}
                sameBillingEnd={sameBillingEnd}
                index={index}
                setAddAssetMode={setAddAssetMode}
                setNewMovements={setNewMovements}
                changeIsSubmitting={changeIsSubmitting}
                asset={assetMap[asset.asset]}
              />
            ))}
          <Card
            sx={(theme) => ({ border: `dashed 1px ${theme.palette.divider}` })}
          >
            <Button
              size="large"
              fullWidth
              startIcon={<AddCircleOutline />}
              disabled={!userCanEditContract() || contractIsInactive}
              onClick={handleAddAsset}
            >
              Add Asset
            </Button>
          </Card>
        </LoadingComponent>
      {Boolean(newMovements.length) && (
        <BulkMovementCreate
          currentContract={currentContract}
          open={Boolean(newMovements.length)}
          onClose={() => setNewMovements([])}
          maxWidth="xl"
          initialMovements={newMovements}
        />
      )}
    </Box>
  );
};
export default ContractAssets;
