import { MenuProps } from '@mui/material';

export { default as ConditionSelect } from './ConditionSelect';
export { default as PrimitiveSelect } from './PrimitiveSelect';

 
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const FormMenuStyles: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: `${(ITEM_HEIGHT * 6.5) + ITEM_PADDING_TOP}px`,
    },
    sx: { boxShadow: 3 }
  },
  disableScrollLock: true,
};