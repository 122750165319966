import React, { ReactNode, FC, useState, ChangeEvent } from "react";
import { useFormikContext, getIn, FastFieldProps, Field, FastField } from "formik";
import Movement, {
  MovementStatus,
  MovementType,
} from "store/models/Movement";
import { ReactComponent as BillOfLadingIcon } from "svg/tasks.svg";
import {
  Card,
  Checkbox,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectCurrentMovementId,
  selectMovementContractId,
  setBillOfLadingMovementId,
  setCurrentBillOfLadingId,
} from "store/slices/contractSlice";
import { isValid } from "date-fns/fp";
import { setGlobalMessage, setModalMode } from "store/slices/systemSlice";
import usePermissions, {
  FeatureType,
  ActionType,
  UIActionType,
} from "hooks/usePermissions";
import DateSelector from "components/DateSelector";
import { dateFromMMDDYYYY, toMMDDYYYY } from "utils/util";
import { useAppDispatch } from 'store/store';
import AssetAutocomplete from "components/autocomplete/AssetAutoComplete";
import { Asset } from "store/models/Asset";
import AccessoryAutoComplete from "components/autocomplete/AccessoryAutoComplete";
import { BranchAutoComplete } from "components/autocomplete";
import { Branch } from "store/models/Branch";
import CurrencyTextField from "components/CurrencyTextField";
import { initCurrency } from "types/Currency";
import { useGetMovementByIdQuery } from "store/services/movement";
import { thirtySeconds } from "utils/timeValues";
import TransportCompanyAutoComplete from "components/autocomplete/TransportCompanyAutoComplete";
import { TransportCompany } from "store/slices/transportCompanySlice";
import { useGetAllAssetsQuery } from "store/services/asset";
import { byIds } from "store/sliceUtils";
import { useGetBranchesQuery } from "store/services/branches";
import useLoggedInUser from "hooks/useLoggedInUser";

interface MovementRowType {
  label: string;
  input: ReactNode;
}

const MovementRow: FC<MovementRowType> = ({ label, input }) => {
  return (
    <Grid container alignItems="center" my={2}>
      <Grid item xs={5}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={7} textAlign="right">
        {input}
      </Grid>
    </Grid>
  );
};

interface Props {
  isForNonContractMovement?: boolean;
  isNewMovement?: boolean
}

const MovementForm: React.FC<Props> = ({
  isForNonContractMovement,
  isNewMovement = false,
}) => {
  const {data: assets = []} = useGetAllAssetsQuery();
  const assetsHash = byIds(assets);
  const {data: branches = []} = useGetBranchesQuery();
  const branchesHash = byIds(branches);
  const currentContractId = useSelector(selectMovementContractId)
  const currentMovementId = useSelector(selectCurrentMovementId);
  const {
    data: movement,
  } = useGetMovementByIdQuery(
    {
      movementId: currentMovementId || "",
      contractId: currentContractId || "",
    },
    { skip: !currentMovementId, pollingInterval: thirtySeconds }
  );
  const [movementType, setMovementType] = useState<"asset" | "nonAsset">(
    movement?.asset ? "asset" : "nonAsset"
  );
  const dispatch = useAppDispatch();
  const checkBillOfLadingPermissions = usePermissions(
    FeatureType.BILL_OF_LADING
  );
  const { loggedInUser, userDefaultCurrency } = useLoggedInUser()
  const { setValues, values, setFieldValue, handleChange, touched, errors } =
    useFormikContext<Movement>();

  function isEditing() {
    return Boolean(movement?.billOfLading);
  }

  const handleBillOfLadingClick = () => {
    dispatch(setBillOfLadingMovementId(movement?._id));
    dispatch(setCurrentBillOfLadingId(movement?.billOfLading));

    if (isEditing()) {
      const userCanEditBOL = checkBillOfLadingPermissions(
        ActionType.UPDATE,
        UIActionType.ONCLICK
      );
      if (!userCanEditBOL) return;
      dispatch(setModalMode("billOfLadingViewEdit"));
    } else {
      const userCanCreateBOL = checkBillOfLadingPermissions(
        ActionType.CREATE,
        UIActionType.ONCLICK
      );
      if (!userCanCreateBOL) return;
      dispatch(setModalMode("billOfLadingCreate"));
    }
  };

  const handleMovementStatusChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    newStatus: any
  ) => {
    if (!isNewMovement) {
      dispatch(
        setGlobalMessage({
          show: true,
          severity: "warning",
          messageText:
            "You cannot change the status of a movement in edit mode",
        })
      );
      return;
    }

    newStatus && setFieldValue("status", newStatus);
  };

  const handleMovementTypeChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    newType: any
  ) => {
    if (!isNewMovement) {
      dispatch(
        setGlobalMessage({
          show: true,
          severity: "warning",
          messageText:
            "You cannot change the type of an existing contract movement. Delete this movement in the contracts module and create a new one.",
        })
      );
      return;
    }

    newType && setFieldValue("type", newType);
  };

  const handleMovementTentativeChange = (
    _: ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    setFieldValue("tentative", newValue);
  };

  const handleMovementLeaseTakeoverChange = (
    _: ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    setFieldValue("leaseTakeover", newValue);
  };


  return (
    <Card sx={{ p: 3 }}>
      <ToggleButtonGroup
        sx={{ mt: 1, mb: 1 }}
        fullWidth
        value={movementType}
        exclusive
        color="primary"
        disabled={Boolean(movement)}
        onChange={(_, newType) => {
          newType && setMovementType(newType);
          if (newType === "nonAsset") {
            setFieldValue("nonAsset.branch", loggedInUser?.branches[0]);
            setFieldValue("nonAsset.description", "");
          } else {
            setFieldValue("nonAsset", null);
            setFieldValue("asset", "");
          }
        }}
      >
        <ToggleButton value="asset">Asset</ToggleButton>
        <ToggleButton value="nonAsset">Non-Asset</ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        sx={{ mt: 1 }}
        fullWidth
        value={values.status}
        exclusive
        color="primary"
        onChange={handleMovementStatusChange}
      >
        {Object.values(MovementStatus).map((status) => {
          return (
            <ToggleButton
              key={status}
              value={status}
              data-testid={`status-${status}`}
              disabled={["COMPLETED", "CANCELLED"].includes(status)}
            >
              {status}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      {isForNonContractMovement && movementType === "asset" && (
        <MovementRow
          label="Asset"
          input={
            <AssetAutocomplete
              value={values.asset ? assetsHash[values.asset] : null}
              onChange={(_, asset) =>
                setFieldValue("asset", (asset as Asset)._id)
              }
              name="asset"
              size="small"
              error={Boolean(touched.asset && errors.asset)}
            />
          }
        />
      )}

      {movementType === "nonAsset" && !movement?.accessory && (
        <>
          <MovementRow
            label="Non-Asset"
            input={
              <AccessoryAutoComplete
                fullWidth
                value={values?.nonAsset?.description}
                onChange={(_, newDescription) =>
                  setFieldValue("nonAsset.description", newDescription)
                }
                name="nonAsset.description"
                size="small"
                error={Boolean(
                  getIn(touched, "nonAsset.description") &&
                    getIn(errors, "nonAsset.description")
                )}
              />
            }
          />
          <MovementRow
            label="Branch"
            input={
              <BranchAutoComplete
                value={
                  (values.nonAsset && branchesHash[values.nonAsset.branch]) || null
                }
                onChange={(_, value) =>
                  setFieldValue(
                    "nonAsset.branch",
                    (value as Branch)?._id || null
                  )
                }
                name="nonAsset.branch"
                size="small"
                error={Boolean(
                  getIn(touched, "nonAsset.branch") &&
                    getIn(errors, "nonAsset.branch")
                )}
              />
            }
          />
        </>
      )}

      <MovementRow
        label="Date of Movement"
        input={
          <DateSelector
            value={dateFromMMDDYYYY(values.dateOfMove)}
            onChange={(newValue: any) => {
              const date = newValue as unknown as Date;
              date &&
                isValid(new Date(date)) &&
                setValues({
                  ...values,
                  dateOfMove: toMMDDYYYY(date),
                });
            }}
            textFieldProps={{
              size: "small",
              fullWidth: true,
              name: "dateOfMove",
            }}
          />
        }
      />

      <MovementRow
        label="Tentative?"
        input={
          <Checkbox
            value={values.tentative}
            checked={values.tentative}
            onChange={handleMovementTentativeChange}
          />
        }
      />

      <MovementRow
        label="Lease Takeover?"
        input={
          <Checkbox
            value={values.leaseTakeover}
            checked={values.leaseTakeover || false}
            onChange={handleMovementLeaseTakeoverChange}
          />
        }
      />

      <MovementRow
        label="Type"
        input={
          <ToggleButtonGroup
            size="small"
            value={values.type}
            exclusive
            fullWidth
            onChange={handleMovementTypeChange}
          >
            {Object.values(MovementType).map((type) => {
              return (
                <ToggleButton
                  key={type}
                  value={type}
                  data-testid={`type-${type}`}
                >
                  {type}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        }
      />

      <MovementRow
        label="Transport Company"
        input={
          <TransportCompanyAutoComplete
            size="small"
            fullWidth
            name="transportCompanyName"
            value={values.transportCompanyName}
            onChange={(_, value) => {
              setFieldValue(
                "transportCompanyName",
                (value as TransportCompany)?.name || ""
              );
              setFieldValue(
                "transportCompany",
                (value as TransportCompany)?._id || null
              );
            }}
          />
        }
      />

      <MovementRow
        label="Transport Invoice #"
        input={
          <TextField
            size="small"
            fullWidth
            name="transportInvoiceNumber"
            value={values.transportInvoiceNumber}
            onChange={handleChange}
            variant="outlined"
            inputProps={{
              "data-testid": "transportInvoiceNumber",
            }}
          />
        }
      />

      <MovementRow
        label="Batch #"
        input={
          <TextField
            size="small"
            fullWidth
            name="batch"
            value={values.batch}
            onChange={handleChange}
            variant="outlined"
            inputProps={{
              "data-testid": "batch",
            }}
          />
        }
      />

      <MovementRow
        label="Bill of Lading"
        input={
          <IconButton
            onClick={handleBillOfLadingClick}
            disabled={!movement?.billOfLading}
            color="primary"
          >
            <SvgIcon fontSize="small">
              <BillOfLadingIcon />
            </SvgIcon>
          </IconButton>
        }
      />
      <MovementRow
        label="PO Number"
        input={
          <FastField name="purchaseOrder.poNumber">
            {({ field, form }: FastFieldProps) => (
              <TextField
                {...field}
                value={values.purchaseOrder?.poNumber || ""}
                size="small"
                autoComplete="off"
                fullWidth
                onChange={(event) => {
                  form.setFieldValue(
                    "purchaseOrder.poNumber",
                    event.target.value
                  );
                }}
                error={Boolean(
                  getIn(touched, "purchaseOrder.poNumber") &&
                    getIn(errors, "purchaseOrder.poNumber")
                )}
                inputProps={{
                  "data-testid": "purchaseOrder.poNumber",
                }}
              />
            )}
          </FastField>
        }
      />
      <MovementRow
        label="PO Amount"
        input={
          <Field name="purchaseOrder.amount">
            {({ field, form }: FastFieldProps) => (
              <CurrencyTextField
                {...field}
                fullWidth
                value={
                  values.purchaseOrder?.amount || {
                    amount: 0,
                    currency: userDefaultCurrency,
                  }
                }
                onChange={(event) => {
                  form.setFieldValue(
                    "purchaseOrder.amount.amount",
                    Number(event.target.value) || undefined
                  );
                }}
                onCurrencyChange={(event) => {
                  event.target.value &&
                    form.setFieldValue(
                      "purchaseOrder.amount.currency",
                      event.target.value
                    );
                }}
                error={Boolean(
                  getIn(touched, "purchaseOrder.amount") &&
                    getIn(errors, "purchaseOrder.amount")
                )}
                inputProps={{
                  "data-testid": "purchaseOrder.amount",
                }}
                size="small"
              />
            )}
          </Field>
        }
      />

      <MovementRow
        label="Bid Cost"
        input={
          <Field name="bidCost">
            {({ field, form }: FastFieldProps) => (
              <CurrencyTextField
                {...field}
                fullWidth
                value={
                  field.value ||
                  initCurrency({
                    currency: userDefaultCurrency,
                  })
                }
                onChange={(event) => {
                  const { value } = event.target;
                  form.setFieldValue(
                    "bidCost.amount",
                    !value && !isNaN(Number(value)) ? null : Number(value)
                  );
                }}
                onCurrencyChange={(event) => {
                  event.target.value &&
                    form.setFieldValue("bidCost.currency", event.target.value);
                }}
                error={Boolean(touched.bidCost && errors.bidCost)}
                size="small"
              />
            )}
          </Field>
        }
      />
      <MovementRow
        label="Actual Cost"
        input={
          <Field name="actualCost">
            {({ field, form }: FastFieldProps) => (
              <CurrencyTextField
                {...field}
                fullWidth
                value={
                  field.value ||
                  initCurrency({
                    currency: userDefaultCurrency,
                  })
                }
                onChange={(event) => {
                  const { value } = event.target;
                  form.setFieldValue(
                    "actualCost.amount",
                    !value && !isNaN(Number(value)) ? null : Number(value)
                  );
                }}
                onCurrencyChange={(event) => {
                  event.target.value &&
                    form.setFieldValue(
                      "actualCost.currency",
                      event.target.value
                    );
                }}
                error={Boolean(touched.actualCost && errors.actualCost)}
                size="small"
              />
            )}
          </Field>
        }
      />
      <Typography>Notes:</Typography>
      <TextField
        name="notes"
        size="small"
        value={values.notes}
        onChange={handleChange}
        fullWidth
        multiline
        minRows={4}
      />
    </Card>
  );
};

export default MovementForm;
