import { onAuthStateChanged, getAuth, OAuthProvider, signInWithPopup } from "firebase/auth"
import useLoggedInUser from "hooks/useLoggedInUser";
import { useEffect, useState } from "react";
import { useLoginUserMutation } from "store/services/user";
import {
  setGlobalMessage,
  setLoggedInUserId,
  simpleGlobalMessage,
} from "store/slices/systemSlice";
import { useAppDispatch } from 'store/store';

const useAuthController = () => {

  const { loggedInUser } = useLoggedInUser()
  const dispatch = useAppDispatch();

  const [reconnectCount, setReconnectCount] = useState(0);
  const [initAuthLoading, setInitAuthLoading] = useState(true);
  const [loginUser] = useLoginUserMutation()

  const silentLogin = async () => {

    const idToken = await getAuth().currentUser?.getIdToken();

    loginUser({ idToken: String(idToken), lastLoggedIn: new Date() })
    .unwrap()
    .then((user) => {
      dispatch(setLoggedInUserId(user._id));
      setInitAuthLoading(false)
    })
    .catch(() => {
      dispatch(simpleGlobalMessage("Failed to login", "error"));
      setInitAuthLoading(false)
    });
  }

  const handleLogin = async (e?: React.FormEvent) => {
    e?.preventDefault();
    const auth = getAuth();
    const provider = new OAuthProvider("microsoft.com").setCustomParameters({
      tenant: "atco.com",
    });

    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        if (!credential?.accessToken) return;

        const idToken = await getAuth().currentUser?.getIdToken();

        loginUser({ idToken: String(idToken), lastLoggedIn: new Date() })
          .unwrap()
          .then((user) => {
            dispatch(setLoggedInUserId(user._id));
            setInitAuthLoading(false);
          });
      })
      .catch(() => {
        dispatch(simpleGlobalMessage("Failed to login", "error"));
        setInitAuthLoading(false);
      });
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout; 

    if(reconnectCount === 3) {
      dispatch(
        setGlobalMessage({
          messageText: "Could Not Connect To Server",
          show: true,
          severity: "error",
          duration: 2000,
        })
      );
    }
  
    const auth = getAuth();
    const unsubscribeFromAuthChanges = onAuthStateChanged(auth, (user) => {
      timeout = setTimeout(() => {
        if(!loggedInUser){
          setReconnectCount(count => count + 1)
        }
      }, 5000)

      function autoLogin() {
        if (user?.email && !loggedInUser) {
          silentLogin()
        }
      }

      if (user && reconnectCount < 3) {
        autoLogin();
      } else if (!loggedInUser) {
        setInitAuthLoading(false)
        dispatch(setLoggedInUserId(undefined));
      }
    });
    
    return () => {
      clearTimeout(timeout)
      unsubscribeFromAuthChanges()
    };
  }, [reconnectCount]);

  return { initAuthLoading, silentLogin, handleLogin };
};
export default useAuthController;
