import {
  GridFilterModel,
  GridControlledStateReasonLookup,
  GridApiPro,
} from "@mui/x-data-grid-pro";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateGridFilterFavoritesMutation,
  useDeleteDataGridFilterFavoriteMutation,
  useGetDataGridFilterFavoritesByQuery,
} from "store/services/dataGridFilterFavorite";
import {
  selectLoggedInUserId,
  setGlobalMessage,
} from "store/slices/systemSlice";

interface Props {
  tableId: string;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  setFilterModel: (
    model: GridFilterModel,
    reason?: GridControlledStateReasonLookup["filter"]
  ) => void;
  setCreateDialogOpen: Dispatch<SetStateAction<boolean>>;
  favouriteName: string;
  setFavoriteName: Dispatch<SetStateAction<string>>;
  apiRefToUse: MutableRefObject<GridApiPro>;
}

const useFilterFavorites = ({
  tableId,
  setAnchorEl,
  setFilterModel,
  setCreateDialogOpen,
  favouriteName,
  setFavoriteName,
  apiRefToUse
}: Props) => {
  const loggedInUserId = useSelector(selectLoggedInUserId);
  const { data: userFavoriteFilters = {} } =
    useGetDataGridFilterFavoritesByQuery({ user: loggedInUserId!, tableId });
  const dispatch = useDispatch();
  const [deleteFilterFavorite] = useDeleteDataGridFilterFavoriteMutation();
  const [createFilterFavorite] = useCreateGridFilterFavoritesMutation();
  const outsideFilterModel = apiRefToUse.current.state?.filter?.filterModel

  const handleFavoritesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFavoriteName("")
  };

  function handleFavoriteFilterClick(filterName: string) {
    filterActive(filterName)
      ? setFilterModel({ items: [] })
      : setFilterModel(userFavoriteFilters[filterName].filterModel);
  }

  function filterActive(filterName: string) {
    return (
      JSON.stringify(outsideFilterModel) ===
      JSON.stringify(userFavoriteFilters[filterName].filterModel)
    );
  }

  function handleFavoriteFilterDelete(filterName: string) {
    try {
      deleteFilterFavorite(userFavoriteFilters[filterName]).unwrap();
    } catch (error) {
      dispatch(
        setGlobalMessage({
          messageText: "Error deleting filter favorite",
          show: true,
          severity: "error",
        })
      );
    }
  }

  function favoriteFilterMaxReached() {
    return Object.values(userFavoriteFilters).length >= 10;
  }

  async function handleFavoriteFilterCreate() {
    if (favoriteFilterMaxReached()) {
      dispatch(
        setGlobalMessage({
          show: true,
          messageText: "You can only have a max of 10 favorite filters",
          severity: "warning",
        })
      );
      return;
    }

    try {
      await createFilterFavorite({
        filterModel: outsideFilterModel,
        filterName: favouriteName,
        tableId,
        user: loggedInUserId!,
      }).unwrap();
    } catch (error) {
      dispatch(
        setGlobalMessage({
          messageText: "Error creating filter favorite",
          show: true,
          severity: "error",
        })
      );
    }
    setCreateDialogOpen(false);
    setFavoriteName("")
  }

  return {
    handleFavoritesClick,
    handleClose,
    handleFavoriteFilterClick,
    handleFavoriteFilterDelete,
    handleFavoriteFilterCreate,
    userFavoriteFilters,
    filterActive,
    favoriteFilterMaxReached: favoriteFilterMaxReached()
  };
};

export default useFilterFavorites;
