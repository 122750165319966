import React, { FC } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { titleCase } from "utils/util";
import { AssetCardData, useGetAssetAutoCompleteOptionsQuery } from "store/services/asset";
import useDebounce from "hooks/useDebounce";

type Props = Omit<
  AutocompleteProps<AssetCardData, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  textFieldProps?: TextFieldProps;
  name?: string;
  error?: boolean;
  "data-testId"?: string;
  options?: AutocompleteProps<AssetCardData, boolean, boolean, boolean>["options"]
  useAssetNumber?: boolean
  limitResultsToContractId?: string
};

const AssetAutocomplete: FC<Props> = ({ value, name, error, textFieldProps, options: limitedOptions, limitResultsToContractId, useAssetNumber = false, ...rest }) => {
  const [searchValue, setSearchValue] = React.useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const OPTIONS_LIMIT = 25;
  const { currentData: assets = [], isFetching } =
    useGetAssetAutoCompleteOptionsQuery(
      {
        limit: OPTIONS_LIMIT,
        searchValue: debouncedSearchValue,
        limitResultsToContractId,
      },
      { skip: !searchValue && !limitResultsToContractId }
    );

  const options = limitedOptions || Object.values(assets);

  const filterOptions = createFilterOptions<AssetCardData>({
    stringify: (asset) => {
      return typeof asset === "string"
        ? asset
        : `${
            useAssetNumber &&
            asset.assetNumber &&
            asset.assetNumber !== "None given"
              ? asset.assetNumber
              : asset.serialNumber
          } - ${titleCase(asset.sizeCode)} - ${titleCase(asset.category)}`;
    },
    limit: OPTIONS_LIMIT,
  });

  return (
    <Autocomplete
      options={options}
      {...rest}
      value={value || null}
      noOptionsText={searchValue ? "No matching assets found" : "Start typing to search"}
      getOptionLabel={(asset) =>
        typeof asset === "string"
          ? asset
          : `${
              useAssetNumber && asset.assetNumber && asset.assetNumber !== 'None given'
                ? asset.assetNumber
                : asset.serialNumber
            } - ${titleCase(asset.sizeCode)} - ${titleCase(asset.category)}`
      }
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      clearOnBlur={false}
      loading={isFetching}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          data-testid={rest["data-testId"]}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          error={error}
          margin="none"
          autoComplete="off"
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password",
            form: { autocomplete: "off" },
          }}
          {...textFieldProps}
        />
      )}
    />
  );
}

export default AssetAutocomplete;