import { FastField, FastFieldProps, FieldArray, FormikProps } from "formik";
import FastCheckbox from "components/formik/FastCheckbox";
import FastNumberInput from "components/formik/FastNumberInput";
import FastTextInput from "components/formik/FastTextInput";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BillOfLading, blankAdditionalItem } from "store/models/BillOfLading";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";
import NumberTextField from "components/NumberTextField";
import CurrencyTextField from "components/CurrencyTextField";
import { initCurrency } from "types/Currency";
import { setGlobalMessage } from "store/slices/systemSlice";
import UserData from "store/models/UserData";
import Iconify from "components/iconify/iconify"
import FastFtInchInputs from "components/formik/FastFtInchInputs";
import { useGetAssetByIdQuery } from "store/services/asset";
import useLoggedInUser from "hooks/useLoggedInUser";

interface Props {
  assetArticles: BillOfLading["assetArticles"];
  additionalItems: BillOfLading["additionalItems"];
  formik: FormikProps<BillOfLading>
}

const NotesPopover = ({ name, highlight }: { name: string, highlight: boolean }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} color={highlight ? "primary" : "default"}>
        <Iconify icon="mdi:notes-outline" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{padding: 1}}>
          <FastTextInput
            name={name}
            label="Notes"
            leaveSpaceForHelperText={false}
          />
        </Box>
      </Popover>
    </>
  );
};
const BillOfLadingArticles: React.FC<Props> = ({
  assetArticles,
  additionalItems,
  formik
}) => {
  // BOL can only contain a single asset. IF this changes this query will need to be modified
  const {data: asset } = useGetAssetByIdQuery(assetArticles[0]?.asset, {skip: !assetArticles[0]?.asset});
  const { loggedInUser, userDefaultCurrency } = useLoggedInUser()
  const dispatch = useDispatch();
  
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "50px minmax(400px, auto) 45px 140px 140px 140px 140px 240px 30px",
        rowGap: 3,
        justifyItems: "center",
        py: 5,
        columnGap: 3,
        alignItems: "center",
      }}
    >
      <Typography variant="caption">Quantity</Typography>
      <Typography variant="caption">Item Description</Typography>
      <Typography variant="caption">HM &quot;R&quot;</Typography>
      <Typography variant="caption">Aprx. Width</Typography>
      <Typography variant="caption">Aprx. Length</Typography>
      <Typography variant="caption">Aprx. Height</Typography>
      <Typography variant="caption">Aprx. Weight (lbs)</Typography>
      <Typography variant="caption">Amount ($)</Typography>
      <Box />

      <FieldArray
        name="assetArticles"
        render={() => {
          return assetArticles.map(({ attributes: {notes} }, index) => (
            <Fragment key={index}>
              <NumberTextField
                value={1}
                fullWidth
                variant="outlined"
                size="small"
                disabled
                helperText=""
              />
              <Stack direction="row" columnGap={2} sx={{ width: "100%" }}>
                <TextField
                  value={`${
                    asset?.serialNumber || "No Serial"
                  } - ${asset?.category || ""} - ${
                    asset?.subCategory || ""
                  } - ${asset?.floorStyle || ""}`}
                  fullWidth
                  label="Asset"
                  variant="outlined"
                  size="small"
                  disabled
                />
                <NotesPopover name={`assetArticles.${index}.attributes.notes`} highlight={Boolean(notes)} />
              </Stack>
              <FastCheckbox name={`assetArticles.${index}.attributes.hazardous`} />
              <FastFtInchInputs
                name={`assetArticles.${index}.attributes.width`}
              />
              <FastFtInchInputs
                name={`assetArticles.${index}.attributes.length`}
              />
              <FastFtInchInputs
                name={`assetArticles.${index}.attributes.height`}
              />
              <FastNumberInput
                name={`assetArticles.${index}.attributes.weight`}
              />
              <FastField name={`assetArticles.${index}.attributes.amount`}>
                {({ field, form }: FastFieldProps) => (
                  <CurrencyTextField
                    {...field}
                    value={
                      field.value || initCurrency({ currency: userDefaultCurrency })
                    }
                    onChange={(event) => {
                      form.setFieldValue(
                        `assetArticles.${index}.attributes.amount`,
                        { ...field.value, amount: Number(event.target.value) }
                      );
                    }}
                    onCurrencyChange={(event) => {
                      event.target.value &&
                        form.setFieldValue(
                          `assetArticles.${index}.attributes.amount`,
                          { ...field.value, currency: event.target.value }
                        );
                    }}
                    size="small"
                    fullWidth
                  />
                )}
              </FastField>
              <Box />
            </Fragment>
          ));
        }}
      />

      <FieldArray
        name="additionalItems"
        render={(helpers) => (
          <>
            {additionalItems.map((item, index) => (
              <Fragment key={index}>
                <FastNumberInput name={`additionalItems.${index}.quantity`} />
                <Stack direction="row" columnGap={2} sx={{ width: "100%" }}>
                  <FastTextInput
                    name={`additionalItems.${index}.description`}
                  />
                  <NotesPopover name={`additionalItems.${index}.notes`} highlight={Boolean(item.notes)} />
                </Stack>
                <FastCheckbox name={`additionalItems.${index}.hazardous`} />
                <FastFtInchInputs
                  name={`additionalItems.${index}.width`}
                />
                <FastFtInchInputs
                  name={`additionalItems.${index}.length`}
                />
                <FastFtInchInputs
                  name={`additionalItems.${index}.height`}
                />
                <FastNumberInput
                  name={`additionalItems.${index}.weight`}
                />
                <FastField name={`additionalItems.${index}.amount`}>
                  {({ field, form }: FastFieldProps) => (
                    <CurrencyTextField
                      {...field}
                      value={
                        field.value ||
                        initCurrency({ currency: userDefaultCurrency })
                      }
                      onChange={(event) => {
                        form.setFieldValue(`additionalItems.${index}.amount`, {
                          ...field.value,
                          amount: Number(event.target.value),
                        });
                      }}
                      onCurrencyChange={(event) => {
                        event.target.value &&
                          form.setFieldValue(
                            `additionalItems.${index}.amount`,
                            { ...field.value, currency: event.target.value }
                          );
                      }}
                      size="small"
                      fullWidth
                    />
                  )}
                </FastField>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => helpers.remove(index)}
                >
                  <Cancel />
                </IconButton>
              </Fragment>
            ))}
            <Button
              className="addItem"
              size="small"
              variant="outlined"
              sx={{
                gridColumn: "1 / 9",
                justifySelf: "flex-start",
              }}
              color="primary"
              onClick={() => {
                if (additionalItems.length >= 6) {
                  dispatch(
                    setGlobalMessage({
                      messageText:
                        "You already have the maximum number of items on this Bill of Lading",
                      severity: "error",
                      show: true,
                    })
                  );
                } else {
                  helpers.push(blankAdditionalItem());
                }
              }}
            >
              Add
            </Button>
          </>
        )}
      />
    </Box>
  );
};
export default BillOfLadingArticles;
