import { IChat, IMessage } from "store/models/Chat";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { oneMinute } from "utils/timeValues";

export const chatApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getChat: builder.query<IChat, string>({
      query: (chatId) => `chat?chatId=${chatId}`,
      transformResponse: getResponseBody,
      providesTags: (result, err, id) => [{ type: "Chat", id }],
      keepUnusedDataFor: oneMinute * 60,
    }),
    updateChat: builder.mutation<IMessage, {documentId: string, message: IMessage, schema: "workOrder", chatId: string}>({
      query: (body) => ({
        url: `chat`,
        method: "PATCH",
        body: {
          documentId: body.documentId,
          schema: body.schema,
          message: body.message,
        },
      }),
      transformResponse: getResponseBody,
      invalidatesTags: (result, err, {chatId}) => [{ type: "Chat", id: chatId }],
      extraOptions: {maxRetries: 0},
    }),
  }),
});

export const { useGetChatQuery, useUpdateChatMutation } = chatApi;
