import { Attachment } from "store/models/Attachment";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";

export const attachmentsApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAttachments: builder.query<
      Attachment[],
      { documentId: string; documentType: string }
    >({
      query: ({ documentId, documentType }) =>
        `/attachment/byContractId?documentId=${documentId}&documentType=${documentType}`,
      transformResponse: getResponseBody,
      providesTags: (result, err, {documentId}) => [{type: "Attachments", id: documentId}], 
    }),
    downloadAttachment: builder.query<
      string,
      { documentId: string; documentType: string; attachmentId: string }
    >({
      query: ({ documentId, documentType, attachmentId }) =>
        `/attachment?attachmentId=${attachmentId}&documentId=${documentId}&documentType=${documentType}`,
      transformResponse: getResponseBody,
    }),
    createAttachment: builder.mutation<
      undefined,
      { documentId: string; documentType: string; form: FormData }
    >({
      query: ({ documentId, documentType, form }) => ({
        url: `/attachment/?documentId=${documentId}&documentType=${documentType}`,
        method: "POST",
        body: form,
      }),
      transformResponse: getResponseBody,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: (result, error, { documentId }) => [{ type: "Attachments", id: documentId }],
    }),
    deleteAttachment: builder.mutation<
      undefined,
      { documentId: string; documentType: string; attachmentId: string }
    >({
      query: ({ documentId, documentType, attachmentId }) => ({
        url: `/attachment/?documentId=${documentId}&attachmentId=${attachmentId}&documentType=${documentType}`,
        method: "DELETE",
      }),
      transformResponse: getResponseBody,
      extraOptions: { maxRetries: 0 },
      invalidatesTags: (result, error, { documentId }) => [{ type: "Attachments", id: documentId }],
    }),
  }),
});

export const {
  useCreateAttachmentMutation,
  useDeleteAttachmentMutation,
  useGetAttachmentsQuery,
  useLazyDownloadAttachmentQuery
} = attachmentsApi;
