import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { ClientContact } from "store/models/ClientContact";
import { getQueryStringFromObject } from "store/api/util";
import { Client } from "store/models/Client";

export type ClientContractWithPopulatedClient = ClientContact & {populatedClient: Client};

export const clientContactApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getClientContactByQuery: builder.query<ClientContact[],Partial<Record<keyof ClientContact, string | number>>>({
      query: (query) => ({url: `/clientContact/query`, method: 'POST', body: query}),
      transformResponse: getResponseBody,
      providesTags: [{type: 'ClientContact', id: "QUERY"}],
    }),
    getAllClientContacts: builder.query<ClientContractWithPopulatedClient[],void>({
      query: () => ({url: `/clientContact/`, method: 'GET'}),
      transformResponse: getResponseBody,
      providesTags: [{type: 'ClientContact', id: "LIST"}],
    }),
    searchClientContacts: builder.query<ClientContractWithPopulatedClient[],{search: string, clientId?: string}>({
      query: (query) => ({url: `clientContact/search/?${getQueryStringFromObject(query)}`, method: 'GET'}),
      transformResponse: getResponseBody,
      providesTags: [{type: 'ClientContact', id: "SEARCH"}],
    }),
    createClientContact: builder.mutation<ClientContact,ClientContact>({
      query: (body) => ({url: `/clientContact/`, method: 'POST', body}),
      transformResponse: getResponseBody,
      invalidatesTags: [{type: 'ClientContact', id: "LIST"}, {type: 'ClientContact', id: "QUERY"}, {type: 'ClientContact', id: "SEARCH"}],
      extraOptions: { maxRetries: 0 },
    }),
    updateClientContact: builder.mutation<ClientContact,ClientContact>({
      query: (body) => ({url: `/clientContact/`, method: 'PATCH', body}),
      transformResponse: getResponseBody,
      invalidatesTags: [{type: 'ClientContact', id: "LIST"}, {type: 'ClientContact', id: "QUERY"}, {type: 'ClientContact', id: "SEARCH"}],
      extraOptions: { maxRetries: 0 },
    }),
    getClientContactById: builder.query<ClientContact, string>({
      query: (id) => ({url: `/clientContact/${id}`, method: 'GET'}),
      transformResponse: getResponseBody,
    }),

  })
});


export const {
  useGetClientContactByQueryQuery,
  useGetAllClientContactsQuery,
  useSearchClientContactsQuery,
  useCreateClientContactMutation,
  useUpdateClientContactMutation,
  useGetClientContactByIdQuery
} = clientContactApi;
