import { AddAssetModalMode } from "components/modals/AddAssetModal";
import { FormikHelpers } from "formik";
import useLoggedInUser from "hooks/useLoggedInUser";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import _  from "lodash";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { moveableAssetStatuses } from "store/api/asset";
import IAccessory from "store/models/Accessory";
import { Asset } from "store/models/Asset";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import { activeContractStatuses, Contract } from "store/models/Contract";
import Movement, {
  AssetMovement,
  determineDefaultDateOfMove,
  initMovement,
  MovementStatus,
} from "store/models/Movement";
import UserData from "store/models/UserData";
import { useGetAssetsByContractIdQuery } from "store/services/asset";
import { useLazyGetClientByIdQuery } from "store/services/client";
import {
  contractAssetMoveableStatuses,
  initAccessory,
  useAddAccessoryMutation,
  useAddAssetMutation,
  useGetContractByIdQuery,
  useRemoveAssetMutation,
  useRevertContractStatusMutation,
  useSwapAssetMutation,
  useUpdateAccessoriesMutation,
  useUpdateAssetDeliverablesMutation,
  useUpdateContractMutation,
} from "store/services/contract";
import { selectCurrentContractId } from "store/slices/contractSlice";
import {
  setGlobalMessage,
  simpleGlobalMessage,
} from "store/slices/systemSlice";
import { byIds } from "store/sliceUtils";
import { useAppDispatch } from "store/store";
import {
  contractInEndedStatus,
  dateFromMMDDYYYY,
  determineDefaultMovementStatus,
} from "utils/util";

const useContractBladeUtils = () => {
  const currentContractId = useSelector(selectCurrentContractId);
  const { data: currentContract } = useGetContractByIdQuery(
    currentContractId || "",
    { skip: !currentContractId }
  );
  const dispatch = useAppDispatch();
  const { loggedInUser, userDefaultCurrency } = useLoggedInUser()
  const { data: assets = [], isLoading: assetsLoading } = useGetAssetsByContractIdQuery(currentContractId || "", {skip: !currentContractId});
  const [getClientById] = useLazyGetClientByIdQuery();
  const assetsHash = byIds(assets);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
  const checkMovementPermissions = usePermissions(FeatureType.MOVEMENT);
  const userCanCreateMovements = checkMovementPermissions(ActionType.CREATE);
  const [addAssetModalMode, setAddAssetModalMode] =
    useState<AddAssetModalMode>("hidden");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, changeIsSubmitting] = useState(false);
  const [swapAsset] = useSwapAssetMutation();
  const [addAsset] = useAddAssetMutation();
  const [removeAsset] = useRemoveAssetMutation();
  const [updateAssetDeliverablesMutation] =
    useUpdateAssetDeliverablesMutation();
  const [addAccessory] = useAddAccessoryMutation();
  const [updateContract] = useUpdateContractMutation();

  const [revertContractStatus] = useRevertContractStatusMutation();
  const [updateAccessories] = useUpdateAccessoriesMutation();

  const handleContractAssetSubmit = useCallback(
    (
      toggleAllRowsSelected: (set?: boolean | undefined) => void,
      selectedRows: Asset[],
      assetDeliverable?: AssetDeliverable | undefined
    ) => {
      if (!currentContract) return;
      const selectedAssetIds = selectedRows.map((row) => row._id);
      const assetsNotReady = selectedRows
        .filter((selectedAsset) => {
          return (
            selectedAsset.inServiceDate &&
            dateFromMMDDYYYY(selectedAsset.inServiceDate) >
              dateFromMMDDYYYY(currentContract?.startDate)
          );
        })
        .map((asset) => asset.serialNumber);

      if (assetsNotReady.length) {
        dispatch(
          setGlobalMessage({
            messageText: `The following Assets are In Service after Contract Start: \n${JSON.stringify(
              assetsNotReady
            )}`,
            show: true,
            severity: "warning",
          })
        );
        return;
      }

      if (selectedAssetIds.length > 50) {
        dispatch(
          setGlobalMessage({
            messageText: `You cannot add more than 50 assets at a time to a contract`,
            show: true,
            severity: "warning",
          })
        );
        return;
      }

      switch (addAssetModalMode) {
        case "add":
          changeIsSubmitting(true);
          if (contractHasAssetAlready()) {
            dispatch(
              simpleGlobalMessage(
                `The following Assets are Already on Contract:${getAssetsAlreadyOnContract()}`
              )
            );
            changeIsSubmitting(false);
            return;
          } else if (notAllAssetsAvailable()) {
            dispatch(
              simpleGlobalMessage(
                `The following Assets are Unavailable:${getUnavailableAssets()}`
              )
            );
            changeIsSubmitting(false);
            return;
          }
          addAsset({
            contractId: currentContract?._id,
            assetsToAdd: selectedAssetIds,
          })
            .unwrap()
            .then(() => {
              setAddAssetModalMode("hidden");
              toggleAllRowsSelected(false);
              changeIsSubmitting(false)
              dispatch(
                simpleGlobalMessage(
                  `Successfully Added Asset${
                    selectedRows.length > 1 ? "s" : ""
                  }`,
                  "success"
                )
              );
            })
            .catch((error) => {
              changeIsSubmitting(false);
              dispatch(
                simpleGlobalMessage(
                  error.data?.message || error.message || `Failed to ${addAssetModalMode} asset`,
                  "error"
                )
              );
            })
          break;
        case "swap":
          if (!currentContract?._id || !assetDeliverable) return;

          swapAsset({
            contractId: currentContract?._id,
            oldAssetId: assetDeliverable?.asset,
            newAssetId: selectedRows[0]._id,
          })
            .unwrap()
            .then(({ contract, newAsset, oldAsset }) => {
              setAddAssetModalMode("hidden");
              dispatch(
                simpleGlobalMessage("Successfully swapped asset", "success")
              );
              toggleAllRowsSelected(false);
              changeIsSubmitting(false);
            })
            .catch((error) => {
              changeIsSubmitting(false);
              dispatch(
                simpleGlobalMessage(
                  error.data?.message || error.message || `Failed to ${addAssetModalMode} asset`,
                  "error"
                )
              );
            })
      }

      function contractHasAssetAlready() {
        return currentContract?.assetDeliverables.some(({ asset }) => {
          return selectedAssetIds.includes(asset);
        });
      }

      function getAssetsAlreadyOnContract() {
        return JSON.stringify(
          _.uniq(
            selectedRows.reduce((unavailableAssets, { _id, serialNumber }) => {
              return selectedAssetIds.includes(_id)
                ? [...unavailableAssets, serialNumber]
                : unavailableAssets;
            }, [] as string[])
          )
        );
      }

      function notAllAssetsAvailable() {
        return selectedRows.some(({ status }) => status !== "AVAILABLE");
      }
      function getUnavailableAssets() {
        return JSON.stringify(
          _.uniq(
            selectedRows.reduce(
              (unavailableAssets, { status, serialNumber }) => {
                if (status !== "AVAILABLE") {
                  unavailableAssets.push(serialNumber);
                }
                return unavailableAssets;
              },
              [] as string[]
            )
          )
        );
      }
    },
    [currentContract, assets, addAssetModalMode]
  );

  const removeAssetFromContract = useCallback(
    (
      asset: Asset,
      setSubmitting?: FormikHelpers<AssetDeliverable>["setSubmitting"],
      setSelectedIndex?: Dispatch<SetStateAction<number | undefined>>
    ) => {
      if (!currentContract) return;
      const assetHasAccessories = currentContract?.accessories.some(
        (accessory) => accessory.asset === asset._id
      );
      if (assetHasAccessories) {
        dispatch(
          simpleGlobalMessage("Asset has Accessories associated with it")
        );
        return;
      }
      if (isLastAssetInContract()) {
        dispatch(
          simpleGlobalMessage(
            "There must always be at least one asset in a contract"
          )
        );
        return;
      }

      removeAsset({
        contractId: currentContract?._id,
        assetToRemoveId: asset._id,
      })
        .unwrap()
        .catch(() => {
          setSubmitting && setSubmitting(false);
        })
        .finally(() => {
          setSubmitting && setSubmitting(false);
          setSelectedIndex && setSelectedIndex(undefined);
        });

      function isLastAssetInContract() {
        return (
          currentContract && currentContract?.assetDeliverables?.length <= 1
        );
      }
    },
    [currentContract]
  );

  const updateAssetDeliverables = useCallback(
    (
      deliverables: AssetDeliverable[],
      setSubmitting?: FormikHelpers<AssetDeliverable>["setSubmitting"],
      setSelectedIndex?: Dispatch<SetStateAction<number | undefined>>
    ) => {
      if (!currentContract) return;

      updateAssetDeliverablesMutation({
        contractId: currentContract._id,
        assetDeliverables: deliverables,
      })
        .unwrap()
        .then((contract) => {})
        .catch(() => {
          setSubmitting && setSubmitting(false);
        })
        .finally(() => {
          setSubmitting && setSubmitting(false);
          setSelectedIndex && setSelectedIndex(undefined);
        });
    },
    [currentContract]
  );

  const addAccessoryToContract = useCallback(
    (accessoryName: string) => {
      const newAccessory = initAccessory({
        name: accessoryName,
        rate: {
          amount: 0,
          currency: userDefaultCurrency,
        },
      });
      if (!currentContract) return;
      changeIsSubmitting(true);
      addAccessory({ contractId: currentContract._id, accessory: newAccessory })
        .unwrap()
        .then(({ contract }) => {
          changeIsSubmitting(false);
        })
        .catch(() => {
          changeIsSubmitting(false);
        });
    },
    [currentContract]
  );

  const handleScheduleAllMovements = useCallback(
    (setNewMovements: Dispatch<SetStateAction<Movement[]>>) => {
      if (!currentContract || assetsLoading) return;
      if (hasPendingMovements()) {
        dispatch(
          simpleGlobalMessage(
            `The following assets have a pending Movement:${getAssetsWithPendingMovements()}`
          )
        );
        return;
      }
      if (!contractAssetMoveableStatuses.includes(currentContract.status)) {
        dispatch(
          simpleGlobalMessage(
            "Contracts must be PENDING DELIVERY or ACTIVE to schedule Movements"
          )
        );
        return;
      }
      if (!Boolean(userCanEditContract && userCanCreateMovements)) {
        dispatch(
          simpleGlobalMessage("You do not have permission to Create Movements")
        );
        return;
      }

      const defaultMovementStatus =
        determineDefaultMovementStatus(currentContract);

      const assetsToScheduleMovementsFor: AssetMovement[] =
        currentContract?.assetDeliverables
          .filter(
            (deliverable) =>
              deliverable.isActiveOnContract &&
              moveableAssetStatuses.includes(
                assetsHash[deliverable.asset].status
              )
          )
          .map(
            (deliverable) =>
              initMovement({
                asset: deliverable.asset,
                type: defaultMovementStatus,
                status: MovementStatus.scheduled,
                contract: currentContract?._id,
                createdBy: (loggedInUser as UserData)._id,
                dateCompleted: null,
                dateOfMove: determineDefaultDateOfMove(
                  defaultMovementStatus,
                  deliverable,
                  currentContract
                ),
              }) as AssetMovement
          );

      assetsToScheduleMovementsFor.length
        ? setNewMovements(assetsToScheduleMovementsFor)
        : dispatch(simpleGlobalMessage("No movements to schedule"));

      function hasPendingMovements() {
        const activeAssets = currentContract?.assetDeliverables
          .filter(onlyActiveAssets)
          .map(({ asset }) => asset);
        return currentContract?.movements
          .filter(onlyMovementsOfActiveAssets)
          .some((movement) =>
            [MovementStatus.scheduled, MovementStatus.dispatched].includes(
              movement.status
            )
          );
        function onlyActiveAssets(deliverable: AssetDeliverable<string>) {
          return deliverable.isActiveOnContract;
        }

        function onlyMovementsOfActiveAssets(movement: Movement) {
          return movement.asset && activeAssets?.includes(movement.asset);
        }
      }

      function getAssetsWithPendingMovements() {
        return JSON.stringify(
          _.uniq(
            currentContract?.movements
              .filter(hasPendingMovements)
              .filter((movement) => movement.asset)
              .reduce((acc, movement) => {
                const currentAsset = movement.asset
                  ? assetsHash[movement.asset]
                  : undefined;
                if (currentAsset) {
                  acc.push(
                    `${currentAsset.serialNumber} - ${currentAsset.sizeCode}`
                  );
                }
                return acc;
              }, [] as string[])
          )
        );
      }
    },
    [currentContract, assets]
  );

  const saveContract = useCallback(
    async (
      contract: Contract,
      { setSubmitting, resetForm }: FormikHelpers<Contract>
    ) => {
      const contractClient = await getClientById(contract.client).unwrap()
      updateContract({...contract, customerName: contractClient.companyName})
        .unwrap()
        .then(() => {
          dispatch(
            simpleGlobalMessage("Successfully saved contract", "success")
          );
          setIsEditing(false);
          resetForm();
        })
        .catch((error) => {
          dispatch(
            simpleGlobalMessage(
              error.data?.message ||
                error.message ||
                "Failed to update contract",
              "error"
            )
          );
          setSubmitting(false);
        });
    },
    []
  );

  const canEnterEditMode = useCallback(() => {
    if (!currentContract) return { allowed: false, message: "No contract" };
    switch (true) {
      case contractInEndedStatus(currentContract?.status):
        return {
          allowed: false,
          message: `You cannot edit contracts that are ${currentContract?.status}`,
        };
      case userCannotEditAwaitingContract():
        return {
          allowed: false,
          message: `You cannot edit ${currentContract?.status} contracts that are not your own unless you are an Administrator or a Sales Coordinator`,
        };
      case userCannotEditActiveContract():
        return {
          allowed: false,
          message: `You cannot edit ${currentContract?.status} contracts unless you are an Administrator or a Sales Coordinator`,
        };
      default:
        return { allowed: true, message: "" };
    }

    function userCannotEditAwaitingContract() {
      return (
        ["AWAITING APPROVAL", "AWAITING CONFIRMATION"].includes(
          currentContract?.status || ""
        ) && !userCanEditContract
      );
    }
    function userCannotEditActiveContract() {
      return (
        currentContract &&
        activeContractStatuses.includes(currentContract?.status || "") &&
        !userCanEditContracts
      );
    }
  }, [currentContract]);

  const revertContract = useCallback(() => {
    if (!currentContract) return;
    if (!userCanEditContract || statusNotAllowedToBeReverted()) {
      dispatch(
        simpleGlobalMessage(
          statusNotAllowedToBeReverted()
            ? `You cannot revert ${currentContract?.status} contracts`
            : "You cannot revert contracts unless you are an Administrator"
        )
      );
      return;
    }

    revertContractStatus({
      contractId: currentContract?._id,
      currentStatus: currentContract?.status,
    })
      .unwrap()
      .then(({ contract, assets }) => {
        dispatch(
          simpleGlobalMessage(
            `Successfully reverted contract to ${contract.status}`,
            "success"
          )
        );
      })
      .catch((error) => {
        dispatch(
          simpleGlobalMessage(
            `Failed to revert contract: ${
              error.data.message || "Unknown Error"
            }`,
            "error"
          )
        );
      });
  }, [currentContract]);

  const userCanEditContract = useMemo(() => {
    const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
    const userCanEditOwnContract =
      loggedInUser?._id === currentContract?.createdBy &&
      checkContractPermissions(ActionType.LIMITED_UPDATE);
    if (currentContract?.status === "ACTIVE") {
      return userCanEditContracts;
    } else {
      return userCanEditOwnContract || userCanEditContracts;
    }
  }, [currentContract]);

  function statusNotAllowedToBeReverted() {
    return currentContract
      ? ["CANCELLED", "DENIED"].includes(currentContract?.status)
      : true;
  }

  const handleEditAccessory = useCallback(
    (
      values: IAccessory,
      formikHelpers: FormikHelpers<IAccessory>,
      setSelectedIndex: Dispatch<SetStateAction<number | undefined>>
    ) => {
      if (!currentContract) return;
      formikHelpers.setSubmitting(true);

      const accessories = currentContract.accessories.map((accessory) =>
        accessory._id === values._id ? values : accessory
      );

      updateAccessories({ contractId: currentContract._id, accessories })
        .unwrap()
        .then((contract) => {
          formikHelpers.setSubmitting(false);
          setSelectedIndex(undefined);
        })
        .catch(() => {
          formikHelpers.setSubmitting(false);
        });
    },
    [currentContract]
  );

  const handleRemoveAccessory = useCallback(
    (
      accessory: IAccessory,
      {
        setSubmitting,
        setSelectedIndex,
      }: {
        setSubmitting: (isSubmitting: boolean) => void;
        setSelectedIndex: Dispatch<SetStateAction<number | undefined>>;
      }
    ) => {
      if (!currentContract) return;
      setSubmitting(true);
      updateContract({
        ...currentContract,
        accessories: currentContract.accessories.filter(
          ({ _id }) => _id !== accessory._id
        ),
      })
        .unwrap()
        .then(({ contract }) => {
          setSubmitting(false);
          setSelectedIndex(undefined);
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
    [currentContract]
  );

  return {
    handleContractAssetSubmit,
    removeAssetFromContract,
    updateAssetDeliverables,
    addAccessoryToContract,
    handleScheduleAllMovements,
    saveContract,
    userCanEditContract,
    revertContract,
    canEnterEditMode,
    handleRemoveAccessory,
    handleEditAccessory,
    addAssetModalMode,
    setAddAssetModalMode,
    isEditing,
    setIsEditing,
    isSubmitting,
    changeIsSubmitting,
  };
};

export default useContractBladeUtils;
