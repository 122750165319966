import { Branch } from "store/models/Branch";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { oneMinute } from "utils/timeValues";

export const branchApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getBranches: builder.query<Branch[], void>({
      query: (query) => `branch`,
      transformResponse: getResponseBody,
      providesTags: [{ type: "Branches", id: `LIST` }],
      keepUnusedDataFor: oneMinute * 60,
    }),
    updateBranch: builder.mutation<Branch, Branch>({
      query: (body) => ({
        url: `branch`,
        method: "PUT",
        body,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: [{ type: "Branches", id: `LIST` }],
      extraOptions: {maxRetries: 0},
    }),
  }),
});

export const { useGetBranchesQuery, useUpdateBranchMutation } = branchApi;
